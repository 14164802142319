import useCurrentUser from '@we-agile-you/planning-poker-app/src/spaces/auth/hooks/useCurrentUser';
import {
  FormInput,
  InlineAlert,
  SubmitRow,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import React, { FormEvent, useState, useRef, useEffect } from 'react';
import { createTeam } from '../../../spaces/retrospective/data/teams';

interface CreateTeamFormProps {
  onCancel: () => void;
  onCreated: (teamId: string) => void;
}

export const CreateTeamForm = ({
  onCancel,
  onCreated,
}: CreateTeamFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useCurrentUser();
  const [nameInputValue, setNameInputValue] = useState('');
  const teamInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (teamInputRef.current) {
      teamInputRef.current.focus();
    }
  }, []);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setError(null);
    setIsLoading(true);

    if (!nameInputValue) {
      setError('Please set a name to the team');
      setIsLoading(false);

      return;
    }

    if (!user) {
      setError('No signed user found');
      setIsLoading(false);

      return;
    }

    createTeam(nameInputValue)
      .then((teamId) => {
        setIsLoading(false);
        onCreated(teamId);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e?.message || 'An error happened saving your deck');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        label="Team name"
        value={nameInputValue}
        onChange={setNameInputValue}
        ref={teamInputRef}
      />
      <VerticalSpacing spacing="spacing-xl" />
      {error && (
        <InlineAlert
          title="There was an error creating your team"
          content={error}
        />
      )}
      <SubmitRow
        cancelLabel="Cancel"
        confirmLabel="Save team"
        onCancel={handleCancel}
        align="strech"
        isLoading={isLoading}
      />
    </form>
  );
};
