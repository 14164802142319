import { useCurrentRetro } from './../../retrospective/hooks/useCurrentRetro';
import { updateCurrentUserRetroHistory } from '@we-agile-you/auth';
import { useEffect, useMemo, useState } from 'react';

import useCurrentUser from './useCurrentUser';

type Summary = {
  id: string;
  name: string;
};
export const useUpdateCurrentUserRetroHistory = () => {
  const { retro } = useCurrentRetro();
  const { user } = useCurrentUser();
  const [previousSummary, setPreviousSummary] = useState<Summary | null>(null);

  const retroSummary = useMemo(() => {
    return retro?.id
      ? {
          id: retro.id,
          name: retro.name || '',
        }
      : null;
  }, [retro?.id, retro?.name]);

  useEffect(() => {
    if (retroSummary === null || user === null) return;

    const hasTableChanged =
      previousSummary === null ||
      previousSummary.id !== retroSummary.id ||
      previousSummary.name !== retroSummary.name;

    if (hasTableChanged) {
      updateCurrentUserRetroHistory(user, retroSummary);
      setPreviousSummary(retroSummary);
    }
  }, [retroSummary, user, previousSummary]);
};
