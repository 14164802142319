import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import styles from './RetroCard.module.scss';
import {
  ButtonIcon,
  ConfirmModal,
  FlexBox,
  Icon,
  PlayerPicker,
  ProfileImage,
  Span,
  InlineEdit,
  ButtonLink,
} from '@we-agile-you/react-base';
import {
  RetroNote as RetroNoteType,
  RetroActionNote,
} from '@we-agile-you/types-planning-poker';
import { DEFAULT_DISPLAY_NAME } from '../../../spaces/retrospective/constants';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';
import useCurrentUser from '@we-agile-you/planning-poker-app/src/spaces/auth/hooks/useCurrentUser';
import { useNotification } from '../../../spaces/notifications/useNotification';
import { useAppContext } from '../../../spaces/app/hooks/useAppContext';
import { addActionNoteToLastTeamsRetro } from '../../../spaces/retrospective/data/retroActions';

interface RetroNoteProps {
  note: RetroNoteType | RetroActionNote;
  isStack: boolean;
  isZoomed?: boolean;
  isCSSTransition?: boolean;
  zoomPercent?: number;
}

export const RetroNote = ({
  note,
  isStack,
  isZoomed,
  isCSSTransition,
  zoomPercent,
}: RetroNoteProps) => {
  const { retro } = useCurrentRetro();
  const { likeNote, unstackNote, deleteNote, assignNoteToPlayer, editNote } =
    useRetroActions();
  const { uid } = useCurrentUser();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const setIsOpenSettingsModal = useAppContext().settingsModal[1];

  const { showNotification } = useNotification();

  if (!retro) return null;

  const player = retro.playersAll.find((player) => player.uid === note.ownerId);
  const likesCount =
    retro.likes?.filter((like) => like.noteId === note.id).length || 0;

  const isLikedByCurrentUser = !!retro.likes?.find(
    (like) => like.noteId === note.id && like.uid === uid,
  );

  const textareaRef = useRef<{
    setIsFocus: (isFocus: boolean) => void;
  }>({
    setIsFocus: () => ({}),
  });

  const handleLikeNoteClick = () => {
    likeNote(note.id).catch((error) => {
      if (error.message === 'likes_limit_reached') {
        showNotification({
          style: 'error',
          title: `You already gave ${retro.likesLimit} likes`,
          content: (
            <>
              If you want, you can change the likes per person limit at{' '}
              <ButtonLink onClick={() => setIsOpenSettingsModal(true)}>
                game settings
              </ButtonLink>
            </>
          ),
        });
      }
    });
  };

  const handleDetachNoteClick = () => {
    unstackNote(note.id);
  };

  const handleDeleteNoteClick = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const handleAssignNote = (playerId: string) => {
    assignNoteToPlayer(note.id, playerId);
  };

  const handleEditNote = () => {
    textareaRef.current.setIsFocus(true);
  };
  const handleBlur = (value: string) => {
    if (!value) {
      setIsConfirmDeleteModalOpen(true);
      return;
    }

    editNote(note.id, value);
  };

  const handleAddToLastRetroClick = () => {
    addActionNoteToLastTeamsRetro(note as RetroActionNote, retro.team.id)
      .then(() => {
        showNotification({
          title: 'Action imported successfully',
          content:
            "The action has been imported to last team's retro, at actions column",
        });
      })
      .catch((error) => {
        showNotification({
          title: error.message,
          style: 'error',
          content:
            "Last team's retro already contains the action you are trying to import",
        });
      });
  };

  const canEditNote = uid === note.ownerId || retro.ownerId === uid;
  const canLikeNote = uid !== note.ownerId || retro.canLikeMyNotes;

  return (
    <>
      <div
        className={cx(
          styles['note'],
          isZoomed && styles['note--is-zoomed'],
          isCSSTransition && styles['note--is-css-transition'],
        )}
      >
        <div className={styles['note__text']}>
          <InlineEdit
            ref={textareaRef}
            value={note.text}
            buttonLabel="Text"
            submitLabel="Save"
            onConfirmValue={handleBlur}
            keepFocusAfterClickOutside
            isDisabled={!isZoomed || !canEditNote}
            valueFontSize={zoomPercent ? 1.4 + (0.8 * zoomPercent) / 100 : 1.4}
          />
        </div>

        <div className={styles['note__bottom']}>
          {note.type === 'action' ? (
            <div
              className={styles['note__player-picker']}
              onClick={(event) => event.stopPropagation()}
            >
              <PlayerPicker
                placeholder="Assign to..."
                players={retro?.playersAll}
                selectedPlayerId={note.assignTo || null}
                onSelectPlayer={handleAssignNote}
                size={isZoomed ? 'body' : 'micro'}
              />
            </div>
          ) : (
            <div className={styles['note__owner']}>
              <ProfileImage
                size={isZoomed ? 'l' : 's'}
                src={player?.profilePictureUrl}
                alt={player?.displayName || DEFAULT_DISPLAY_NAME}
                tooltip={player?.displayName || DEFAULT_DISPLAY_NAME}
                isCSSTransition={isCSSTransition}
              />
            </div>
          )}
          <FlexBox
            justifyContent="end"
            className={styles['note__actions-wrapper']}
          >
            {isZoomed && (
              <ul className={cx(styles['note__actions'])}>
                {isStack && (
                  <li>
                    <ButtonIcon
                      onClick={handleDetachNoteClick}
                      icon={<Icon icon="detach" />}
                    />
                  </li>
                )}
                {canEditNote && (
                  <li>
                    <ButtonIcon
                      onClick={handleEditNote}
                      icon={<Icon icon="pencil" />}
                    />
                  </li>
                )}
                {canEditNote && (
                  <li>
                    <ButtonIcon
                      onClick={handleDeleteNoteClick}
                      icon={<Icon icon="trash" />}
                    />
                  </li>
                )}
              </ul>
            )}
            {note.type === 'simple' && (
              <div
                className={styles['note__likes']}
                onClick={(event) => event.stopPropagation()}
              >
                {canLikeNote ? (
                  <ButtonIcon
                    icon={
                      isLikedByCurrentUser ? (
                        <Icon icon="like-solid" />
                      ) : (
                        <Icon icon="like-outline" />
                      )
                    }
                    buttonColor={isLikedByCurrentUser ? 'primary' : 'secondary'}
                    onClick={handleLikeNoteClick}
                  />
                ) : (
                  <div className={styles['note__like-icon']}>
                    <Span color="grey600">
                      <Icon icon="like-outline" />
                    </Span>
                  </div>
                )}
                <Span color="grey600">{likesCount}</Span>
              </div>
            )}
            {!!retro && !retro.isLastRetro && (
              <div onClick={(event) => event.stopPropagation()}>
                <ButtonIcon
                  icon={<Icon icon="open" />}
                  tooltip="Import to current team's retro"
                  onClick={handleAddToLastRetroClick}
                />
              </div>
            )}
          </FlexBox>
        </div>
      </div>
      {isConfirmDeleteModalOpen &&
        ReactDOM.createPortal(
          <ConfirmModal
            isDanger
            confirmLabel="Delete note"
            cancelLabel="Cancel"
            title="Are you sure you want to delete this note?"
            onClose={() => setIsConfirmDeleteModalOpen(false)}
            onCancel={() => setIsConfirmDeleteModalOpen(false)}
            onConfirm={() => {
              setIsConfirmDeleteModalOpen(false);
              deleteNote(note.id);
            }}
          />,
          document.body,
        )}
    </>
  );
};
