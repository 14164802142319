import { Modal, ModalTitle } from '@we-agile-you/react-base';
import React from 'react';
import { LayoutForm } from '../LayoutForm/LayoutForm';

interface CreateLayoutModalModalProps {
  onClose: () => void;
  onCreated: (teamId: string) => void;
}
export const CreateLayoutModal = ({
  onClose,
  onCreated,
}: CreateLayoutModalModalProps) => {
  return (
    <Modal onClose={onClose} width="auto">
      <ModalTitle>Create new layout</ModalTitle>
      <LayoutForm onCancel={onClose} onCreated={onCreated} />
    </Modal>
  );
};
