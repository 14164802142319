import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  doc,
} from 'firebase/firestore';
import { firestore, auth } from '@we-agile-you/firebase';
import {
  Team,
  RetrospectiveServer,
  TeamServer,
} from '@we-agile-you/types-planning-poker';

const TEAMS_COLLECTION = 'teams';

export const createTeam = async (teamName: string) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) throw new Error('No signed user');

  const uid = signedInUser.uid;

  const newTeam: Partial<TeamServer> = {
    name: teamName,
    creatorUid: uid,
    usersJoined: [uid],
    createdAt: serverTimestamp(),
  };

  const docRef = await addDoc(collection(firestore, TEAMS_COLLECTION), newTeam);

  return docRef.id;
};

export const subscribeToTeams = (
  uid: string,
  onTeamsChange: (teams: Team[]) => void,
) => {
  const q = query(
    collection(firestore, TEAMS_COLLECTION),
    where('usersJoined', 'array-contains', uid),
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const teams: Team[] = [];

    querySnapshot.forEach((doc) => {
      const teamServer = {
        id: doc.id,
        ...doc.data(),
      } as TeamServer;

      teams.push({
        ...teamServer,
        createdAt: teamServer.createdAt?.toDate(),
      } as Team);
    });

    onTeamsChange(teams);
  });

  return unsubscribe;
};

export const getTeamGames = async (teamId: string) => {
  const q = query(
    collection(firestore, 'retrospectives'),
    where('teamId', 'array-contains', teamId),
  );

  const querySnapshot = await getDocs(q);

  const games: RetrospectiveServer[] = [];

  querySnapshot.forEach((doc) => {
    games.push({
      id: doc.id,
      ...doc.data(),
    } as any);
  });

  return games;
};

export const deleteTeam = async (teamId: string) => {
  return deleteDoc(doc(firestore, `teams/${teamId}`));
};

export const editTeam = async (teamId: string, update: Partial<Team>) => {
  return updateDoc(doc(firestore, `teams/${teamId}`), update);
};
