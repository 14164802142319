import { RECEIVE_IS_LAST_RETRO_CHANGE } from './../constants';
import {
  ReceiveCreateRetrospectiveAction,
  ReceiveIsLastRetroChangeAction,
  ReceiveRetrospectiveDataChangeAction,
  ReceiveRetrospectivePlayersChangeAction,
  RetrospectiveActionTypes,
} from './../types.d';
import { RetrospectiveState } from '@we-agile-you/types-planning-poker';
import {
  RECEIVE_CREATED_RETROSPECTIVE,
  RECEIVE_RETROSPECTIVE_DATA_CHANGE,
  RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE,
  REQUEST_CREATE_RETROSPECTIVE,
  REQUEST_SUBSCRIBE_TO_RETROSPECTIVE,
  UNSUBSCRIBE_TO_RETROSPECTIVE,
} from '../constants';

export const initialRetrospectiveState: RetrospectiveState = {
  name: '',
  columns: [],
  cards: [],
  teamId: '',
  teamName: '',
  isCreating: false,
  isFetching: false,
  id: null,
  createdAt: new Date(),
  ownerId: null,
  players: [],
  playersAll: [],
  canLikeMyNotes: true,
  isLastRetro: true,
  layout: {
    columns: [],
    name: '',
    id: '',
  },
  notes: [],
  likes: [],
  likesLimit: 3,
};

export const retrospectiveReducer = function retrospectiveReducer(
  state = initialRetrospectiveState,
  action: RetrospectiveActionTypes,
): RetrospectiveState {
  switch (action.type) {
    case REQUEST_CREATE_RETROSPECTIVE:
      return {
        ...state,
        isCreating: true,
      };

    case RECEIVE_CREATED_RETROSPECTIVE:
      return {
        ...state,
        isCreating: false,
        id: (action as ReceiveCreateRetrospectiveAction).retrospectiveId,
      };

    case REQUEST_SUBSCRIBE_TO_RETROSPECTIVE:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_RETROSPECTIVE_DATA_CHANGE:
      return {
        ...state,
        ...(action as ReceiveRetrospectiveDataChangeAction).data,
        isFetching: false,
        players: state.players,
      };
    case RECEIVE_IS_LAST_RETRO_CHANGE:
      return {
        ...state,
        isLastRetro: (action as ReceiveIsLastRetroChangeAction).isLastRetro,
      };

    case RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE:
      const players = (action as ReceiveRetrospectivePlayersChangeAction)
        .players;

      return {
        ...state,
        players: players.filter((player) => player.state === 'online'),
        playersAll: players,
      };

    case UNSUBSCRIBE_TO_RETROSPECTIVE:
      return {
        ...initialRetrospectiveState,
      };

    default:
      return state;
  }
};
