import { Paragraph } from '@we-agile-you/react-base';
import React from 'react';

import styles from './FormatedElement.module.scss';
import { FormatedElementType } from './FormatedTextContainer';

type FormatedElementProps = {
  i: number;
  element: FormatedElementType;
  valueFontSize?: number;
};

export const FormatedElement = ({
  i,
  element,
  valueFontSize,
}: FormatedElementProps) => {
  switch (element.type) {
    case 'paragraph':
      return (
        <Paragraph
          key={i.toString()}
          className={styles.p}
          style={
            valueFontSize ? { fontSize: `${valueFontSize}rem` } : undefined
          }
        >
          {element.paragraphElements &&
            element.paragraphElements.map((element, j) => (
              <FormatedElement
                key={j}
                i={j}
                element={element}
                valueFontSize={valueFontSize}
              />
            ))}
        </Paragraph>
      );

    case 'text':
      return (
        <span
          key={i.toString()}
          style={
            valueFontSize ? { fontSize: `${valueFontSize}rem` } : undefined
          }
        >
          {element.text}
        </span>
      );
    case 'url':
      return (
        <>
          <a
            onClick={(event) => event.stopPropagation()}
            href={element.url}
            className={styles['link']}
            target="_blank"
            rel="noopener noreferrer"
            key={i.toString()}
            style={
              valueFontSize ? { fontSize: `${valueFontSize}rem` } : undefined
            }
          >
            {element.url}
          </a>
        </>
      );
  }
};
