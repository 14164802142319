import React, { useEffect } from 'react';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { Helmet } from 'react-helmet';
import { NotFoundTemplate } from '../NotFoundTemplate/NotFoundTemplate';
import useSubscribeToRetro from '../../../spaces/retrospective/hooks/useSubscribeToRetro';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { RetroNavigation } from '../../../containers/retrospective/navigation/RetroNavigation';
import { CardsDashboard } from '../../../containers/retrospective/CardsDashboard/CardsDashboard';
import { useBuildPresenceInRetro } from '../../../spaces/retrospective/hooks/useBuildPresence';
import { useAppContext } from '../../../spaces/app/hooks/useAppContext';
import { useUpdateCurrentUserRetroHistory } from '../../../spaces/auth/hooks/useUpdateCurrentUserGames';
import { useSubscribeToCurrentUserTeams } from '../../../spaces/retrospective/hooks/useSubscribeToCurrentUserTeams';
import { useUpdateCurrentUserTeamsHistory } from '../../../spaces/auth/hooks/useUpdateCurrentUserTeamsHistory';

interface RetroTemplateProps {
  retroId: string;
}

export const RetrospectiveTemplate = ({ retroId }: RetroTemplateProps) => {
  const { retroExists } = useSubscribeToRetro(retroId);
  const { user } = useCurrentUser();
  useBuildPresenceInRetro();
  useUpdateCurrentUserRetroHistory();
  useUpdateCurrentUserTeamsHistory();
  useSubscribeToCurrentUserTeams();

  const setIsEditDisplayNameModalOpen = useAppContext().editDisplayNameModal[1];

  const { retro } = useCurrentRetro();
  const { uid } = useCurrentUser();
  const ownerId = retro?.ownerId;

  useEffect(() => {
    if (uid && retroId && ownerId) {
      // sendEvent({
      //   id: 'poker-table-page-view',
      //   retroId,
      //   isOwner: ownerId === uid,
      // });
    }
  }, [uid, retroId, ownerId]);

  const title = retro?.name ? retro.name : 'Retrospective';

  const hasToOpen = !!user && !user.displayName;
  useEffect(() => {
    if (hasToOpen) {
      setIsEditDisplayNameModalOpen('prompted');
    }
  }, [hasToOpen]);

  if (retroExists === false) {
    return <NotFoundTemplate />;
  }

  return (
    <>
      <Helmet title={title}>
        <style type="text/css">{`
        @media (min-width: 920px) and (max-height: 750px) {
          html { font-size: 57%; }
        }

        @media (min-width: 920px) and (max-height: 650px) {
          html { font-size: 50%; }
        }

    `}</style>
      </Helmet>
      <RetroNavigation />
      <CardsDashboard />
      {/* <RobotAlerting />
      <ModalAlerting />
      <PremiumInFreeGameAlert /> */}
    </>
  );
};
