import React from 'react';

import { ProfileImage } from '../../atoms/ProfileImage/ProfileImage';
import { Span } from '../../atoms/text/Span/Span';
import { FlexBox } from '../../atoms/FlexBox/FlexBox';
import { HoritzontalSpacing } from '../../atoms/spacings/HoritzontalSpacing/HoritzontalSpacing';
import { PlayerRetro } from '../../../types-planning-poker';

interface ProfileProps {
  player: PlayerRetro;
  size?: 'small' | 'micro';
}

const DEFAULT_DISPLAY_NAME = 'Anonymous';

export const Profile = ({ player, size }: ProfileProps) => {
  return (
    <FlexBox>
      <ProfileImage
        src={player?.profilePictureUrl}
        alt={player?.displayName || DEFAULT_DISPLAY_NAME}
        size={size === 'micro' ? 's' : 'm'}
      />
      <HoritzontalSpacing spacing="spacing-xs" />
      <Span size={size ? size : 'small'} spanStyle="bold">
        {player?.displayName || DEFAULT_DISPLAY_NAME}
      </Span>
    </FlexBox>
  );
};
