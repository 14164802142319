import { Modal, ModalTitle } from '@we-agile-you/react-base';
import React from 'react';

import { CreateTeamForm } from '../CreateTeamForm/CreateTeamForm';

interface ImportFromFileModalProps {
  onClose: () => void;
  onCreated: (teamId: string) => void;
}
export const CreateTeamModal = ({
  onClose,
  onCreated,
}: ImportFromFileModalProps) => {
  return (
    <Modal onClose={onClose} width="big">
      <ModalTitle>Create new team</ModalTitle>
      <CreateTeamForm onCancel={onClose} onCreated={onCreated} />
    </Modal>
  );
};
