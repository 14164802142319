import React from 'react';

export const User = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8C9 6.34657 10.3466 5 12 5C13.6534 5 15 6.34657 15 8C15 9.65343 13.6534 11 12 11C10.3466 11 9 9.65343 9 8ZM15.3979 11.6656C16.3829 10.7519 17 9.44698 17 8C17 5.242 14.758 3 12 3C9.242 3 7 5.242 7 8C7 9.44698 7.61712 10.7519 8.60211 11.6656C5.31852 13.0087 3 16.238 3 20C3 20.5523 3.44772 21 4 21C4.55228 21 5 20.5523 5 20C5 16.1409 8.14086 13 12 13C15.8591 13 19 16.1409 19 20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20C21 16.238 18.6815 13.0087 15.3979 11.6656Z"
      fill="currentColor"
    />
  </svg>
);
