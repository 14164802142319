import { ButtonIcon, Icon, Span } from '@we-agile-you/react-base';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

import styles from './RetroCardModal.module.scss';
import { RetroNote } from '../RetroCard/RetroNote';
import { MODAL_WIDTH } from './useCardModalAnimation';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { getCardFromRetro } from '../../../spaces/retrospective/utils';

interface RetroCardModalProps {
  currentCardId?: string | null;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

export const RetroCardModal = ({
  currentCardId,
  onClose,
  onNext,
  onPrevious,
}: RetroCardModalProps) => {
  const modal = useRef<HTMLDivElement>(null);

  const { retro } = useCurrentRetro();

  const column = retro?.columns.find((column) =>
    column.cards.find((card) => card.id === currentCardId),
  );

  const cardIndex = column?.cards.findIndex(
    (card) => card.id === currentCardId,
  );

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const listener = (event: Event) => {
      switch ((event as KeyboardEvent).code) {
        case 'ArrowLeft':
          onPrevious();
          break;
        case 'ArrowRight':
          onNext();
          break;
        case 'Escape':
          handleClose();
          break;
      }
    };
    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [onNext, onPrevious]);

  const card = currentCardId && retro && getCardFromRetro(currentCardId, retro);

  const cardDoesNotExist = currentCardId && retro && !card;

  useEffect(() => {
    if (cardDoesNotExist) {
      handleClose();
    }
  }, [cardDoesNotExist]);

  if (!card) return null;

  const notes = card.type === 'action' ? [card.note] : card.notes;

  return (
    <>
      <div className={styles.overlay} onClick={handleClose}>
        <div className={cx(styles['column-name'])}>
          {column && typeof cardIndex === 'number' && (
            <Span spanStyle="bold" color="white">{`${column.name} (${
              cardIndex + 1
            }/${column.cards.length})`}</Span>
          )}
        </div>
        <ButtonIcon
          className={styles['close-button']}
          icon={<Icon icon="close" />}
          buttonColor="light"
          onClick={handleClose}
        />
      </div>
      <div ref={modal} className={styles['retro-card-modal']}>
        {notes.map((note, i) => (
          <>
            {
              <RetroNote
                note={note}
                isStack={notes.length > 1}
                isZoomed={true}
                zoomPercent={100}
              />
            }
            {i < notes.length - 1 && (
              <div className={styles['stack-seperator']} />
            )}
          </>
        ))}
      </div>
      <div
        style={{
          top: '50%',
          left: '50%',
          width: MODAL_WIDTH,
          transform: 'translate(-50%,-50%)',
          zIndex: 9,
          position: 'fixed',
        }}
      >
        <div className={styles['modal-previous']}>
          {typeof cardIndex === 'number' && cardIndex > 0 && (
            <ButtonIcon
              onClick={onPrevious}
              buttonColor="light"
              icon={<Icon icon="previous"></Icon>}
            />
          )}
        </div>
        {typeof cardIndex === 'number' &&
          column?.cards.length &&
          cardIndex < column?.cards.length - 1 && (
            <div className={styles['modal-next']}>
              <ButtonIcon
                onClick={onNext}
                buttonColor="light"
                icon={<Icon icon="next"></Icon>}
              ></ButtonIcon>
            </div>
          )}
      </div>
    </>
  );
};
