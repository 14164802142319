import { firestore, auth } from '@we-agile-you/firebase';
import { RetrospectiveServer } from '@we-agile-you/types-planning-poker';
import { serverTimestamp, addDoc, collection } from 'firebase/firestore';

const RETROSPECTIVE_COLLECTION = 'retrospectives';

export const createRetrospective = (game: Partial<RetrospectiveServer>) => {
  if (!auth) return;

  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  const newGame = {
    createdAt: serverTimestamp(),
    ownerId: signedInUser.uid,
    ...game,
  };

  return addDoc(collection(firestore, RETROSPECTIVE_COLLECTION), newGame).then(
    (retroDocRef) => retroDocRef.id,
  );
};
