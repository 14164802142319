import { PlayerRetro } from '@we-agile-you/types-planning-poker';
import { firestore, database } from '@we-agile-you/firebase';
import {
  doc,
  setDoc,
  serverTimestamp as serverTimestampFirestore,
} from 'firebase/firestore';

import {
  ref,
  onDisconnect,
  set,
  serverTimestamp as serverTimestampDatabase,
  DataSnapshot,
  onValue,
} from 'firebase/database';
const PLAYERS_RETROS_COLLECTION = 'players-retros';

export const buldPresenceInRetro = (uid: string, retroId: string) => {
  const playerId = `${uid}_${retroId}`;
  const playerDatabaseRef = ref(
    database,
    `/${PLAYERS_RETROS_COLLECTION}/${playerId}`,
  );
  const playerFirestoreRef = doc(
    firestore,
    `/${PLAYERS_RETROS_COLLECTION}/${playerId}`,
  );
  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: serverTimestampDatabase(),
  };
  const isOnlineForDatabase = {
    state: 'online',
    last_changed: serverTimestampDatabase(),
  };
  const isOfflineForFirestore = {
    state: 'offline',
    last_changed: serverTimestampFirestore(),
  };
  const isOnlineForFirestore = {
    state: 'online',
    last_changed: serverTimestampFirestore(),
  };
  const onConnexionSatusChange = (snapshot: DataSnapshot) => {
    // eslint-disable-next-line eqeqeq
    if (snapshot.val() == false) {
      // we'll also set Firestore's state
      // to 'offline'. This ensures that our Firestore cache is aware
      // of the switch to 'offline.'
      setDoc(playerFirestoreRef, isOfflineForFirestore, { merge: true });
      return;
    }

    onDisconnect(playerDatabaseRef)
      .set(isOfflineForDatabase)
      .then(function () {
        set(playerDatabaseRef, isOnlineForDatabase);

        // We'll also add Firestore set here for when we come online.
        setDoc(playerFirestoreRef, isOnlineForFirestore, { merge: true });
      });
  };

  const connectedRef = ref(database, '.info/connected');
  const unsubscribe = onValue(connectedRef, onConnexionSatusChange);

  return function removePresenceInPokerGame() {
    unsubscribe();

    set(playerDatabaseRef, isOfflineForDatabase);
  };
};

export const updatePlayerRetroInfo = (player: PlayerRetro, retroId: string) => {
  const playerId = `${player.uid}_${retroId}`;

  return setDoc(
    doc(firestore, `/${PLAYERS_RETROS_COLLECTION}/${playerId}`),
    player,
    {
      merge: true,
    },
  );
};
