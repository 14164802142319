import {
  Container,
  Grid,
  Paragraph,
  useLocation,
  VerticalSpacing,
  FlexBox,
} from '@we-agile-you/react-base';
import { navigate } from 'gatsby';
import React, { useRef, useState } from 'react';
import { CreateTeamModal } from '../../../containers/retrospective/CreateTeamModal/CreateTeamModal';
import { CreateLayoutModal } from '../../../containers/retrospective/CreateLayoutModal/CreateLayoutModal';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { Loader } from '../../molecules/Loader/Loader';
import { Navigation } from '../../organisms/Navigation/Navigation';
import { useSubscribeToCurrentUserTeams } from '../../../spaces/retrospective/hooks/useSubscribeToCurrentUserTeams';
import { CreateRetroForm } from '../../../containers/retrospective/CreateRetroForm/CreateRetroForm';

export const NewRetrospectiveTemplate = () => {
  const location = useLocation();
  const { uid, user } = useCurrentUser();
  useSubscribeToCurrentUserTeams();
  const [isCreateTeamModalOpen, setisCreateTeamModalOpen] = useState(false);
  const [isCreateNewLayoutModalOpen, setisCreateNewLayoutModalOpen] =
    useState(false);
  const settingsFormRef = useRef<{
    setSelectedTeam: (teamId: string) => void;
    setSelectedLayout: (layoutId: string) => void;
    focusSubmitButton: () => void;
  }>({
    setSelectedTeam: () => ({}),
    setSelectedLayout: () => ({}),
    focusSubmitButton: () => ({}),
  });

  const onCreated = (pokerTableId: string) => {
    navigate(`/${pokerTableId}`, { state: { from: location } });
  };

  const title = 'Create game';

  const handleTeamCreated = (teamId: string) => {
    setisCreateTeamModalOpen(false);
    settingsFormRef.current.setSelectedTeam(teamId);
    settingsFormRef.current.focusSubmitButton();
  };

  const handleLayoutCreated = (layoutId: string) => {
    setisCreateNewLayoutModalOpen(false);
    settingsFormRef.current.setSelectedLayout(layoutId);
  };

  return (
    <>
      <Navigation title={title} isMinimal />

      <Container isCenteredInPage>
        {!!user ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <VerticalSpacing spacing="spacing-xxl-4" />
              <VerticalSpacing spacing="spacing-l" />
              <Paragraph align="center">
                Choose a layout and a team for your game.
              </Paragraph>
              <VerticalSpacing spacing="spacing-xl" />
              <CreateRetroForm
                ref={settingsFormRef}
                ownerId={uid}
                onCreated={onCreated}
                onSelectNewTeam={() => setisCreateTeamModalOpen(true)}
                onSelectNewLayout={() => setisCreateNewLayoutModalOpen(true)}
              />
            </Grid>
          </Grid>
        ) : (
          <FlexBox justifyContent="center">
            <Loader message="Just a second..." />
          </FlexBox>
        )}
      </Container>
      {isCreateTeamModalOpen && (
        <CreateTeamModal
          onCreated={handleTeamCreated}
          onClose={() => setisCreateTeamModalOpen(false)}
        />
      )}
      {isCreateNewLayoutModalOpen && (
        <CreateLayoutModal
          onCreated={handleLayoutCreated}
          onClose={() => setisCreateNewLayoutModalOpen(false)}
        />
      )}
    </>
  );
};
