import { useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { DragItem } from '@we-agile-you/types-planning-poker';
import { RetroCard, RetroCardAction } from '@we-agile-you/types-planning-poker';

export const useCardDrop = (
  ref: React.RefObject<HTMLDivElement>,
  index: number,
  card: RetroCard | RetroCardAction,
  columnId: string,
  onIsAttaching: () => void,
  onStackCard: (
    fromId: string,
    fromColumnId: string,
    toId: string,
    toColumnId: string,
  ) => void,
  onMoveCard: (
    fromIndex: number,
    toIndex: number,
    fromColumnId: string,
    toColumnId: string,
  ) => void,
) => {
  const accept = card.type === 'action' ? ['action'] : ['simple'];

  return useDrop({
    accept,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: (item: DragItem, monitor: DropTargetMonitor) => {
      if (
        !ref.current ||
        card.type === 'action' ||
        item.card.type === 'action'
      ) {
        return;
      }

      const dragIndex = item.index;
      const dragColumnId = item.columnId;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && dragColumnId === columnId) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragColumnId === columnId &&
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        item.card.type === 'simple' &&
        card.type === 'simple'
      ) {
        onStackCard(item.card.id, item.columnId, card.id, columnId);
        return;
      }

      // Dragging upwards
      if (
        dragColumnId === columnId &&
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        item.card.type === 'simple' &&
        card.type === 'simple'
      ) {
        onStackCard(item.card.id, item.columnId, card.id, columnId);
        return;
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const dragColumnId = item.columnId;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && dragColumnId === columnId) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const attachingBreakpoint = hoverBoundingRect.height / 5;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragColumnId === columnId &&
        dragIndex < hoverIndex &&
        hoverClientY < attachingBreakpoint &&
        item.card.type === 'simple' &&
        card.type === 'simple'
      ) {
        onIsAttaching();
        return;
      }

      // Dragging upwards
      if (
        dragColumnId === columnId &&
        dragIndex > hoverIndex &&
        hoverClientY > hoverBoundingRect.height - attachingBreakpoint &&
        item.card.type === 'simple' &&
        card.type === 'simple'
      ) {
        onIsAttaching();
        return;
      }

      // Time to actually perform the action
      onMoveCard(dragIndex, hoverIndex, dragColumnId, columnId);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      item.columnId = columnId;
    },
  });
};
