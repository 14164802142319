import {
  buldPresenceInRetro,
  updatePlayerRetroInfo,
} from './../data/retroPresence';
import { useCurrentRetro } from './useCurrentRetro';
import { useEffect } from 'react';
import useCurrentUser from '../../auth/hooks/useCurrentUser';

export const useBuildPresenceInRetro = (): void => {
  const { user, uid } = useCurrentUser();
  const { retro } = useCurrentRetro();

  const isUserFetched = !!user;
  const retroId = retro?.id;
  const retroName = retro?.name || '';
  const displayName = user?.displayName || '';
  const profilePictureUrl = user?.profilePictureUrl || null;
  const isSpectator = !!user?.isSpectator;

  useEffect(() => {
    if (!uid || !retroId) return;

    const removePresence = buldPresenceInRetro(uid, retroId);

    return function cleanup() {
      if (removePresence) {
        removePresence();
      }
    };
  }, [uid, retroId]);

  useEffect(() => {
    if (!uid || !isUserFetched || !retroId) return;

    updatePlayerRetroInfo(
      {
        uid,
        retroId,
        displayName,
        profilePictureUrl,
        retroName,
        isSpectator,
      },
      retroId,
    );
  }, [
    uid,
    isUserFetched,
    retroId,
    displayName,
    profilePictureUrl,
    retroName,
    isSpectator,
  ]);
};
