import { RetrospectiveActionTypes } from './../types.d';
import { RECEIVE_USER_TEAMS_DATA_CHANGE } from './../constants';
import { ReceiveUserTeamsChangeAction } from '../types';
import { Team } from '@we-agile-you/types-planning-poker';

interface UserTeamsState {
  teams?: Team[];
}
export const initialUserTeams: UserTeamsState = {
  teams: [],
};

export const userTeamsReducer = function retrospectiveReducer(
  state = initialUserTeams,
  action: RetrospectiveActionTypes,
): UserTeamsState {
  switch (action.type) {
    case RECEIVE_USER_TEAMS_DATA_CHANGE:
      return {
        ...state,
        teams: (action as ReceiveUserTeamsChangeAction).teams,
      };

    default:
      return state;
  }
};
