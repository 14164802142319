import { subscribeToPreviousRetros } from '../data/retroSubscriptions';
import {
  Retrospective,
  RetrospectiveState,
} from '@we-agile-you/types-planning-poker/retrospective';
import { useCurrentRetro } from './useCurrentRetro';
import { useEffect, useState } from 'react';
import { mapRetroStateToRetro } from '../utils';

interface useSubscribeToPreviousRetrosParams {
  limit?: number;
}
export const useSubscribeToPreviousRetros = (
  params?: useSubscribeToPreviousRetrosParams,
) => {
  const { retro } = useCurrentRetro();
  const [retros, setRetros] = useState<
    { retro: Retrospective; retroState: RetrospectiveState }[]
  >([]);

  const retroId = retro?.id;
  const retroCreatedAt = retro?.createdAt;
  const teamId = retro?.team.id;

  useEffect(() => {
    if (!teamId || !retroId) return;

    return subscribeToPreviousRetros(
      teamId,
      retroCreatedAt || new Date(),
      (retros) => {
        setRetros(
          retros
            .filter((_retro) => _retro.id !== retroId)
            .map((retroState) => ({
              retroState,
              retro: mapRetroStateToRetro(retroState),
            })),
        );
      },
      params?.limit,
    );
  }, [retroId, teamId]);

  return { retros };
};
