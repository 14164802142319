import {
  FlexBox,
  usePrevious,
  VerticalSpacing,
  Button,
} from '@we-agile-you/react-base';
import cx from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
} from 'react';
import { ButtonIcon } from '../../atoms/ButtonIcon/ButtonIcon';
import { Icon } from '../../atoms/Icon/Icon';
import { HoritzontalSpacing } from '../../atoms/spacings/HoritzontalSpacing/HoritzontalSpacing';

import { FormatedTextContainer } from './FormatedTextContainer/FormatedTextContainer';
import styles from './InlineEdit.module.scss';

const HEIGHTS = {
  'textarea-m': 12,
};

interface InlineEditProps {
  value: string;
  type?: 'input' | 'textarea';
  buttonLabel?: React.ReactNode;
  submitLabel?: string;
  placeholder?: string;
  confirmValueWithEnterKey?: boolean;
  confirmValueWithClickOutside?: boolean;
  keepFocusAfterClickOutside?: boolean;
  keepFocusAfterEnterKeyPress?: boolean;
  valueClassname?: string;

  elementClassname?: string;
  valueFontSize?: number /** Usefull for animations */;
  isDisabled?: boolean;
  isRequired?: boolean;
  hideActions?: boolean;

  onConfirmValue: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}
export const InlineEdit = forwardRef(
  (
    {
      value,
      buttonLabel,
      submitLabel,
      placeholder,
      confirmValueWithEnterKey,
      confirmValueWithClickOutside,
      keepFocusAfterClickOutside,
      keepFocusAfterEnterKeyPress,
      type,
      valueClassname,
      valueFontSize,
      elementClassname,
      isDisabled,
      isRequired,
      hideActions,
      onConfirmValue,
      onFocus,
      onBlur,
    }: InlineEditProps,
    ref,
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    const elementRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
    const addNoteButton = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const valueRef = useRef<HTMLDivElement>(null);

    const previousIsFocus = usePrevious(isFocus);
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    const confirmButtonRef = useRef<HTMLButtonElement>(null);
    const element = elementRef.current;

    const resolvedType = type || 'textarea';

    useEffect(() => {
      if (!previousIsFocus && isFocus) {
        if (element) {
          element.focus();
        }
      }
    }, [isFocus, previousIsFocus, element]);

    useImperativeHandle(ref, () => ({
      setIsFocus: (isFocus: boolean) => {
        setIsFocus(isFocus);
      },
    }));

    useEffect(() => {
      setCurrentValue(value || '');
    }, [value]);

    const handleBlur = useCallback(
      (saveValue = true) => {
        setIsFocus(false);

        if (saveValue && currentValue !== value) {
          setCurrentValue(value); // Always reset value, to be sure onConfirmValue works
          onConfirmValue(currentValue);
        }

        if (onBlur) {
          onBlur();
        }
      },
      [isFocus, currentValue, value],
    );

    const containerRefs = [
      elementRef,
      addNoteButton,
      cancelButtonRef,
      confirmButtonRef,
      valueRef,
    ];

    useEffect(() => {
      const handleDocumentClick = (event: MouseEvent) => {
        if (!isFocus || keepFocusAfterClickOutside) return;

        const isOutsideAllContainers = containerRefs.every(
          (element) =>
            !element.current ||
            !document.body.contains(event.target as Node) ||
            !document.body.contains(element.current as Node) ||
            !element.current.contains(event.target as Node),
        );
        if (isOutsideAllContainers) {
          handleBlur(!!confirmValueWithClickOutside);
        }
      };

      document.addEventListener('click', handleDocumentClick);

      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, [
      containerRefs,
      isFocus,
      keepFocusAfterClickOutside,
      confirmValueWithClickOutside,
    ]);

    const handleCancelClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      handleBlur(false);
    };

    function handleChange(e: React.FormEvent) {
      const { value } = e.target as HTMLInputElement;
      setCurrentValue(value);
    }

    function handleKeyPress(e: React.KeyboardEvent) {
      if (e.key === 'Enter' && !e.shiftKey && confirmValueWithEnterKey) {
        e.preventDefault();

        if (elementRef.current && keepFocusAfterEnterKeyPress) {
          onConfirmValue(currentValue || value || '');
          setCurrentValue('');
          window.setTimeout(() => {
            if (element) element.scrollIntoView();
          }, 50);
        } else {
          handleBlur();
        }
      }
    }

    const handleButtonClick = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsFocus(true);
    };

    const handleValueClick = () => {
      if (isDisabled) return;

      setIsFocus(true);
    };

    const handleConfirmClick = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleBlur();
    };

    const elementProps = {
      value: currentValue,
      onChange: handleChange,
      onFocus,
      onKeyPress: handleKeyPress,
      onClick: (event: React.MouseEvent) => event.stopPropagation(),
      placeholder,
    };

    return (
      <div ref={containerRef}>
        <div
          className={cx(
            styles['container'],
            hideActions && styles['container--hide-actions'],
          )}
          style={isFocus ? { display: 'block' } : { display: 'none' }}
        >
          {resolvedType === 'textarea' ? (
            <textarea
              ref={elementRef as React.RefObject<HTMLTextAreaElement>}
              className={cx(
                elementClassname ? elementClassname : styles.textarea,
              )}
              style={{
                height: `${HEIGHTS['textarea-m']}rem`,
              }}
              {...elementProps}
            />
          ) : (
            <input
              ref={elementRef as React.RefObject<HTMLInputElement>}
              className={cx(elementClassname ? elementClassname : styles.input)}
              {...elementProps}
            />
          )}
          {!hideActions && (
            <>
              <VerticalSpacing spacing="spacing-m" />
              <FlexBox>
                <Button
                  size="small"
                  onClick={handleConfirmClick}
                  isDisabled={isRequired && !currentValue}
                  ref={confirmButtonRef}
                >
                  {submitLabel}
                </Button>
                <HoritzontalSpacing spacing="spacing-xs" />
                <ButtonIcon
                  ref={cancelButtonRef}
                  icon={<Icon icon="close" />}
                  onClick={handleCancelClick}
                  size="sm"
                />
              </FlexBox>
            </>
          )}
        </div>

        {value && (
          <div
            ref={valueRef}
            style={isFocus ? { display: 'none' } : { display: 'block' }}
          >
            <div
              onClick={handleValueClick}
              className={cx(
                styles['value'],
                isDisabled && styles['value--disabled'],
                valueClassname,
              )}
            >
              <FormatedTextContainer
                text={value}
                valueFontSize={valueFontSize}
              />
            </div>
          </div>
        )}

        {!isFocus && !value && (
          <div className={styles['button-wrapper']}>
            <button
              ref={addNoteButton}
              className={cx(styles.button)}
              onClick={handleButtonClick}
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    );
  },
);
