import React from 'react';

export const Ppo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1642 0.481604C12.5209 -0.160535 11.4791 -0.160535 10.8358 0.481606L3.96154 7.34341L1.66482 9.63597C-0.55494 11.8517 -0.554941 15.4441 1.66482 17.6599C3.88458 19.8756 7.48352 19.8756 9.70328 17.6599L10.105 17.2589C9.54258 16.7664 9.17612 16.0557 9.13776 15.259L6.08326 15.8397C5.28597 15.9913 4.77288 15.0205 5.34726 14.4471L9.13443 10.6668V7.8355C9.13443 6.26589 10.4068 4.99347 11.9765 4.99347C13.5461 4.99347 14.8185 6.26589 14.8185 7.8355V10.6665H14.8188L18.6063 14.4471C19.1807 15.0205 18.6676 15.9913 17.8703 15.8397L14.8151 15.2589C14.7774 16.0438 14.4212 16.7453 13.8729 17.2368L14.2967 17.6598C16.5165 19.8756 20.1154 19.8756 22.3352 17.6598C24.5549 15.4441 24.5549 11.8517 22.3352 9.63595L13.1642 0.481604ZM11.976 10.2621C12.7608 10.2621 13.397 9.62707 13.397 8.84369C13.397 8.06031 12.7608 7.42525 11.976 7.42525C11.1912 7.42525 10.555 8.06031 10.555 8.84369C10.555 9.62707 11.1912 10.2621 11.976 10.2621ZM7.25624 22.3909C6.88868 22.5014 6.61143 22.8243 6.61143 23.2081C6.61143 23.6456 6.96612 24.0003 7.40365 24.0003H16.3879C16.8296 24.0003 17.1877 23.6423 17.1877 23.2006C17.1877 22.8155 16.9111 22.4909 16.5433 22.377C15.6947 22.1142 14.896 21.6503 14.2236 20.9854L12.5018 19.2828C12.1808 18.9654 11.6641 18.9654 11.3431 19.2828L9.62137 20.9854C8.937 21.6621 8.12178 22.1306 7.25624 22.3909Z"
      fill="currentColor"
    />
  </svg>
);
