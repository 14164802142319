import { auth } from '@we-agile-you/firebase';
import {
  onAuthStateChanged as onAuthStateChangedFirebase,
  signInAnonymously as signInAnonymouslyFirebase,
  EmailAuthProvider,
  linkWithCredential,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signOut as signOutFirebase,
  updateEmail as updateEmailFirebase,
  updatePassword as updatePasswordFirebase,
} from 'firebase/auth';

/**
 * The user returned by auth
 * this is not the user used in our data layer, you need to get that from db
 */
interface SignedInUser {
  uid: string;
  email: string | null;
  isAnonymous: boolean;
}

export const onAuthStateChanged = (
  onAuthStateChanged: (user: SignedInUser | null) => void,
) => {
  return onAuthStateChangedFirebase(auth, (user) => {
    onAuthStateChanged(
      user
        ? {
            uid: user.uid,
            isAnonymous: user.isAnonymous,
            email: user.email,
          }
        : null,
    );
  });
};

export const signInAnonymously = () => signInAnonymouslyFirebase(auth);

export const linkEmailCredentialToCurrentUser = async (
  email: string,
  password: string,
) => {
  const user = auth.currentUser;

  if (!user?.isAnonymous) {
    return;
  }

  const credential = EmailAuthProvider.credential(email, password);

  return linkWithCredential(user, credential);
};

export const signInWithEmailAndPassword = (email: string, password: string) =>
  signInWithEmailAndPasswordFirebase(auth, email, password);

export const sendPasswordResetEmail = (email: string) =>
  auth &&
  sendPasswordResetEmailFirebase(auth, email, {
    url: 'https://planningpokeronline.com',
  });

export const signOut = () => signOutFirebase(auth);

export const deleteAccount = () => {
  const user = auth.currentUser;

  if (!user) return Promise.reject();

  return user.delete();
};

export const updateEmail = (password: string, newEmail: string) => {
  const user = auth.currentUser;

  if (!user || !user.email) return Promise.reject();

  return signInWithEmailAndPasswordFirebase(auth, user.email, password).then(
    function (userCredential) {
      if (!userCredential || !userCredential.user) return Promise.reject();

      return updateEmailFirebase(user, newEmail);
    },
  );
};

export const updatePassword = (password: string, newPassword: string) => {
  const user = auth.currentUser;

  if (!user || !user.email) return Promise.reject();

  return signInWithEmailAndPasswordFirebase(auth, user.email, password).then(
    function (userCredential) {
      if (!userCredential || !userCredential.user) return Promise.reject();

      return updatePasswordFirebase(user, newPassword);
    },
  );
};

export const reauthenticateWithPassword = (password: string) => {
  const user = auth.currentUser;

  if (!user || !user.email) return Promise.reject();

  return signInWithEmailAndPassword(user.email, password);
};
