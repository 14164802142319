import { auth, firestore } from '@we-agile-you/firebase';
import {
  serverTimestamp,
  collection,
  updateDoc,
  doc,
  deleteField,
} from 'firebase/firestore';

const RETROS_COLLECTION = 'retrospectives';

/**
 * startTimerForGame
 * @param tableId
 * @param timerDurationMinutes
 */
export const startTimerForGame = (
  retroId: string,
  timerDurationMinutes: number,
) => {
  const signedInUser = auth.currentUser;

  if (!retroId) return Promise.reject();
  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }
  return updateDoc(doc(collection(firestore, RETROS_COLLECTION), retroId), {
    timerStartedAt: serverTimestamp(),
    timerDurationMinutes,
    timerCurrentDurationMinutes: timerDurationMinutes,
  });
};

/**
 * cancelTimerForGame
 * @param tableId
 */
export const cancelTimerForGame = (retroId: string) => {
  const signedInUser = auth.currentUser;

  if (!retroId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return updateDoc(doc(collection(firestore, RETROS_COLLECTION), retroId), {
    timerStartedAt: deleteField(),
  });
};

/**
 * updateTimer
 * @param retroId
 * @param timerDurationMinutes
 */
export const updateCurrentTimerForGame = (
  retroId: string,
  timerCurrentDurationMinutes: number,
) => {
  const signedInUser = auth.currentUser;

  if (!retroId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return updateDoc(doc(collection(firestore, RETROS_COLLECTION), retroId), {
    timerCurrentDurationMinutes,
  });
};

/**
 * updateTimerAutoRestart
 * @param retroId
 * @param timerAutoRestart
 */
export const updateTimerAutoRestart = (
  retroId: string,
  timerAutoRestart: boolean,
) => {
  const signedInUser = auth.currentUser;

  if (!retroId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }
  return updateDoc(doc(collection(firestore, RETROS_COLLECTION), retroId), {
    timerAutoRestart,
  });
};
