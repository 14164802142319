import { getRandomGame } from "./games";
import { IceBreakerServer, IceBreakerServerActive } from "./types.d";
import { firestore } from "@we-agile-you/firebase";
import { ICE_BREAKER_COLLECTION } from "./constants";
import { onSnapshot, doc, setDoc, updateDoc } from "firebase/firestore";

export const createIceBreaker = (retroOrPPId: string) => {
  const iceBreakerRef = doc(
    firestore,
    `${ICE_BREAKER_COLLECTION}/${retroOrPPId}`
  );

  setDoc(iceBreakerRef, { isActive: false });
};

type startRandomIceBreakerGameProps = {
  retroOrPPId: string;
  startedByUserName: string;
  startedByUserUid: string;
};

export const startRandomIceBreakerGame = ({
  retroOrPPId,
  startedByUserName,
  startedByUserUid,
}: startRandomIceBreakerGameProps) => {
  const iceBreakerRef = doc(
    firestore,
    `${ICE_BREAKER_COLLECTION}/${retroOrPPId}`
  );

  const game = getRandomGame();

  const update: IceBreakerServerActive = {
    isActive: true,
    startedByUserName,
    startedByUserUid,
    gameType: game.type,
    gameTitle: game.title,
    currentStep: 0,
    showSolution: false,
    stepsLength: game.stepsLength,
    steps: game.steps,
  };

  setDoc(iceBreakerRef, update, { merge: true });
};

export const updateIceBreaker = (
  retroOrPPId: string,
  update: Partial<IceBreakerServer>,
  overwrite = false
) => {
  const iceBreakerRef = doc(
    firestore,
    `${ICE_BREAKER_COLLECTION}/${retroOrPPId}`
  );

  if (overwrite) {
    return setDoc(iceBreakerRef, update);
  }

  return updateDoc(iceBreakerRef, update);
};

type subscribeToIceBreakerProps = {
  retroOrPPId: string;
  onChange: (iceBreaker: IceBreakerServer) => void;
  onDoesntExist: () => void;
};
export const subscribeToIceBreaker = ({
  retroOrPPId,
  onDoesntExist,
  onChange,
}: subscribeToIceBreakerProps) => {
  const iceBreakerRef = doc(
    firestore,
    `${ICE_BREAKER_COLLECTION}/${retroOrPPId}`
  );

  return onSnapshot(
    iceBreakerRef,
    function (doc) {
      const iceBreaker: IceBreakerServer | undefined =
        doc.data() as IceBreakerServer;

      if (!iceBreaker) {
        onDoesntExist();

        return;
      }

      onChange(iceBreaker);
    },
    (error) => {
      console.error("Error");
      console.error(error);
    }
  );
};
