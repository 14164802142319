import { ConfirmModal } from '@we-agile-you/react-base';
import React, { useState } from 'react';

import { useNotification } from '../../spaces/notifications/useNotification';
import { useRetroActions } from '../../spaces/retrospective/hooks/useRetroActions';

interface MyRetrosModalProps {
  retroId: string;
  onClose: () => void;
  onRetroDeleted: () => void;
}

export const ConfirmDeleteRetroModal = ({
  onClose,
  onRetroDeleted,
  retroId,
}: MyRetrosModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { deleteRetro } = useRetroActions();
  const { showNotification } = useNotification();

  const handleConfirm = () => {
    setIsLoading(true);

    deleteRetro(retroId)
      .then(() => {
        setIsLoading(false);
        showNotification({ title: 'The retro has been deleted' });
        onRetroDeleted();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification({
          title: 'An error happened when trying to delete retro',
          style: 'error',
        });
        onClose();
      });
  };

  return (
    <ConfirmModal
      title="Are you sure you want to delete this retro?"
      content="Once you confirm it will no longer be available."
      isDanger
      confirmLabel="Delete retro"
      onConfirm={handleConfirm}
      cancelLabel="Cancel"
      onCancel={onClose}
      onClose={onClose}
      isLoading={isLoading}
    />
  );
};
