import {
  Button,
  FlexBox,
  Modal,
  ModalTitle,
  Paragraph,
  VerticalSpacing,
} from "@we-agile-you/react-base";
import React from "react";

import styles from "./StartIceBreakerModal.module.scss";

interface StartIceBreakerModalProps {
  onClickStart: () => void;
  onClose: () => void;
}

export const StartIceBreakerModal = ({
  onClose,
  onClickStart,
}: StartIceBreakerModalProps) => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle>{`Start Icebreaker for everyone?`}</ModalTitle>
      <FlexBox
        className={styles["question-container"]}
        justifyContent="center"
        alignItems="center"
      >
        <Paragraph size="h1">🍿🎥🎬</Paragraph>
      </FlexBox>
      <FlexBox className={styles["answer-container"]}>{""}</FlexBox>
      <VerticalSpacing spacing="spacing-xxl-2" />
      <div className={styles["submit-row"]}>
        <div>
          <Button buttonStyle="tertiary" onClick={onClose} isBlock>
            Cancel
          </Button>
        </div>
        <div>
          <Button onClick={onClickStart} isBlock>
            Start Icebreaker
          </Button>
        </div>
      </div>
    </Modal>
  );
};
