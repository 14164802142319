import { uuidv4 } from '@we-agile-you/js-base';
import useCurrentUser from '@we-agile-you/planning-poker-app/src/spaces/auth/hooks/useCurrentUser';
import {
  ButtonIcon,
  FlexBox,
  FormInput,
  Icon,
  Span,
  SubmitRow,
  Switch,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import { RetroLayout } from '@we-agile-you/types-planning-poker';
import React, { FormEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { updateCurrentUser } from '@we-agile-you/auth';

import styles from './LayoutForm.module.scss';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';

interface LayoutFormProps {
  isCreateForm?: boolean;
  layout?: RetroLayout;
  onCancel: () => void;
  onCreated: (teamId: string) => void;
}

const DEFAULT_LAYOUT: RetroLayout = {
  id: uuidv4(),
  columns: [
    { id: uuidv4(), name: '', type: 'simple' },
    { id: uuidv4(), name: '', type: 'simple' },
    { id: uuidv4(), name: '', type: 'actions' },
  ],
  name: '',
};

export const LayoutForm = ({
  isCreateForm,
  onCancel,
  onCreated,
  layout,
}: LayoutFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [_error, setError] = useState<string | null>(null);
  const [isNameManuallyChanged, setIsNameManuallyChanged] = useState(false);
  const { user } = useCurrentUser();
  const [nameInputValue, setNameInputValue] = useState(
    layout?.name || DEFAULT_LAYOUT.name,
  );
  const [columns, setColumns] = useState(
    layout?.columns || DEFAULT_LAYOUT.columns,
  );

  const { updateRetrospectiveLayout } = useRetroActions();

  useEffect(() => {
    if (isNameManuallyChanged) return;

    const name = columns.reduce(
      (previous, current) =>
        `${previous}${previous ? ', ' : ''}${current.name}`,
      '',
    );
    setNameInputValue(name);
  }, [columns]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    setError(null);
    setIsLoading(true);

    if (!nameInputValue) {
      setError('Please set a name to the team');
      setIsLoading(false);

      return;
    }

    if (!user) {
      setError('No signed user found');
      setIsLoading(false);

      return;
    }

    if (isCreateForm) {
      const layout = {
        name: nameInputValue,
        columns,
        id: uuidv4(),
      };

      updateCurrentUser({
        customRetroLayouts: user?.customRetroLayouts
          ? [...user.customRetroLayouts, layout]
          : [layout],
      })
        .then(() => {
          setIsLoading(false);
          onCreated(layout.id);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e?.message || 'An error happened saving your layout');
        });
    } else {
      updateRetrospectiveLayout({
        name: nameInputValue,
        columns,
      })
        .then(() => {
          setIsLoading(false);
          onCreated('');
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e?.message || 'An error happened saving your layout');
        });
    }
  };

  const handleColumnNameChange = (value: string, columnId: string) => {
    setColumns(
      columns.map((column) =>
        column.id === columnId ? { ...column, name: value } : column,
      ),
    );
  };

  const handleActionsCheckboxChange = (
    isChecked: boolean,
    columnId: string,
  ) => {
    setColumns(
      columns.map((column) =>
        column.id === columnId
          ? { ...column, type: isChecked ? 'actions' : 'simple' }
          : column,
      ),
    );
  };

  const handleAddColumn = () => {
    setColumns([...columns, { id: uuidv4(), name: '', type: 'simple' }]);
  };

  const handleDeleteColumn = (columnId: string) => {
    setColumns(columns.filter((column) => column.id !== columnId));
  };

  const handleNameChange = (value: string) => {
    setIsNameManuallyChanged(true);
    setNameInputValue(value);
  };
  function handleFormSubmit(event: FormEvent) {
    event.preventDefault();

    handleSubmit();
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={styles['name-wrapper']}>
        {isCreateForm && (
          <FormInput
            label="Layout name"
            value={nameInputValue}
            onChange={handleNameChange}
          />
        )}
      </div>
      <VerticalSpacing spacing="spacing-xl" />
      <div
        className={cx(
          styles['dashboard-wrapper'],
          isCreateForm && styles['dashboard-wrapper--create-form'],
        )}
      >
        <div className={styles.dashboard}>
          {columns.map((column, index) => (
            <div className={styles.column}>
              <div>
                <FormInput
                  label={`Column name`}
                  value={column.name}
                  onChange={(value) => handleColumnNameChange(value, column.id)}
                  isNoMargin
                />
              </div>
              <VerticalSpacing spacing="spacing-m" />
              <div
                className={cx(
                  styles['column-mock'],
                  column.type === 'actions' && styles['column-mock--actions'],
                )}
              >
                <div />
                <div />
                <div />
              </div>
              <VerticalSpacing spacing="spacing-xxs" />
              <FlexBox
                justifyContent="space-between"
                className={styles['column-footer']}
              >
                <FlexBox>
                  <Span>Actions</Span>
                  <Switch
                    id={`actions-${index}`}
                    isChecked={column.type === 'actions'}
                    theme="primary"
                    onChangeSwitch={(value) =>
                      handleActionsCheckboxChange(value, column.id)
                    }
                  />
                </FlexBox>
                <ButtonIcon
                  className={styles.thrash}
                  icon={<Icon icon="trash" />}
                  onClick={() => handleDeleteColumn(column.id)}
                />
              </FlexBox>
              <div></div>
            </div>
          ))}

          <button
            className={styles['add-column-button']}
            onClick={handleAddColumn}
            type="button"
          >
            <Icon icon="plus" />
          </button>
        </div>
      </div>
      <VerticalSpacing spacing="spacing-xl" />
      <VerticalSpacing spacing="spacing-xl" />
      <SubmitRow
        cancelLabel="Cancel"
        confirmLabel="Save layout"
        onCancel={handleCancel}
        align="strech"
        isLoading={isLoading}
      />
    </form>
  );
};
