import { useRef, useState, useEffect } from 'react';
import cx from 'classnames';

import styles from './RetroCardModal.module.scss';

const ENTER_LEAVE_ANIMATION_DURATION = 200;

type ModalRect = {
  width: number;
  height: number;
  top: number;
  left: number;
};

type ModalAnimationStep =
  | 'closed'
  | 'at-card-size'
  | 'at-card-size--transition'
  | 'entering-window'
  | 'centered-in-window'
  | 'at-modal-size'
  | 'leaving-window';

export const MODAL_WIDTH = 524;

export const useCardModalAnimation = (
  modal: React.RefObject<HTMLDivElement>,
  isOpen: boolean,
  onClose: () => void,
  cardDomElement?: HTMLDivElement,
) => {
  const [modalAnimationStep, setModalAnimationStep] =
    useState<ModalAnimationStep>('closed');
  const [zoomPercent, setZoomPercent] = useState<number | null>(100);

  const requestRef = useRef<number>();
  const modalRectRef = useRef<ModalRect>();

  const startTimestampEnters = useRef<number>();
  const startTimestampLeaves = useRef<number>();

  const animateEnters = () => {
    if (!startTimestampEnters.current) {
      return;
    }

    const elapsedAnimationTime = Date.now() - startTimestampEnters.current;

    setZoomPercent(
      (elapsedAnimationTime / ENTER_LEAVE_ANIMATION_DURATION) * 100,
    );

    if (elapsedAnimationTime >= ENTER_LEAVE_ANIMATION_DURATION) {
      // setModalAnimationStep('centered-in-window');
      setZoomPercent(100);
      return;
    }

    requestRef.current = requestAnimationFrame(animateEnters);
  };

  const animateLeaves = () => {
    if (!startTimestampLeaves.current) {
      return;
    }

    const elapsedAnimationTime = Date.now() - startTimestampLeaves.current;

    setZoomPercent(
      100 - (elapsedAnimationTime / ENTER_LEAVE_ANIMATION_DURATION) * 100,
    );

    if (elapsedAnimationTime >= ENTER_LEAVE_ANIMATION_DURATION) {
      setZoomPercent(null);
      setModalAnimationStep('closed');

      onClose();

      return;
    }

    requestRef.current = requestAnimationFrame(animateLeaves);
  };

  const startAnimationEnters = () => {
    const modalRect = modal.current?.getBoundingClientRect();
    startTimestampEnters.current = Date.now();

    setZoomPercent(0);

    if (modalRect) {
      modalRectRef.current = {
        width: modalRect.width,
        height: modalRect.height,
        top: modalRect.top,
        left: modalRect.left,
      };
    }

    setModalAnimationStep('at-card-size');

    requestRef.current = requestAnimationFrame(() => {
      setModalAnimationStep('at-card-size--transition');

      requestRef.current = requestAnimationFrame(() => {
        // setModalAnimationStep('entering-window');
        // requestRef.current = requestAnimationFrame(animateEnters);
      });
    });
  };

  const startAnimationLeaves = () => {
    const modalRect = modal.current?.getBoundingClientRect();
    startTimestampLeaves.current = Date.now();

    setZoomPercent(100);

    if (modalRect) {
      modalRectRef.current = {
        width: modalRect.width,
        height: modalRect.height,
        top: modalRect.top,
        left: modalRect.left,
      };
    }

    setModalAnimationStep('at-modal-size');

    requestRef.current = requestAnimationFrame(() => {
      setModalAnimationStep('leaving-window');
      requestRef.current = requestAnimationFrame(animateLeaves);
    });
  };
  useEffect(() => {
    if (isOpen) {
      startAnimationEnters();
    }
    return () => cancelAnimationFrame(requestRef.current || 0);
  }, [isOpen]); // Make sure the effect runs only once

  const handleClose = () => {
    startAnimationLeaves();
  };

  const cardRect = cardDomElement?.getBoundingClientRect();

  const modalStyle: React.CSSProperties = {};

  switch (modalAnimationStep) {
    case 'at-card-size':
      modalStyle.width = cardRect?.width;
      modalStyle.height = cardRect?.height;
      modalStyle.top = cardRect?.top;
      modalStyle.left = cardRect?.left;
      break;
    case 'at-card-size--transition':
      modalStyle.width = cardRect?.width;
      modalStyle.height = cardRect?.height;
      modalStyle.top = cardRect?.top;
      modalStyle.left = cardRect?.left;
      modalStyle.transitionDuration = `${
        ENTER_LEAVE_ANIMATION_DURATION / 1000
      }s`;
      break;
    case 'at-modal-size':
      modalStyle.width = modalRectRef.current?.width;
      modalStyle.height = modalRectRef.current?.height;
      modalStyle.top = modalRectRef.current?.top;
      modalStyle.left = modalRectRef.current?.left;
      modalStyle.transitionDuration = `${
        ENTER_LEAVE_ANIMATION_DURATION / 1000
      }s`;
      break;
    case 'entering-window':
    case 'centered-in-window':
      modalStyle.top = modalRectRef.current?.top;
      modalStyle.left = modalRectRef.current?.left;
      modalStyle.width = modalRectRef.current?.width;
      modalStyle.height = modalRectRef.current?.height;
      modalStyle.transitionDuration = `${
        ENTER_LEAVE_ANIMATION_DURATION / 1000
      }s`;
      break;
    case 'leaving-window':
      modalStyle.width = cardRect?.width;
      modalStyle.height = cardRect?.height;
      modalStyle.top = cardRect?.top;
      modalStyle.left = cardRect?.left;
      modalStyle.transitionDuration = `${
        ENTER_LEAVE_ANIMATION_DURATION / 1000
      }s`;
      break;
    case 'closed':
      modalStyle.top = '50%';
      modalStyle.left = '50%';
      modalStyle.transform = 'translate(-50%, -50%)';
      modalStyle.width = MODAL_WIDTH;
      break;
  }

  if (modalAnimationStep === 'centered-in-window') {
    modalStyle.top = '50%';
    modalStyle.left = '50%';
    modalStyle.transform = 'translate(-50%, -50%)';
    modalStyle.height = 'auto';
    modalStyle.transitionDuration = `0s`;
  }

  modalStyle.zIndex = modalAnimationStep === 'closed' ? -1 : 10;

  if (modalAnimationStep === 'entering-window') {
    modalStyle.zIndex = 100;
  }

  const overlayClassName = cx(
    styles['overlay'],
    isOpen && styles['overlay--is-open'],
  );

  const modalClassName = cx(
    styles['retro-card-modal'],
    styles[`retro-card-modal--${modalAnimationStep}`],
  );

  return {
    handleClose,
    modalStyle,
    modalAnimationStep,
    zoomPercent,
    isNoteZoomed:
      modalAnimationStep !== 'at-card-size' &&
      modalAnimationStep !== 'leaving-window',
    isNoteCSSTransiton:
      modalAnimationStep === 'entering-window' ||
      modalAnimationStep === 'leaving-window' ||
      modalAnimationStep === 'centered-in-window',
    modalRect: modalRectRef.current,
    overlayClassName,
    modalClassName,
  };
};
