import { updateCurrentUser } from '@we-agile-you/auth';
import {
  updateRetrospective,
  startDraggingCard,
  stopDraggingCard,
  deleteRetro,
} from './../data/retroActions';
import { useCurrentRetro } from './useCurrentRetro';
import { RetrospectiveActionTypes } from './../types.d';
import {
  REQUEST_CREATE_RETROSPECTIVE,
  RECEIVE_CREATED_RETROSPECTIVE,
} from './../constants';
import { createRetrospective } from './../data/createRetrospective';
import { useDispatch } from 'react-redux';
import {
  RetroActionNoteServer,
  RetroCardActionServer,
  RetroCardServer,
  RetroNoteServer,
} from '@we-agile-you/types-planning-poker';
import { uuidv4 } from '@we-agile-you/js-base';
import {
  RetrospectiveServer,
  RetroColumnServer,
  RetroLayout,
} from '@we-agile-you/types-planning-poker';

import update from 'immutability-helper';
import useCurrentUser from '../../auth/hooks/useCurrentUser';
export const useRetroActions = () => {
  const dispatch = useDispatch();
  const { retroState, retro } = useCurrentRetro();
  const { uid, user } = useCurrentUser();

  return {
    createRetro: async (retro: Partial<RetrospectiveServer>) => {
      const createAction: RetrospectiveActionTypes = {
        type: REQUEST_CREATE_RETROSPECTIVE,
      };
      dispatch(createAction);

      if (!retro.columns && retro.layout) {
        retro.columns = retro.layout.columns.map((column) => ({
          id: column.id,
          name: column.name,
          columnType: column.type,
          cards: [],
        }));
      }

      const retroId = await createRetrospective(retro);

      if (!retroId) return;

      const receiveAction: RetrospectiveActionTypes = {
        type: RECEIVE_CREATED_RETROSPECTIVE,
        retrospectiveId: retroId,
      };

      dispatch(receiveAction);

      return retroId;
    },
    deleteRetro: (retroId: string) =>
      deleteRetro(retroId).then(() => {
        if (!user?.retroHistory) {
          return Promise.resolve();
        }

        return updateCurrentUser({
          retroHistory: user.retroHistory.filter(
            (retro) => retro.id !== retroId,
          ),
        });
      }),
    updateRetroSettings: async (settings: {
      name: string;
      teamId?: string;
      teamName?: string;
      likesLimit: number;
      canLikeMyNotes: boolean;
    }) => {
      if (!retro?.id) return;

      return updateRetrospective(retro.id, settings);
    },
    addNote: async (text: string, columnId: string) => {
      const column = retroState?.columns.find(
        (column) => column.id === columnId,
      );

      if (!retroState?.id || !uid || !column) return;

      let note: RetroActionNoteServer | RetroNoteServer;
      let card: RetroCardActionServer | RetroCardServer;

      if (column.columnType === 'actions') {
        note = {
          id: uuidv4(),
          ownerId: uid,
          text,
          type: 'action',
          assignTo: null,
        };

        card = {
          id: uuidv4(),
          noteId: note.id,
          type: 'action',
        };
      } else {
        note = {
          id: uuidv4(),
          ownerId: uid,
          text,
          likes: [],
          type: 'simple',
        };

        card = {
          id: uuidv4(),
          noteIds: [note.id],
          type: 'simple',
        };
      }

      return updateRetrospective(retroState.id, {
        columns: [
          ...retroState.columns.filter((column) => column.id !== columnId),
          {
            ...column,
            cards: [...column.cards, card.id],
          },
        ],
        cards: [...retroState.cards, card],
        notes: [...retroState.notes, note],
      });
    },
    moveCard: async (
      fromIndex: number,
      toIndex: number,
      fromColumnId: string,
      toColumnId: string,
    ) => {
      if (!retro?.id) return;

      const fromColumn = retroState.columns.find(
        (column) => column.id === fromColumnId,
      );
      const toColumn = retroState.columns.find(
        (column) => column.id === toColumnId,
      );
      const cardId = fromColumn?.cards[fromIndex];

      if (!fromColumn || !cardId || !toColumn) return;

      let columns: RetroColumnServer[];

      if (fromColumnId === toColumnId) {
        columns = retroState.columns.map((column) =>
          column.id !== fromColumnId
            ? column
            : {
                ...column,
                cards: update(column.cards, {
                  $splice: [
                    [fromIndex, 1],
                    [toIndex, 0, cardId],
                  ],
                }),
              },
        );
      } else {
        columns = retroState.columns.map((column) => {
          if (column.id === fromColumnId) {
            return {
              ...column,
              cards: update(fromColumn.cards, {
                $splice: [[fromIndex, 1]],
              }),
            };
          }
          if (column.id === toColumnId) {
            return {
              ...column,
              cards: update(toColumn.cards, {
                $splice: [[toIndex, 0, cardId]],
              }),
            };
          }

          return column;
        });
      }

      return updateRetrospective(retro.id, { columns });
    },
    stackCard: async (
      fromCardId: string,
      fromColumnId: string,
      toCardId: string,
    ) => {
      const fromColumn = retroState?.columns.find(
        (column) => column.id === fromColumnId,
      );
      const stackingCard = retroState?.cards.find(
        (card) => card.id === fromCardId,
      );

      if (!fromColumn || !retro?.id || !uid || !stackingCard) return;

      const columns: RetroColumnServer[] = [
        ...retroState.columns.map((column) =>
          column.id !== fromColumnId
            ? column
            : {
                ...column,
                cards: column.cards.filter((card) => card !== fromCardId),
              },
        ),
      ];

      const cards: (RetroCardActionServer | RetroCardServer)[] =
        retroState.cards
          .filter((card) => card.id !== fromCardId)
          .map((card) => {
            if (card.id !== toCardId) return card;

            if (card.type === 'simple' && stackingCard.type === 'simple') {
              return {
                ...card,
                noteIds: [...card.noteIds, ...stackingCard.noteIds],
              };
            }

            return card;
          });

      return updateRetrospective(retro.id, { columns, cards });
    },
    unstackNote: async (noteId: string) => {
      if (!retro?.id) return;

      const cardFrom = retroState.cards.find(
        (card) =>
          card.type === 'simple' &&
          !!card.noteIds.find((_noteId) => _noteId === noteId),
      );

      if (!cardFrom) return;

      const newCard: RetroCardServer = {
        id: uuidv4(),
        noteIds: [noteId],
        type: 'simple',
      };

      const cards: (RetroCardActionServer | RetroCardServer)[] = [
        ...retroState.cards.map((card) => {
          if (card.id === cardFrom.id && card.type === 'simple') {
            return {
              ...card,
              noteIds: card.noteIds.filter((_noteId) => noteId !== _noteId),
            };
          }

          return card;
        }),
        newCard,
      ];

      const columns: RetroColumnServer[] = retroState.columns.map((column) => {
        const cardIndex = column.cards.findIndex(
          (cardId) => cardId === cardFrom.id,
        );

        if (cardIndex !== -1) {
          return {
            ...column,
            cards: [
              ...column.cards.slice(0, cardIndex),
              newCard.id,
              ...column.cards.slice(cardIndex),
            ],
          };
        }

        return column;
      });

      return updateRetrospective(retro.id, { cards, columns });
    },
    editNote: async (noteId: string, text: string) => {
      if (!retroState?.id) return;

      return updateRetrospective(retroState.id, {
        notes: retroState.notes.map((note) =>
          note.id === noteId ? { ...note, text } : note,
        ),
      });
    },
    deleteNote: async (noteId: string) => {
      if (!retro?.id) return;

      const update: Partial<RetrospectiveServer> = {};

      const cards: (RetroCardServer | RetroCardActionServer | null)[] =
        retroState.cards.map((card) => {
          if (card.type === 'action' && card.noteId === noteId) {
            update.columns = retroState.columns.map((column) => {
              return {
                ...column,
                cards: column.cards.filter((_cardId) => _cardId !== card.id),
              };
            });
            return null;
          }

          if (
            card.type === 'simple' &&
            card.noteIds.find((_noteId) => noteId === _noteId)
          ) {
            if (card.noteIds.length === 1) {
              update.columns = retroState.columns.map((column) => {
                return {
                  ...column,
                  cards: column.cards.filter((_cardId) => _cardId !== card.id),
                };
              });
              return null;
            }

            return {
              ...card,
              noteIds: card.noteIds.filter((_noteId) => _noteId !== noteId),
            };
          }

          return card;
        });

      update.cards = cards.filter((card) => card !== null) as (
        | RetroCardServer
        | RetroCardActionServer
      )[];

      await updateRetrospective(retro.id, update);
    },
    startDraggingCard: async (cardId: string) => {
      if (!retro?.id) return;

      startDraggingCard(retro.id, cardId);
    },
    stopDraggingCard: async () => {
      if (!retro?.id) return;

      stopDraggingCard(retro.id);
    },
    assignNoteToPlayer: async (noteId: string, playerId: string) => {
      if (!retro?.id) return;

      const notes = retroState.notes.map((note) => {
        if (note.type === 'action' && noteId === note.id) {
          return {
            ...note,
            assignTo: playerId,
          };
        }

        return note;
      });

      await updateRetrospective(retro.id, { notes });
    },
    likeNote: async (noteId: string) => {
      if (!retro?.id || !uid) return;

      const hasLikedNote = retroState.likes?.find(
        (like) => like.uid === uid && like.noteId === noteId,
      );

      if (!retro.likes) {
        await updateRetrospective(retro.id, {
          likes: [{ uid, noteId }],
        });

        return;
      }

      if (hasLikedNote) {
        await updateRetrospective(retro.id, {
          likes: retro.likes.filter(
            (like) => like.uid !== uid || like.noteId !== noteId,
          ),
        });

        return;
      }

      const userVotesTotal = retro.likes.filter(
        (like) => like.uid === uid,
      ).length;

      if (!!retro.likesLimit && userVotesTotal >= retro.likesLimit) {
        throw new Error('likes_limit_reached');
      }

      await updateRetrospective(retro.id, {
        likes: [...retro.likes, { uid, noteId }],
      });
    },
    updateRetrospectiveLayout: async (layout: Partial<RetroLayout>) => {
      if (!retro?.id) return;

      const columns: RetroColumnServer[] = layout.columns
        ? layout.columns.map((layoutColunn) => {
            const column = retroState.columns.find(
              (retroColumn) => retroColumn.id === layoutColunn.id,
            );

            if (column) {
              return {
                ...column,
                name: layoutColunn.name,
                columnType: layoutColunn.type,
              };
            } else {
              return {
                id: layoutColunn.id,
                name: layoutColunn.name,
                columnType: layoutColunn.type,
                cards: [],
              };
            }
          })
        : retroState.columns;

      // TODO: delete cards and notes from deleted columns

      await updateRetrospective(retro.id, {
        layout: { ...retro.layout, ...layout },
        columns,
      });
    },
    updateColumnName: async (columnId: string, columnName: string) => {
      if (!retro?.id) return;

      updateRetrospective(retro.id, {
        layout: {
          ...retro.layout,
          columns: retro.layout.columns.map((column) =>
            column.id === columnId ? { ...column, name: columnName } : column,
          ),
        },
        columns: retroState?.columns.map((column) =>
          column.id === columnId ? { ...column, name: columnName } : column,
        ),
      });
    },
  };
};
