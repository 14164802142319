import { iceBreakerReducer } from '@we-agile-you/ice-breaker';
import { combineReducers, createStore as reduxCreateStore } from 'redux';

import {
  retrospectiveReducer,
  authReducer,
  userTeamsReducer,
} from '@we-agile-you/retrospective-app';

const rootReducer = combineReducers({
  auth: authReducer,
  retro: retrospectiveReducer,
  userTeams: userTeamsReducer,
  iceBreaker: iceBreakerReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export function createStore(preloadedState?: AppState) {
  const isReduxExtensionEnabled =
    process.env.GATSBY_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__;

  const store = isReduxExtensionEnabled
    ? reduxCreateStore(
        rootReducer,
        preloadedState,
        window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    : reduxCreateStore(rootReducer, preloadedState);

  return store;
}

export function createStoreSSR(preloadedState?: AppState) {
  const store = reduxCreateStore(rootReducer, preloadedState);

  return store;
}
