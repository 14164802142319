import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { auth, firestore } from '@we-agile-you/firebase';

interface Message {
  name?: string;
  email?: string;
  message: string;
}

const CONTACTS_COLLECTION = 'contacts';
/**
 * createContactMessage
 * @param message message to send
 */
export const createContactMessage = (message: Message) => {
  const signedInUser = auth.currentUser;

  return addDoc(collection(firestore, CONTACTS_COLLECTION), {
    createdAt: serverTimestamp(),
    ownerId: signedInUser && signedInUser.uid,
    ...message,
  });
};
