import { IceBreakerState } from "./../types.d";
import { useSelector } from "react-redux";

export const useCurrentIceBreaker = () => {
  const iceBreaker = useSelector(
    (state: any) => state.iceBreaker as IceBreakerState
  );

  return { iceBreaker };
};
