import {
  DropdownItem,
  HoritzontalSpacing,
  Icon,
  ProfileImage,
} from '@we-agile-you/react-base';
import React from 'react';

import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import styles from './AuthDropdown.module.scss';
import { useAppContext } from '../../../spaces/app/hooks/useAppContext';
import { useAuthActions } from '../../../spaces/auth/hooks/useAuthActions';

interface AuthDropdownProps {
  onClose: () => void;
}
export const AuthDropdown = ({ onClose }: AuthDropdownProps) => {
  const { user, isAnonymous } = useCurrentUser();
  const { signOut } = useAuthActions();

  const appContext = useAppContext();

  const setIsOpenAuthType = appContext.authModal[1];
  const setIsMyAccountModalOpen = appContext.myAccountModal[1];
  const setIsContactModalOpen = appContext.contactModal[1];
  const setIsEditDisplayNameModalOpen = appContext.editDisplayNameModal[1];
  const setIsMyRetrosModalOpen = appContext.myRetrosModal[1];

  const isGuestOrRegistered = !!user?.displayName || !isAnonymous;

  return (
    <div className={styles['dropdown']} data-test="auth-dropdown">
      {isGuestOrRegistered && (
        <>
          <div className={styles['display-name-box']}>
            <ProfileImage
              size="xl"
              src={user?.profilePictureUrl}
              alt={user?.displayName || ''}
              onClick={() => {
                setIsEditDisplayNameModalOpen(true);
                onClose();
              }}
            />
            <div className={styles['display-name-wrapper']}>
              <div
                className={styles['display-name']}
                onClick={() => {
                  setIsEditDisplayNameModalOpen(true);
                  onClose();
                }}
              >
                <span className={styles['display-name-value']}>
                  {user?.displayName || ''}{' '}
                </span>
                <Icon icon="pencil" />
              </div>
              {isAnonymous && (
                <div className={styles['display-name-label-wrapper']}>
                  <span className={styles['display-name-label']}>
                    Guest user
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={styles['divider']} />

          <DropdownItem
            label="My retros"
            icon={<Icon icon="retro-board" />}
            onClick={() => {
              setIsMyRetrosModalOpen(true);
              onClose();
            }}
            rightContent={
              <div>
                <HoritzontalSpacing spacing="spacing-s" />
              </div>
            }
          />
        </>
      )}
      {!isAnonymous && (
        <DropdownItem
          onClick={() => {
            setIsMyAccountModalOpen(true);
            onClose();
          }}
          label="My account"
          icon={<Icon icon="cog" />}
        />
      )}
      {!isAnonymous && <div className={styles['divider']} />}
      {/* ----- Join us block */}
      {isAnonymous && user?.displayName && (
        <>
          <DropdownItem
            label="Login"
            icon={<Icon icon="login" />}
            onClick={() => {
              setIsOpenAuthType('sign-in');
              onClose();
            }}
          />
          <DropdownItem
            label="Sign up"
            icon={<Icon icon="user-new" />}
            onClick={() => {
              setIsOpenAuthType('sign-up');
              onClose();
            }}
          />
          <div className={styles['divider']} />
        </>
      )}
      {/* ----- Permanent block */}
      <DropdownItem
        label="Contact us"
        icon={<Icon icon="comment" />}
        onClick={() => {
          setIsContactModalOpen(true);
          onClose();
        }}
      />
      <a className={styles['item-link']} href="/legal-notice" target="_blank">
        <DropdownItem label="Legal notice" icon={<Icon icon="shield" />} />
      </a>
      {/* ----- Signout block */}
      {isGuestOrRegistered && (
        <>
          <div className={styles['divider']} />
          <DropdownItem
            label="Sign out"
            icon={<Icon icon="union" />}
            onClick={signOut}
          />
        </>
      )}
    </div>
  );
};
