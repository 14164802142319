import React, { useRef, useState } from 'react';
import { RetroCard } from '../../../components/molecules/RetroCard/RetroCard';
import { useDrop } from 'react-dnd';
import {
  FlexBox,
  HoritzontalSpacing,
  Span,
  InlineEdit,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import cx from 'classnames';

import styles from './CardsContainer.module.scss';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { AddNoteInline } from '../AddNoteInline/AddNoteInline';
import {
  DragItem,
  RetroCard as RetroCardType,
  RetroCardAction,
} from '@we-agile-you/types-planning-poker';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';
import withScrolling from 'react-dnd-scrolling';

interface CardsContainerProps {
  columnId: string;
  onCardMove: (
    fromIndex: number,
    toIndex: number,
    fromColumnName: string,
    toColumnName: string,
  ) => void;
  onStartDrag: (cardId: string) => void;
  onEndDrag: (cardId: string) => void;
  onStackCard: (
    fromId: string,
    fromColumnName: string,
    toId: string,
    toColumnName: string,
  ) => void;
  onCardOpen: (card: RetroCardType | RetroCardAction) => void;
  onRenderCard: (element: HTMLDivElement, cardId: string) => void;
  currentOpenedCardId: string | null;
}

const ScrollingComponent = withScrolling('ul');

export const CardsContainer = ({
  columnId,
  onCardMove,
  onStartDrag,
  onEndDrag,
  onStackCard,
  onCardOpen,
  onRenderCard,
  currentOpenedCardId,
}: CardsContainerProps) => {
  const { retro } = useCurrentRetro();
  const [isAttachingToAnotherCard, setIsAttachingToAnotherCard] =
    useState<null | DragItem>(null);
  const lastCard = useRef<HTMLDivElement>(null);

  const { updateColumnName } = useRetroActions();

  const column = retro?.columns?.find((column) => columnId === column.id);
  const accept = column?.columnType === 'actions' ? ['action'] : ['simple'];

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    // drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover: (_item, monitor) => {
      if (monitor.isOver({ shallow: true }) && isAttachingToAnotherCard) {
        setIsAttachingToAnotherCard(null);
      }
    },
    drop: (e: any) => {
      if (e.columnId !== columnId) {
        onCardMove(e.index, 0, e.columnId, columnId);
      }
    },
  });

  const isActive = isOver && canDrop;

  const handleCardMove = (
    fromIndex: number,
    toIndex: number,
    fromColumnName: string,
    toColumnName: string,
  ) => {
    onCardMove(fromIndex, toIndex, fromColumnName, toColumnName);
  };

  const handleCardDragEnd = (id: string) => {
    if (isAttachingToAnotherCard) {
      setIsAttachingToAnotherCard(null);
    }

    onEndDrag(id);
  };

  const handleNoteAdded = () => {
    lastCard.current?.scrollIntoView();
  };

  const handleColumnNameChange = (newName: string) => {
    updateColumnName(columnId, newName);
  };

  const cardsLength = column?.cards.length;
  const likesLength =
    column?.columnType === 'simple' &&
    column?.cards.reduce((likesCount, card) => {
      if (card.type === 'action') return likesCount;

      return (
        likesCount +
        card.notes.reduce(
          (likesCount, note) =>
            likesCount +
            (retro?.likes.filter((like) => like.noteId === note.id).length ||
              0),
          0,
        )
      );
    }, 0);

  return (
    <div
      ref={drop}
      role="cards-container"
      className={cx(
        styles['cards-container'],
        isActive && styles['cards-container--active'],
        canDrop && styles['cards-container--can-drop'],
      )}
    >
      {column && (
        <>
          <div className={styles['cards-container__top']}>
            <FlexBox justifyContent="space-between">
              <div className={styles['cards-container__title-container']}>
                <InlineEdit
                  value={column.name}
                  submitLabel="Save"
                  buttonLabel="Column name"
                  onConfirmValue={handleColumnNameChange}
                  hideActions
                  valueClassname={styles.title}
                  elementClassname={styles['title-input']}
                  confirmValueWithEnterKey
                  confirmValueWithClickOutside
                  isRequired
                  type="input"
                />
              </div>
              <div className={styles['cards-container__title-right']}>
                <HoritzontalSpacing spacing="spacing-xs" />
                {!!cardsLength && (
                  <Span size="micro" color="grey500" spanStyle="semibold">
                    {`${cardsLength} ${
                      column.columnType === 'actions' ? 'action' : 'note'
                    }${cardsLength !== 1 ? 's' : ''}`}

                    {likesLength !== false
                      ? ` · ${likesLength} like${likesLength !== 1 ? 's' : ''}`
                      : ''}
                  </Span>
                )}
              </div>
            </FlexBox>
          </div>
          <ScrollingComponent className={styles['cards-container__cards']}>
            {column.cards.map((card, index) => {
              return (
                <li key={card.id}>
                  <RetroCard
                    index={index}
                    columnId={columnId}
                    card={card}
                    key={card.id}
                    isAttaching={isAttachingToAnotherCard?.card.id === card.id}
                    onMoveCard={handleCardMove}
                    onStartDrag={() => onStartDrag(card.id)}
                    onEndDrag={() => handleCardDragEnd(card.id)}
                    isOpen={
                      !!currentOpenedCardId && currentOpenedCardId === card.id
                    }
                    onIsAttaching={() =>
                      setIsAttachingToAnotherCard({
                        card,
                        index,
                        columnId,
                      })
                    }
                    onStackCard={onStackCard}
                    onOpen={onCardOpen}
                    onRender={(element) => onRenderCard(element, card.id)}
                  />
                  {index < column.cards.length - 1 && (
                    <VerticalSpacing spacing="spacing-s" />
                  )}
                </li>
              );
            })}
            <div ref={lastCard} />
          </ScrollingComponent>
          <div className={styles['cards-container__bottom']}>
            <AddNoteInline column={column} onNoteAdded={handleNoteAdded} />
          </div>
        </>
      )}
    </div>
  );
};
