import React from 'react';

export const Eye2 = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4853 8.48528L23.2929 11.2929C23.6834 11.6834 23.6834 12.3166 23.2929 12.7071L20.4853 15.5147C15.799 20.201 8.20098 20.201 3.51469 15.5147L0.707078 12.7071C0.316554 12.3166 0.316554 11.6834 0.707078 11.2929L3.51469 8.48528C8.20098 3.79899 15.799 3.79899 20.4853 8.48528ZM8.99997 12C8.99997 10.3431 10.3431 9 12 9C13.6568 9 15 10.3431 15 12C15 13.6569 13.6568 15 12 15C10.3431 15 8.99997 13.6569 8.99997 12ZM12 7C9.23855 7 6.99997 9.23858 6.99997 12C6.99997 14.7614 9.23855 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
      fill="currentColor"
    />
  </svg>
);
