import React from 'react';

import { PlayerRetro } from '@we-agile-you/types-planning-poker';
import { Profile } from '../Profile/Profile';
import { Select } from '../..';

interface PlayerPickerProps {
  placeholder: string;
  size?: 'body' | 'micro';
  players: PlayerRetro[];
  selectedPlayerId: string | null;
  onSelectPlayer: (playerId: string) => void;
}

export const PlayerPicker = ({
  placeholder,
  players,
  selectedPlayerId,
  size,
  onSelectPlayer,
}: PlayerPickerProps) => {
  const options = players.map((player) => ({
    value: player.uid,
    label: player.displayName,
    meta: player,
  }));

  const selectedPlayer =
    selectedPlayerId &&
    options.find((option) => option.value === selectedPlayerId);

  return (
    document?.body && (
      <Select
        options={options}
        formatOptionLabel={(option) => (
          <Profile
            player={option.meta}
            size={size === 'micro' ? size : 'small'}
          />
        )}
        value={selectedPlayer}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        isNoBorders
        onChange={(option) =>
          onSelectPlayer(
            selectedPlayer && selectedPlayer.value === option.value
              ? null
              : option.value,
          )
        }
      />
    )
  );
};
