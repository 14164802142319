import {
  Button,
  FlexBox,
  Icon,
  Modal,
  ModalTitle,
  Paragraph,
  Span,
  VerticalSpacing,
} from "@we-agile-you/react-base";
import React from "react";
import { updateIceBreaker } from "../../data";
import { useCurrentIceBreaker } from "../../hooks/useCurrentIceBreaker";

import styles from "./IceBreakerModal.module.scss";

interface IceBreakerModalProps {
  onClose: () => void;
  retroOrPPId: string;
}

export const IceBreakerModal = ({
  onClose,
  retroOrPPId,
}: IceBreakerModalProps) => {
  const { iceBreaker } = useCurrentIceBreaker();

  if (!iceBreaker.isActive) return null;

  const handleShowAnswer = () => {
    updateIceBreaker(retroOrPPId, {
      showSolution: true,
    });
  };

  const handleNextQuestion = () => {
    updateIceBreaker(retroOrPPId, {
      showSolution: false,
      currentStep: (iceBreaker.currentStep || 0) + 1,
    });
  };

  const resumeRetro = () =>
    updateIceBreaker(
      retroOrPPId,
      {
        isActive: false,
      },
      true
    );

  const handleFinish = () => resumeRetro();

  const currentStep =
    typeof iceBreaker.currentStep === "number" &&
    iceBreaker.steps[iceBreaker.currentStep];
  const isLastStep =
    typeof iceBreaker.currentStep === "number" &&
    iceBreaker.currentStep >= iceBreaker.steps.length - 1;

  return (
    <Modal onClose={onClose}>
      {currentStep && (
        <div>
          <ModalTitle>{`Guess the movie (${
            (iceBreaker.currentStep || 0) + 1
          })`}</ModalTitle>
          <FlexBox
            alignItems="center"
            justifyContent="center"
            className={styles["question-container"]}
          >
            <Paragraph size="h1">{currentStep.question}</Paragraph>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent="center"
            className={styles["answer-container"]}
          >
            {iceBreaker.showSolution && (
              <FlexBox alignItems="center" justifyContent="center">
                <span className={styles["answer-icon"]}>
                  <Icon icon="check-solid" />
                </span>
                <Span spanStyle="bold">{currentStep.answer}</Span>
              </FlexBox>
            )}
          </FlexBox>
          <VerticalSpacing spacing="spacing-xxl-2" />
          <div className={styles["submit-row"]}>
            <div>
              {iceBreaker.showSolution && isLastStep ? (
                <Span color="grey500">Game has ended</Span>
              ) : (
                <Button buttonStyle="tertiary" onClick={handleFinish} isBlock>
                  Finish game
                </Button>
              )}
            </div>
            <div>
              {iceBreaker.showSolution ? (
                isLastStep ? (
                  <Button onClick={handleFinish} isBlock>
                    Finish
                  </Button>
                ) : (
                  <Button onClick={handleNextQuestion} isBlock>
                    Next
                  </Button>
                )
              ) : (
                <Button onClick={handleShowAnswer} isBlock>
                  Check
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
