import { useEffect } from 'react';

import useCurrentUser from '../../auth/hooks/useCurrentUser';
import { subscribeToTeams } from '../data/teams';
import { useDispatch } from 'react-redux';
import {
  REQUEST_SUBSCRIBE_TO_USER_TEAMS,
  RECEIVE_USER_TEAMS_DATA_CHANGE,
} from '../constants';

export const useSubscribeToCurrentUserTeams = () => {
  const { uid } = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!uid) {
      dispatch({
        type: RECEIVE_USER_TEAMS_DATA_CHANGE,
        teams: [],
      });

      return;
    }

    dispatch({
      type: REQUEST_SUBSCRIBE_TO_USER_TEAMS,
    });

    const unsubscribe = subscribeToTeams(uid, (teams) => {
      dispatch({
        type: RECEIVE_USER_TEAMS_DATA_CHANGE,
        teams,
      });
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [uid]);
};
