import {
  ButtonLink,
  ConfirmModal,
  InlineEdit,
  Paragraph,
} from '@we-agile-you/react-base';
import React, { useState } from 'react';
import { useNotification } from '../../spaces/notifications/useNotification';
import {
  deleteTeam,
  editTeam,
  getTeamGames,
} from '../../spaces/retrospective/data/teams';
import { useUserTeams } from '../../spaces/retrospective/hooks/useUserTeams';

import styles from './ManageTeamsForm.module.scss';

type TeamToDelete = {
  gamesTotal: number;
  teamId: string;
};

export const ManageTeamsForm = () => {
  const { teams } = useUserTeams();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<
    false | TeamToDelete
  >(false);

  const { showNotification } = useNotification();

  const handleDeleteTeamClick = (teamId: string) => {
    getTeamGames(teamId).then((games) => {
      setIsConfirmDeleteOpen({ teamId, gamesTotal: games.length });
    });
  };

  const handleConfirmDeleteTeam = () => {
    if (!isConfirmDeleteOpen) return;

    deleteTeam(isConfirmDeleteOpen.teamId)
      .then(() => {
        showNotification({
          title: 'Team deleted successfully',
        });
        setIsConfirmDeleteOpen(false);
      })
      .catch((error) => {
        console.error(error);

        showNotification({
          title: 'Something went wrong when deleting team',
          style: 'error',
          content: error?.message,
        });
        setIsConfirmDeleteOpen(false);
      });
  };

  const handleTeamNameChange = (teamId: string, name: string) => {
    editTeam(teamId, { name })
      .then(() => {
        showNotification({
          title: 'Team name changed successfully',
        });
      })
      .catch((error) => {
        console.error(error);

        showNotification({
          title: 'Something went wrong when deleting team',
          style: 'error',
          content: error?.message,
        });
      });
  };

  return (
    <>
      <ul className={styles['decks']}>
        {teams?.length ? (
          teams.map((team, i) => (
            <li key={i}>
              <div>
                <InlineEdit
                  type="input"
                  value={team.name}
                  onConfirmValue={(value) =>
                    handleTeamNameChange(team.id, value)
                  }
                  isRequired
                  hideActions
                  valueClassname={styles['team-name']}
                  confirmValueWithEnterKey
                  confirmValueWithClickOutside
                />
              </div>
              <div className={styles['delete-button']}>
                <ButtonLink
                  buttonColor="danger"
                  onClick={() => handleDeleteTeamClick(team.id)}
                >
                  Delete team
                </ButtonLink>
              </div>
            </li>
          ))
        ) : (
          <Paragraph color="grey500">
            {"You don't have any team created"}
          </Paragraph>
        )}
      </ul>
      {isConfirmDeleteOpen && (
        <ConfirmModal
          title="Are you sure you want to delete the team?"
          isDanger
          content={
            isConfirmDeleteOpen.gamesTotal
              ? `${isConfirmDeleteOpen.gamesTotal} games will also be deleted`
              : ''
          }
          onClose={() => setIsConfirmDeleteOpen(false)}
          onConfirm={handleConfirmDeleteTeam}
          cancelLabel="Cancel"
          confirmLabel={
            isConfirmDeleteOpen.gamesTotal
              ? 'Delete team and games'
              : 'Delete team '
          }
        />
      )}
    </>
  );
};
