import { AppState } from './../../../../../../sites/retrospective-site/src/state/createStore';
import { useSelector } from 'react-redux';
import { mapRetroStateToRetro } from '../utils';

export const useCurrentRetro = () => {
  const retroState = useSelector((state: AppState) => state.retro);

  const retro = retroState ? mapRetroStateToRetro(retroState) : null;

  const playersDragging =
    retro?.players.filter((player) => !!player.draggingNoteId) || [];

  return { retro, retroState, playersDragging };
};
