import React from 'react';

interface LogoProps {
  className?: string;
  color?: 'primary' | 'grey500';
}

const COLORS = {
  primary: '#3993FF',
  grey500: '#a8aeb2',
};

export const LogoIcon = ({ className, color }: LogoProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="white" />
      <g clipPath="url(#clip0_4416_57337)">
        <path
          d="M19.0248 11.5293L20.1242 18.5482C20.1626 18.7846 20.2775 19.0015 20.4507 19.165C20.6239 19.3285 20.8459 19.4293 21.0818 19.4519C21.3177 19.4744 21.5543 19.4173 21.7548 19.2895C21.9552 19.1617 22.1082 18.9703 22.1897 18.7454L23.4841 15.2135C24.3593 15.7931 25.4132 16.0308 26.4491 15.8824C27.485 15.7339 28.432 15.2094 29.1135 14.4068C29.7907 13.6149 30.1484 12.5944 30.1155 11.548C30.0827 10.5017 29.6617 9.50619 28.9362 8.75936L32.2163 6.88589C32.4245 6.76939 32.5877 6.58515 32.6793 6.36306C32.7708 6.14097 32.7855 5.89408 32.7208 5.66252C32.6561 5.43097 32.5159 5.2283 32.3229 5.08749C32.1299 4.94669 31.8955 4.87603 31.6578 4.88692L24.5036 5.03929L22.0479 2.98654C21.1766 2.26643 20.0617 1.91868 18.9407 2.01747C17.8196 2.11626 16.781 2.65374 16.0461 3.51539C15.6883 3.93888 15.4175 4.43017 15.2494 4.96062C15.0814 5.49106 15.0195 6.05005 15.0673 6.60494C15.1152 7.15984 15.2719 7.69951 15.5282 8.19254C15.7846 8.68556 16.1354 9.12207 16.5603 9.47653L19.0248 11.5293ZM18.5018 5.55922C18.8678 5.12734 19.387 4.85807 19.9474 4.80945C20.5079 4.76082 21.0648 4.9367 21.4983 5.29923C21.712 5.47586 21.8887 5.69388 22.0181 5.94046C22.1475 6.18704 22.2269 6.45726 22.2516 6.73527C22.2764 7.01327 22.246 7.29347 22.1623 7.55946C22.0786 7.82545 21.9433 8.07187 21.7642 8.28429C21.3966 8.71676 20.8759 8.98623 20.314 9.03483C19.7521 9.08344 19.1938 8.90729 18.7589 8.54423C18.5447 8.36768 18.3678 8.14949 18.2386 7.90256C18.1093 7.65563 18.0303 7.38499 18.0063 7.10666C17.9823 6.82832 18.0139 6.54795 18.099 6.28214C18.1841 6.01634 18.3211 5.77055 18.5018 5.55922V5.55922Z"
          fill={COLORS[color || 'primary']}
        />
        <path
          d="M33.9804 17.4543V17.3558C33.9804 17.284 33.9183 17.2034 33.8829 17.1317C33.5678 16.4909 33.0139 16.003 32.3431 15.7752C31.6722 15.5474 30.9394 15.5985 30.3058 15.917C29.6721 16.2356 29.1896 16.7957 28.9643 17.474C28.739 18.1523 28.7895 18.8933 29.1045 19.534C30.0019 21.4594 30.3448 23.6015 30.094 25.7148C29.8431 27.8281 29.0086 29.8272 27.6861 31.4832C26.3065 33.2277 24.4602 34.5345 22.3669 35.2481C20.2274 36.0002 17.9188 36.1122 15.718 35.5708C13.4546 35.0318 11.3907 33.85 9.76943 32.1644C8.93331 31.3201 8.22793 30.3531 7.67719 29.2959C7.39324 28.7664 7.14449 28.2184 6.93254 27.6555C6.73092 27.0847 6.56803 26.5007 6.44496 25.9075C5.92226 23.4828 6.09448 20.9578 6.94141 18.6286C7.80993 16.2431 9.32443 14.1525 11.312 12.5958C9.11932 14.0273 7.37266 16.0577 6.27435 18.4517C5.17605 20.8457 4.77107 23.5054 5.1063 26.1227C5.25524 27.469 5.60806 28.7841 6.15239 30.022C6.6739 31.2826 7.38239 32.4554 8.25345 33.5001C10.0034 35.667 12.3284 37.2841 14.9556 38.1614C16.2839 38.6 17.6644 38.8562 19.0602 38.9233C20.4738 38.9607 21.8862 38.8131 23.2623 38.4841C26.0683 37.7779 28.6193 36.283 30.6205 34.1724C32.6749 31.9849 34.0794 29.2573 34.6735 26.301C35.2676 23.3448 35.0275 20.2791 33.9804 17.4543V17.4543Z"
          fill={COLORS[color || 'primary']}
        />
      </g>
      <defs>
        <clipPath id="clip0_4416_57337">
          <rect
            width="30"
            height="36.9231"
            fill="white"
            transform="translate(5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
