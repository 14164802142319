import {
  RetroActionNoteServer,
  RetroCard,
  RetroCardAction,
  RetroColumn,
  RetroNoteServer,
  Retrospective,
  RetrospectiveState,
} from '@we-agile-you/types-planning-poker';
import { uuidv4 } from '@we-agile-you/js-base';

export const mapRetroStateToRetro = (retroState: RetrospectiveState) => {
  const columns: RetroColumn[] = retroState.columns.map((columnServer) => {
    return {
      ...columnServer,
      cards: columnServer.cards
        ? columnServer.cards.map((cardId) => {
            const cardServer = retroState.cards.find(
              (card) => card.id === cardId,
            );

            if (!cardServer) {
              return { id: uuidv4(), notes: [], type: 'simple' };
            }

            if (cardServer.type === 'action') {
              const card: RetroCardAction = {
                ...cardServer,
                note: retroState.notes.find(
                  (note) => note.id === cardServer.noteId,
                ) as RetroActionNoteServer,
              };

              return card;
            } else {
              const card: RetroCard = {
                ...cardServer,
                notes: cardServer.noteIds.map(
                  (noteId) =>
                    retroState.notes.find(
                      (note) => note.id === noteId,
                    ) as RetroNoteServer,
                ),
              };

              return card;
            }
          })
        : [],
    };
  });

  const retro: Retrospective = {
    ...retroState,
    team: {
      id: retroState.teamId,
      name: retroState.teamName,
    },
    columns,
  };

  return retro;
};

export const getCardFromRetro = (cardId: string, retro: Retrospective) => {
  const column = retro.columns.find((column) =>
    column.cards?.find((card) => card.id === cardId),
  );

  return column?.cards.find((card) => card.id === cardId);
};
