import {
  RetroCard,
  RetroCardAction,
  DragItem,
} from '@we-agile-you/types-planning-poker';
import { useDrag } from 'react-dnd';

export const useCardDrag = (
  index: number,
  card: RetroCard | RetroCardAction,
  columnId: string,
  onStartDrag: () => void,
  onEndDrag: () => void,
) =>
  useDrag({
    type: card.type,
    item: () => {
      onStartDrag();
      const dragItem: DragItem = { card, index, columnId };
      return dragItem;
    },
    end: onEndDrag,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
