import {
  Button,
  ButtonDropdown,
  ButtonIcon,
  DropdownItem,
  Icon,
} from '@we-agile-you/react-base';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from '../../../../components/atoms/Link/Link';

import { AuthButton } from '../../../AuthButton/AuthButton';
import styles from './HeaderRetro.module.scss';
import { LogoIcon } from '../../../../components/atoms/LogoIcon/LogoIcon';
import { useCurrentRetro } from '../../../../spaces/retrospective/hooks/useCurrentRetro';
import { OnlinePlayers } from '../../OnlinePlayers/OnlinePlayers';
import { useAppContext } from '../../../../spaces/app/hooks/useAppContext';
import { BurgerButtonDropdown } from '../../../../components/molecules/BurgerButtonDropdown/BurgerButtonDropdown';
import { Timer } from '../../../../spaces/timer/Timer/Timer';
import { IceBreakerButton } from '@we-agile-you/ice-breaker';

import { useNotification } from '../../../../spaces/notifications/useNotification';
import { useSubscribeToPreviousRetros } from '../../../../spaces/retrospective/hooks/useSubscribeToPreviousRetros';

export const HeaderRetro = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { retro } = useCurrentRetro();
  const appContext = useAppContext();
  const [isGameMenuOpen, setIsGameMenuOpen] = useState(false);
  const { showNotification } = useNotification();
  const { retros } = useSubscribeToPreviousRetros({ limit: 1 });

  const previousRetroId = retros[0]?.retro.id;

  useEffect(() => {
    const handleWindowScroll = () => {
      setIsScrolled(
        document.body.scrollTop > 20 || document.documentElement.scrollTop > 20,
      );
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  const className = cx(styles['header'], {
    [styles['header--scrolled']]: isScrolled,
  });

  const isLoading = !retro?.id;

  const handlePreviousRetroClick = (event: React.MouseEvent) => {
    if (!previousRetroId) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.preventDefault();
      showNotification({
        title: `There are no previous retros for the team "${
          retro?.team.name || 'team'
        }"`,
        style: 'error',
        content:
          'You will be able to check the previous retro when you have a history for this team.',
      });
    }
  };

  return (
    <div className={className}>
      <div className={styles['left']}>
        <div className={styles['burger-button']}>
          <BurgerButtonDropdown isPoker />
        </div>
        <Link to="/" className={styles['logo-link']}>
          <LogoIcon className={styles['logo-icon']} color="primary" />
          <span className={styles['beta-label']}>Beta</span>
        </Link>
      </div>
      <div className={cx(styles['middle'])}>
        {!isLoading && (
          <div className={styles['game-name-container']}>
            <ButtonDropdown
              isOpen={isGameMenuOpen}
              onIsOpenChange={setIsGameMenuOpen}
              className={styles['game-name']}
              data-test="button-dropdown-game-title"
              dropdown={
                <>
                  <DropdownItem
                    label="Game settings"
                    onClick={() => {
                      appContext.settingsModal[1](true);
                      setIsGameMenuOpen(false);
                    }}
                    icon={<Icon icon="cog" />}
                  ></DropdownItem>
                  <div className={styles['hide-small-screen']}>
                    <DropdownItem
                      label="Edit game layout"
                      icon={<Icon icon="issues" />}
                      onClick={() => {
                        appContext.editLayoutModal[1](true);
                        setIsGameMenuOpen(false);
                      }}
                    ></DropdownItem>
                  </div>
                  <div className={styles['show-small-screen']}>
                    <a
                      href={`/${previousRetroId || '#'}`}
                      target="_blank"
                      className={styles['prev-retro-link']}
                    >
                      <DropdownItem
                        label="Open Previous retro"
                        icon={<Icon icon="issues" />}
                        onClick={handlePreviousRetroClick}
                      ></DropdownItem>
                    </a>
                  </div>
                </>
              }
            >
              <span data-test="span-game">{retro.name || ''}</span>
            </ButtonDropdown>
            <span className={styles['settings']}>
              <a href={`/${previousRetroId || '#'}`} target="_blank">
                <ButtonIcon
                  tooltip={`Previous retro`}
                  className={styles['previous-retro-button']}
                  onClick={handlePreviousRetroClick}
                  icon={<Icon icon="previous-retro" />}
                />
              </a>
            </span>
          </div>
        )}
      </div>
      <div className={styles['right']}>
        {retro?.id && (
          <div className={cx(styles['ice-breaker-button'])}>
            <IceBreakerButton retroOrPPId={retro?.id} />
          </div>
        )}
        <Timer />
        <OnlinePlayers />
        <div className={styles['header-actions']}>
          <div
            className={cx(styles['header-action'], styles['invite-players'])}
          >
            <Button
              buttonStyle="secondary"
              onClick={() => appContext.invitePlayersModal[1](true)}
              icon={<Icon icon="invite" />}
              isHeaderButton
              size="small"
            >
              <span>Share</span>
            </Button>
          </div>
        </div>
        <div className={styles['auth-button']}>
          <AuthButton buttonColor="primary" />
        </div>
      </div>
    </div>
  );
};
