import { FC, memo, useRef } from 'react';
import cx from 'classnames';
import React from 'react';

import styles from './RetroCard.module.scss';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import {
  RetroCard as RetroCardType,
  RetroCardAction,
} from '@we-agile-you/types-planning-poker';
import { useCardDrop } from './useCardDrop';
import { useCardDrag } from './useCardDrag';
import { RetroNote } from './RetroNote';

export interface CardProps {
  card: RetroCardType | RetroCardAction;
  columnId: string;
  index: number;
  isAttaching: boolean;
  onStartDrag: () => void;
  onEndDrag: () => void;
  onIsAttaching: () => void;
  onStackCard: (
    fromId: string,
    fromColumnName: string,
    toId: string,
    toColumnName: string,
  ) => void;
  onMoveCard: (
    fromIndex: number,
    toIndex: number,
    fromColumnName: string,
    toColumnName: string,
  ) => void;
  onRender: (domElement: HTMLDivElement) => void;
  isOpen: boolean;
  onOpen: (card: RetroCardType | RetroCardAction) => void;
}

export const RetroCard: FC<CardProps> = memo(
  ({
    card,
    index,
    isAttaching,
    isOpen,
    onStartDrag,
    onEndDrag,
    onIsAttaching,
    onMoveCard,
    onStackCard,
    onOpen,
    onRender,
    columnId,
  }: CardProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { playersDragging } = useCurrentRetro();
    const wrapper = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useCardDrop(
      ref,
      index,
      card,
      columnId,
      onIsAttaching,
      onStackCard,
      onMoveCard,
    );

    const [{}, drag] = useCardDrag(
      index,
      card,
      columnId,
      onStartDrag,
      onEndDrag,
    );

    const playerDragging = playersDragging.find(
      (draggingNote) => draggingNote.draggingNoteId === card.id,
    );

    drag(drop(ref));

    const notes = card.type === 'action' ? [card.note] : card.notes;

    const handleClick = () => {
      onOpen(card);
    };

    return (
      <div
        ref={(element) => {
          if (!element) return;

          ref.current = element;
          onRender(element);
        }}
        role="Box"
        className={cx(
          styles['card'],
          !!playerDragging && styles['card--dragging'],
          isAttaching && styles['card--attaching'],
          isOpen && styles['card--open'],
        )}
        data-handler-id={handlerId}
        key={card.id}
      >
        <div ref={wrapper} onClick={handleClick}>
          {notes.map((note, i) => (
            <div key={note.id}>
              {
                <RetroNote
                  key={note.id}
                  note={note}
                  isStack={notes.length > 1}
                />
              }
              {i < notes.length - 1 && (
                <div key={`${i}-sep`} className={styles['stack-seperator']} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  },
);
