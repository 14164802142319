import { Icon, Modal, VerticalSpacing } from '@we-agile-you/react-base';
import React, { useEffect, useState } from 'react';

import { PokerTableSummary } from '@we-agile-you/types-planning-poker';
import { List, ListElement } from './List';
import styles from './MyRetrosModal.module.scss';
import { Tabs } from './Tabs';
import { ConfirmDeleteRetroModal } from './ConfirmDeleteRetroModal';
import useCurrentUser from '../../spaces/auth/hooks/useCurrentUser';
import { navigate } from 'gatsby';
import { getMonthWithDayAndYearAndTimeInLocalTime } from '@we-agile-you/js-base';
import { useCurrentRetro } from '../../spaces/retrospective/hooks/useCurrentRetro';
import { getCurrentUserRetrosCreated } from '@we-agile-you/auth';

interface MyRetrosModalProps {
  onClose: () => void;
}

export const MyRetrosModal = ({ onClose }: MyRetrosModalProps) => {
  const { uid, user } = useCurrentUser();
  const { retro } = useCurrentRetro();
  const [currentTab, setCurrentTab] = useState('last-retros');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<
    false | ListElement
  >(false);
  const [retrosCreated, setRetrosCreated] = useState<
    PokerTableSummary[] | null
  >(null);

  useEffect(() => {
    if (uid && !retrosCreated) {
      getCurrentUserRetrosCreated().then((retros) => {
        setRetrosCreated(retros);
      });
    }
  }, [uid, retrosCreated]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleRetroDelete = (retro: ListElement) => {
    setIsConfirmModalOpen(retro);
  };

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        titleTopBar={
          <div className={styles['top-bar-content']}>
            <div className={styles['top-bar-title']}>
              <Icon icon="retro-board" />
              <span className={styles['title-span']}>My Retros</span>
            </div>
            <div className={styles.tabs}>
              <Tabs
                selectedTab={currentTab}
                elements={[
                  { name: 'Last retros', id: 'last-retros' },
                  { name: 'Created retros', id: 'created-retros' },
                ]}
                onChange={(id) => setCurrentTab(id)}
              />
            </div>
          </div>
        }
        width="big"
        height="full-height"
      >
        <div>
          <VerticalSpacing spacing="spacing-xxl-4" />
          <VerticalSpacing spacing="spacing-xxl-4" />
          {currentTab === 'last-retros' ? (
            <div>
              {user?.retroHistory && user?.retroHistory.length > 0 ? (
                <List
                  onLinkClick={() => handleCloseModal()}
                  data-test="my-retros-list"
                  elements={user?.retroHistory.map((retro) => ({
                    name: retro.name || '',
                    id: retro.id,
                    to: `/${retro.id}`,
                    meta: retro.lastJoinedAtMilis
                      ? `Last joined at ${getMonthWithDayAndYearAndTimeInLocalTime(
                          new Date(retro.lastJoinedAtMilis),
                        )}`
                      : `${retro.id}`,
                    onDelete: !!retrosCreated?.find(
                      (_retro) => retro.id === _retro.id,
                    )
                      ? handleRetroDelete
                      : undefined,
                  }))}
                />
              ) : (
                <div className={styles['empty-message']}>
                  You haven´t joined any retro, when you do it will appear here.
                </div>
              )}
            </div>
          ) : (
            <div>
              {retrosCreated === null ? (
                <div className={styles['empty-message']}>Loading</div>
              ) : retrosCreated && retrosCreated.length > 0 ? (
                <List
                  data-test="created-retros-list"
                  onLinkClick={() => handleCloseModal()}
                  elements={retrosCreated.map((retro) => ({
                    id: retro.id,
                    name: retro.name || '',
                    to: `/${retro.id}`,
                    meta: retro.createdAt
                      ? `Created at ${getMonthWithDayAndYearAndTimeInLocalTime(
                          retro.createdAt,
                        )}`
                      : `${retro.id}`,
                    onDelete: handleRetroDelete,
                  }))}
                />
              ) : (
                <div className={styles['empty-message']}>
                  You haven´t created any retro, when you do it will appear
                  here.
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      {isConfirmModalOpen && (
        <ConfirmDeleteRetroModal
          onRetroDeleted={() => {
            if (retro?.id && isConfirmModalOpen.id === retro?.id) {
              navigate(`/`);

              return;
            }
            setIsConfirmModalOpen(false);
            getCurrentUserRetrosCreated().then((retros) => {
              setRetrosCreated(retros);
            });
          }}
          onClose={() => setIsConfirmModalOpen(false)}
          retroId={isConfirmModalOpen.id}
        />
      )}
    </>
  );
};
