import { AppState } from './../../../../../../sites/retrospective-site/src/state/createStore';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../auth/hooks/useCurrentUser';

type UserTeamCreated = {
  name: string;
  id: string;
  createdAt: Date;
};
type UserTeamHistory = {
  name: string;
  id: string;
  lastJoinedAt: Date;
};
export const useUserTeams = () => {
  const createdTeams = useSelector((state: AppState) => state.userTeams?.teams);
  const { user } = useCurrentUser();

  const userCreatedTeams: UserTeamCreated[] = createdTeams
    ? createdTeams.map((createdTeam) => ({
        name: createdTeam.name,
        id: createdTeam.id,
        createdAt: createdTeam.createdAt || new Date(1000),
      }))
    : [];

  const userHistoryTeams: UserTeamHistory[] = user?.teamHistory
    ? user?.teamHistory.map((historyTeam) => ({
        name: historyTeam.name,
        id: historyTeam.id,
        lastJoinedAt: new Date(historyTeam.lastJoinedAtMilis || 1000),
      }))
    : [];

  const teamsAll: (UserTeamCreated | UserTeamHistory)[] = [
    ...userCreatedTeams,
    ...userHistoryTeams,
  ];

  const teamsUnique = teamsAll.filter((team) => {
    const teams = teamsAll.filter((_team) => _team.id === team.id);

    if (teams.length > 1 && (team as UserTeamCreated).createdAt) {
      return false;
    }

    return true;
  });

  const teams = teamsUnique.sort((team1, team2) => {
    const dateTeam1: Date =
      typeof (team1 as UserTeamHistory).lastJoinedAt !== 'undefined'
        ? (team1 as UserTeamHistory).lastJoinedAt
        : (team1 as UserTeamCreated).createdAt;
    const dateTeam2: Date =
      typeof (team2 as UserTeamHistory).lastJoinedAt !== 'undefined'
        ? (team2 as UserTeamHistory).lastJoinedAt
        : (team2 as UserTeamCreated).createdAt;

    return (dateTeam2?.getTime() || 1000) - (dateTeam1?.getTime() || 1000);
  });

  return { teams };
};
