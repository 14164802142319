import {
  PlayerRetro,
  RetrospectiveServer,
  RetrospectiveState,
} from '@we-agile-you/types-planning-poker';
import { firestore } from '@we-agile-you/firebase';
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  limit,
  orderBy,
} from 'firebase/firestore';

const RETROS_COLLECTION = 'retrospectives';

export const subscribeToRetro = (
  retroId: string,
  teamId: string,
  onRetroChanged: (retro: RetrospectiveState) => void,
  onRetroPlayersChanged: (players: PlayerRetro[]) => void,
  onIsLastRetroChange: (isLastRetroChange: boolean) => void,
  onRetroDontExist: () => void,
) => {
  const retroRef = doc(firestore, `${RETROS_COLLECTION}/${retroId}`);
  const playersRef = query(
    collection(firestore, 'players-retros'),
    where('retroId', '==', retroId),
  );
  const retrosRef = query(
    collection(firestore, RETROS_COLLECTION),
    where('teamId', '==', teamId),
    orderBy('createdAt', 'desc'),
    limit(1),
  );

  let unsubscribeToRetro = [
    onSnapshot(
      retroRef,
      function (doc) {
        const retroServer: RetrospectiveServer | undefined =
          doc.data() as RetrospectiveServer;

        if (!retroServer) {
          onRetroDontExist();
          return;
        }

        const retroState: RetrospectiveState = {
          id: doc.id,
          ...retroServer,
          createdAt: retroServer.createdAt?.toDate(),
          timerStartedAt: retroServer.timerStartedAt?.toDate(),
          isLastRetro: true,
        };

        onRetroChanged(retroState);
      },
      (error) => {
        console.error('Error');
        console.error(error);
      },
    ),
    onSnapshot(playersRef, function (querySnapshot) {
      const players: PlayerRetro[] = [];

      querySnapshot.forEach(function (doc) {
        players.push({
          uid: '',
          retroId: '',
          ...doc.data(),
        });
      });

      onRetroPlayersChanged(players);
    }),
    onSnapshot(retrosRef, function (querySnapshot) {
      let isLastRetro = false;

      querySnapshot.forEach(function (doc) {
        if (doc.id === retroId) {
          isLastRetro = true;
        }
      });

      onIsLastRetroChange(isLastRetro);
    }),
  ];

  return async function unsubscribeFromRetrospective() {
    unsubscribeToRetro.forEach((unsubscribe) => unsubscribe());

    unsubscribeToRetro = [];
  };
};

export const subscribeToPreviousRetros = (
  teamID: string,
  retroCreatedAt: Date,
  onRetrosChange: (retros: RetrospectiveState[]) => void,
  maxRetros?: number,
) => {
  const queryConstraints = [
    where('teamId', '==', teamID),
    where('createdAt', '<', retroCreatedAt),
    orderBy('createdAt', 'desc'),
  ];
  if (maxRetros) {
    queryConstraints.push(limit(maxRetros));
  }
  const q = query(
    collection(firestore, RETROS_COLLECTION),
    ...queryConstraints,
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const retros: RetrospectiveState[] = [];

    querySnapshot.forEach((doc) => {
      const retroServer: RetrospectiveServer | undefined =
        doc.data() as RetrospectiveServer;

      if (!retroServer) {
        return;
      }

      const retroState: RetrospectiveState = {
        id: doc.id,
        ...retroServer,
        createdAt: retroServer.createdAt?.toDate(),
        timerStartedAt: retroServer.timerStartedAt?.toDate(),
        isLastRetro: false,
      };

      retros.push(retroState);
    });

    onRetrosChange(retros);
  });

  return unsubscribe;
};
