import { IceBreakerServer } from "../types";
import { subscribeToIceBreaker, createIceBreaker } from "../data";
import {
  REQUEST_SUBSCRIBE_TO_ICE_BREAKER,
  RECEIVE_ICE_BREAKER_DATA_CHANGE,
  UNSUBSCRIBE_TO_ICE_BREAKER,
} from "../constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useSubscribeToIceBreaker = (retroOrPPId: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: REQUEST_SUBSCRIBE_TO_ICE_BREAKER,
    });

    const handleIceBreakerChange = (iceBreaker: Partial<IceBreakerServer>) => {
      dispatch({
        type: RECEIVE_ICE_BREAKER_DATA_CHANGE,
        data: iceBreaker,
      });
    };

    const handleIceBreakerDoesntExist = () => {
      createIceBreaker(retroOrPPId);
    };

    const unsubscribeToIceBreaker = subscribeToIceBreaker({
      retroOrPPId,
      onChange: handleIceBreakerChange,
      onDoesntExist: handleIceBreakerDoesntExist,
    });

    return function cleanup() {
      unsubscribeToIceBreaker();

      dispatch({
        type: UNSUBSCRIBE_TO_ICE_BREAKER,
      });
    };
  }, [retroOrPPId, dispatch]);
};
