import { ProfileImage, useHandleClickOuside } from '@we-agile-you/react-base';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';

import styles from './OnlinePlayers.module.scss';

const PROFILE_WIDTH = 24;
const OVERLAY_RATIO = 1.3;

export const OnlinePlayers = () => {
  const { retro } = useCurrentRetro();

  const [isExpanded, setIsExpanded] = useState(false);
  const [maxPlayers, setMaxPlayers] = useState(5);
  const containerRef = useRef<HTMLUListElement>(null);
  const playersHiddenRef = useRef<HTMLLIElement>(null);

  const refs = useMemo(() => [containerRef], []);

  const cb = useCallback(() => {
    setIsExpanded(false);
  }, []);

  useHandleClickOuside(refs, cb, true);

  useEffect(() => {
    const onResize = () => {
      let maxPlayers = 5;

      setMaxPlayers(maxPlayers);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const retroPlayers = retro?.players;

  if (!retroPlayers?.length || retroPlayers.length < 2) {
    return null;
  }

  const players = isExpanded ? retroPlayers : retroPlayers.slice(0, maxPlayers);

  const playersHidden =
    retroPlayers.length && retroPlayers.length > maxPlayers
      ? retroPlayers.length - maxPlayers
      : 0;

  return (
    <div
      style={{
        width: `${
          (Math.min(players.length, maxPlayers) *
            (PROFILE_WIDTH / OVERLAY_RATIO) +
            Math.min(players.length, maxPlayers) * 2 +
            PROFILE_WIDTH / 2 +
            12) /
          10
        }rem`,
        position: 'relative',
        height: '3.2rem',
      }}
      className={cx(styles['online-players-wrapper'])}
    >
      <ul
        ref={containerRef}
        className={cx(
          styles['online-players'],
          isExpanded && styles['online-players--expanded'],
        )}
        style={{
          width: `${
            (players.length * (PROFILE_WIDTH / OVERLAY_RATIO) +
              players.length * 2 +
              PROFILE_WIDTH / 2 +
              12) /
            10
          }rem`,
          position: 'absolute',
          right: 0,
        }}
      >
        {players.map((player, index) => {
          const computedIndex = index >= maxPlayers ? index + 1 : index;
          return (
            <li
              style={{
                zIndex: computedIndex,
                right: `${
                  (computedIndex * (PROFILE_WIDTH / OVERLAY_RATIO)) / 10
                }rem`,
              }}
              onClick={() => setIsExpanded(true)}
            >
              <ProfileImage
                src={player.profilePictureUrl}
                alt={player.displayName}
                tooltip={player.displayName}
                size="s"
              />
            </li>
          );
        })}
        {playersHidden > 0 && (
          <li
            style={{
              zIndex: maxPlayers,
              right: `${
                (maxPlayers * (PROFILE_WIDTH / OVERLAY_RATIO)) / 10
              }rem`,
            }}
            ref={playersHiddenRef}
            className={styles['players-hidden']}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {`+${playersHidden}`}
          </li>
        )}
      </ul>
    </div>
  );
};
