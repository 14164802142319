import { uuidv4 } from '@we-agile-you/js-base';
import { firestore, auth } from '@we-agile-you/firebase';
import {
  RetroActionNote,
  RetroActionNoteServer,
  RetroCardActionServer,
  RetrospectiveServer,
} from '@we-agile-you/types-planning-poker';
import {
  updateDoc,
  doc,
  setDoc,
  collection,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  query,
  limit,
} from 'firebase/firestore';

const RETROSPECTIVE_COLLECTION = 'retrospectives';
const RETRO_PLAYERS_COLLECTION = 'players-retros';

export const updateRetrospective = (
  retroId: string,
  retro: Partial<RetrospectiveServer>,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return updateDoc(
    doc(firestore, `${RETROSPECTIVE_COLLECTION}/${retroId}`),
    retro,
  );
};

export const deleteRetro = (retroId: string) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return deleteDoc(
    doc(collection(firestore, RETROSPECTIVE_COLLECTION), retroId),
  );
};

export const startDraggingCard = (retroId: string, cardId: string) => {
  if (!auth) return;

  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return setDoc(
    doc(
      firestore,
      `${RETRO_PLAYERS_COLLECTION}/${signedInUser.uid}_${retroId}`,
    ),
    {
      draggingNoteId: cardId,
    },
    { merge: true },
  );
};

export const stopDraggingCard = (retroId: string) => {
  if (!auth) return;

  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return setDoc(
    doc(
      firestore,
      `${RETRO_PLAYERS_COLLECTION}/${signedInUser.uid}_${retroId}`,
    ),
    {
      draggingNoteId: null,
    },
    { merge: true },
  );
};

export const addActionNoteToLastTeamsRetro = async (
  note: RetroActionNote,
  teamId: string,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    throw new Error('No valid user is signed in.');
  }

  const lastRetrosSnapshot = await getDocs(
    query(
      collection(firestore, RETROSPECTIVE_COLLECTION),
      where('teamId', '==', teamId),
      orderBy('createdAt', 'desc'),
      limit(1),
    ),
  );

  const lastRetroSnapshot = lastRetrosSnapshot.docs[0];

  if (!lastRetroSnapshot) {
    throw new Error("Last retro doesn't exist");
  }
  const lastRetro = lastRetroSnapshot.data() as RetrospectiveServer;

  let isAddedToColumn: string | false = false;

  const notes = lastRetro.notes || [];
  const cards = lastRetro.cards || [];

  if (notes.find((note_) => note_.id === note.id)) {
    throw new Error('This action is already imported');
  }

  const columns = lastRetro.columns.map((column) => {
    if (column.columnType === 'actions') {
      isAddedToColumn = column.id;

      const newNote: RetroActionNoteServer = {
        ...note,
      };
      const newCard: RetroCardActionServer = {
        id: uuidv4(),
        type: 'action',
        noteId: newNote.id,
      };

      notes.push(newNote);
      cards.push(newCard);

      return { ...column, cards: [...column.cards, newCard.id] };
    }

    return column;
  });

  if (!isAddedToColumn) {
    throw new Error("Last retro doesn't have an actions column");
  }

  return updateDoc(lastRetroSnapshot.ref, {
    notes,
    cards,
    columns,
  });
};
