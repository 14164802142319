import { Modal, ModalTitle } from '@we-agile-you/react-base';
import React from 'react';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { LayoutForm } from '../LayoutForm/LayoutForm';

interface EditLayoutModalProps {
  onClose: () => void;
}

const EditLayoutModal: React.FC<EditLayoutModalProps> = ({ onClose }) => {
  // const { retro } = useCurrentRetro();
  const { uid } = useCurrentUser();
  const { retro } = useCurrentRetro();

  if (!uid) return null;

  return (
    <Modal width="biggest" onClose={onClose}>
      <>
        <ModalTitle>Edit game layout</ModalTitle>
        {retro?.layout && (
          <LayoutForm
            layout={retro?.layout}
            onCancel={onClose}
            onCreated={onClose}
          />
        )}
      </>
    </Modal>
  );
};

export default EditLayoutModal;
