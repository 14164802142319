import React from 'react';
// mockups
// /mockups
import styles from './HomeTemplate.module.scss';
import wall_notes from './images/wall_notes.svg';

import {
  Button,
  Container,
  Header1,
  Paragraph,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import { Navigation } from '../../organisms/Navigation/Navigation';
import { Footer } from '../../organisms/Footer/Footer';
import { Link } from '../../atoms/Link/Link';

// const SECTIONS = [
//   {
//     title: 'Create notes in real time',
//     text: 'What went good? What went bad? Actions? Create notes on each column in collaboration with other teammates.',
//     mockupSrc: voting,
//     mockupSrcFallback: votingJpg,
//   },
//   {
//     title: 'Assign actions to users',
//     text: 'Who will take care of the action item? Assign actions to users',
//     mockupSrc: results,
//     mockupSrcFallback: resultsJpg,
//   },
//   {
//     title: 'Get back to previous retro of the team',
//     text: 'Recap what were the main points and actions that were assigned.',
//     mockupSrc: issues,
//     mockupSrcFallback: issuesJpg,
//   },
//   {
//     title: 'Timer',
//     text: 'Set a time limit',
//     mockupSrc: jira,
//     mockupSrcFallback: jiraJpg,
//   },
//   {
//     title: 'On the way?',
//     text: `No problem! Retrospective Online web app works on all devices so you can write your notes from anywhere and at any time.`,
//     mockupSrc: iphone,
//     mockupSrcFallback: iphonePng,
//   },
// ];
export const HomeTemplate = () => {
  return (
    <>
      <Navigation isHome />
      <main className={styles['home-container']}>
        <VerticalSpacing spacing="spacing-xxl" />
        <Container className={styles['first-section']}>
          <div className={styles['first-section__left']}>
            <Header1>Retrospectives for agile development teams.</Header1>
            <VerticalSpacing key="spacer-1" spacing="spacing-l" />
            <Paragraph color="grey600">
              Simple and engaging tool to run retrospectives.
            </Paragraph>
            <VerticalSpacing key="spacer-2" spacing="spacing-l" />
            <div className={styles['first-section__cta']}>
              <Link to="/new-retrospective">
                <Button isBlock>Start new retrospective</Button>
              </Link>
            </div>
          </div>
          <div>
            <div className={styles['video-wrapper']}>
              <div className={styles['wall_notes']}>
                <img src={wall_notes} />
              </div>
            </div>
          </div>
        </Container>
      </main>
      <VerticalSpacing spacing="spacing-xxl-2" />
      <Footer />
    </>
  );
};
