import { Game } from "./types.d";

const movies = [
  ["🚢🧊💑", "Titanic"],
  ["🤐👊🤕🧼", "Fight Club"],
  ["🚣‍♂️🐯👳🏾", "Life of Pi"],
  ["🎥🏃‍♂️🍤🌲", "Forrest Gump"],
  ["⚡👦🏻⚗️📜", "Harry Potter"],
  ["👽📞🚲", "ET"],
  ["💊🕴️🔫🖥️", "Matrix"],
  ["⭐🔫🚀", "Star Wars"],
  ["🏎️🚰🎸🔫🏜", "Mad Max"],
  ["🥋👊👦", "Karate Kid"],
  ["🔎🐠", "Finding Nemo"],
  ["🐺🏦💸💊", "Wolf of Wall Street"],
  ["👦🏻💍🌋🧙‍♂️", "Lord of the rings"],
  ["🩸💎🔫", "Blood Diamond"],
  ["👑💬", "King's Speech"],
  ["🕰️🟠⚙️", "A Clockwork Orange"],
  ["🎥👰🔪👘", "Kill Bill"],
  ["🐓🏃‍♂️", "Chicken Run"],
  ["🦍🌎🦧", "Planet of the apes"],
  ["🐇🕳️👧♠️♣️", "Alice in Wonderland"],
  ["🏠🎈👴🏻👶", "Oben"],
  ["🕵🏼‍♂️🔫🔎🎩👣", "Sherlock Holmes"],
  ["😡🐂", "Raging bull"],
  ["🗡️🏃‍♂️", "Blade Runner"],
  ["👳🏾📺💵", "Slumdog Millionaire"],
  ["👊🐼🥋", "Kung Fu Panda"],
  ["⚫🐆", "Black Panther"],
  ["🥁🤕", "Whiplash"],
  ["👨‍🔬👴🥽🔫💰", "Breaking Bad"],
  ["🧟‍♀️🚶🧟‍♂️🪓", "The Walking Dead"],
  ["⚔️🐉🐺❄️", "Game of Thrones"],
  ["🟠🆕⚫", "Orange Is the New Black"],
  ["🐅👑", "Tiger King"],
  ["🦁👑", "The lion king"],
  ["👽🚲1️⃣1️⃣", "Stranger Things"],
  ["7️⃣", "Seven"],
  ["🌆🙏🇧🇷", "City of God"],
  ["🕷️🙃🕸️", "Spider Man"],
  ["🦇🤵💲🃏", "Batman"],
  ["🃏", "Joker"],
  ["👨‍✈️🤥✈️🏃‍♂️🕵️‍♂️", "Catch me if you can"],
  ["🦸‍♂️👨", "Superman"],
  ["👨🏾➡⚫", "Men in Black"],
  ["🧑‍⚕️👩‍⚕️👨‍⚕️🏥🩺", "Scrubs"],
  ["🚕🔫", "Taxi Driver"],
  ["🏢👨🏻‍💼🖇️🤣🤦‍♂️", "The Office"],
  ["🦕🦖👨‍🔬🚍🏃‍♂️", "Jurassic Park"],
  ["1️⃣2️⃣⛓️🏃🏿‍♂️🎻", "12 years slave"],
  ["🥊💪🤼", "Rocky"],
  ["🏴‍☠⛵️🦜", "Pirates of the Caribbean"],
  ["👻🎬", "Scary movie"],
  ["🚐👻🔫", "Ghostbusters"],
  ["🔨🧔🏼‍♀️", "Thor"],
  ["🦖🦕🌎", "Jurassic world"],
  ["🧞‍♂️👳🏽‍♀️", "Aladin"],
  ["🚫👀⬆️", "Don't look up"],
  ["👨🏾‍🦲🏀🐰", "Space Jam"],
  ["☕️🥐💎", "Breakfast at Tiffany's"],
  ["🦁🦛🦓🦒🐧", "Madagascar"],
  ["🧑🏼‍🚀🚀⚫️👵🏻", "Interstellar"],
  ["😎🔫💉❤️", "Pulp Fiction"],
  ["🐺🧝‍♀️🗡", "The Witcher"],
  ["👴🏼👦🏻🚗🕰", "Back to the Future"],
  ["🌴📖🐻🧒🏻", "The Jungle Book"],
  ["🦑🎲", "Squid Game"],
  ["👨‍❤️‍👨🗻🤠", "Brokeback Mountain"],
  ["🩸🧽👱", "Dexter"],
  ["🗡🌾🤚🏼🏟", "Gladiator"],
  ["🏠🃏👱‍♂️👱‍♀️", "House of Cards"],
  ["🥷🏠💰", "Money Heist (La Casa de Papel)"],
  ["🧒🏻🤖🎮1️⃣", "Ready Player One"],
  ["🛸🌎", "Independence Day"],
  ["🧔🏻‍♀️⛩🗡", "The Last Samurai"],
  ["👨🏻🕺🏻🎹", "Bohemian Rhapsody"],
  ["🟢🎭", "The Mask"],
  ["🦏🎲🐘🧩", "Jumanji"],
  ["👙💪🏖🏃‍♂️", "Baywatch"],
  ["5️⃣👩🏻‍🦰", "The Fifth Element"],
  ["🌊🌎🚤🔫", "Waterworld"],
  ["🚀🖖", "Star Trek"],
  ["👴🏼⌚️👶🏼", "The Curious Case of Benjamin Button"],
  ["🧔🏾‍♂️⛓🤠", "Django Unchained"],
  ["🌎🔥🛸🪐", "War of the Worlds"],
  ["🦍🗼", "King Kong"],
  ["🏦🥷🎰", "Ocean's Eleven"],
  ["🧽🍔", "SpongeBob SquarePant"],
  ["🎩🍫", "Charlie and the Chocolate Factory"],
  ["🧑🏻📹⛵️🌊", "The Truman Show"],
  ["😡🐤", "Angry Birds"],
  ["🇺🇸🍰", "American Pie"],
  ["📺🧟‍♀️", "The Ring"],
  ["👻📹", "Paranormal Activity"],
  ["💉💊🚃", "Trainspotting"],
  ["👸🐺🦌🐗", "Princess Mononoke"],
  ["🤫🐏🐏", "The Silence of the Lambs"],
  ["🚪🪓🤪", "Shining"],
  ["🤡🎈👦🏻", "It"],
  ["🔫😎🏍🦾", "Terminator"],
  ["👸❄️🏰", "Frozen"],
  ["🏠😱", "Home Alone"],
  ["🤠🧑🏼‍🚀🦖", "Toy Story"],
  ["🎃💀🎅🏼", "Nightmare Before Christmas"],
  ["🐢🐀👊🏼🍕", "Teenage Mutant Ninja Turtles"],
];

export const getRandomGame = (): Game => {
  const shuffledMovies = [...movies].sort(() => 0.5 - Math.random());

  return {
    title: "Guess the movie",
    type: "questions",
    stepsLength: shuffledMovies.length,
    steps: shuffledMovies.map(([emoji, title]) => ({
      question: emoji,
      answer: title,
    })),
  };
};
