import React from 'react';

export const ManageFacilitators = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8C9.65685 8 11 6.65685 11 5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5C5 6.65685 6.34315 8 8 8ZM13 5C13 6.48039 12.3566 7.81051 11.3343 8.72602C12.6384 9.32483 13.7535 10.2637 14.5655 11.4279C14.8815 11.8809 14.7704 12.5042 14.3174 12.8202C13.8644 13.1361 13.2411 13.0251 12.9251 12.5721C11.8392 11.0152 10.0381 10 8 10C4.68629 10 2 12.6863 2 16C2 16.5523 1.55228 17 1 17C0.447715 17 0 16.5523 0 16C0 12.7718 1.91213 9.99012 4.66559 8.72588C3.6433 7.81038 3 6.48032 3 5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5ZM17.1021 13C16.8637 13 16.6585 13.1682 16.6118 13.4019L16.4531 14.1956C15.9159 14.3705 15.4286 14.656 15.0181 15.0252L14.2503 14.7654C14.0246 14.6891 13.7763 14.7827 13.6571 14.9891L13.067 16.0112C12.9478 16.2176 12.9909 16.4794 13.17 16.6367L13.7782 17.1712C13.7218 17.4386 13.6922 17.7159 13.6922 18C13.6922 18.2842 13.7218 18.5615 13.7782 18.829L13.17 19.3635C12.9909 19.5208 12.9478 19.7827 13.067 19.9891L13.6571 21.0112C13.7763 21.2176 14.0246 21.3112 14.2503 21.2348L15.0183 20.975C15.4287 21.3441 15.9159 21.6295 16.4531 21.8044L16.6118 22.5981C16.6585 22.8318 16.8637 23 17.1021 23H18.2823C18.5206 23 18.7258 22.8318 18.7726 22.5981L18.9313 21.8044C19.4684 21.6295 19.9556 21.3441 20.3661 20.975L21.134 21.2348C21.3598 21.3112 21.6081 21.2176 21.7273 21.0112L22.3174 19.9891C22.4365 19.7827 22.3934 19.5208 22.2144 19.3635L21.6062 18.829C21.6625 18.5615 21.6922 18.2842 21.6922 18C21.6922 17.7159 21.6626 17.4386 21.6062 17.1712L22.2144 16.6367C22.3934 16.4794 22.4365 16.2176 22.3174 16.0112L21.7273 14.9891C21.6081 14.7827 21.3598 14.6891 21.134 14.7654L20.3663 15.0252C19.9558 14.656 19.4685 14.3705 18.9313 14.1956L18.7726 13.4019C18.7258 13.1682 18.5206 13 18.2823 13H17.1021ZM17.6922 20C18.7968 20 19.6922 19.1046 19.6922 18C19.6922 16.8954 18.7968 16 17.6922 16C16.5876 16 15.6922 16.8954 15.6922 18C15.6922 19.1046 16.5876 20 17.6922 20Z"
      fill="currentColor"
    />
  </svg>
);
