// -- Constants

export const REQUEST_CREATE_RETROSPECTIVE = 'REQUEST_CREATE_RETROSPECTIVE';
export const RECEIVE_CREATED_RETROSPECTIVE = 'RECEIVE_CREATED_RETROSPECTIVE';
export const REQUEST_SUBSCRIBE_TO_RETROSPECTIVE =
  'REQUEST_SUBSCRIBE_TO_RETROSPECTIVE';
export const RECEIVE_SUBSCRIBE_TO_RETROSPECTIVE =
  'RECEIVE_SUBSCRIBE_TO_RETROSPECTIVE';
export const REQUEST_SUBSCRIBE_TO_USER_TEAMS =
  'REQUEST_SUBSCRIBE_TO_USER_TEAMS';
export const RECEIVE_USER_TEAMS_DATA_CHANGE = 'RECEIVE_USER_TEAMS_DATA_CHANGE';
export const RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE =
  'RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE';
export const RECEIVE_IS_LAST_RETRO_CHANGE = 'RECEIVE_IS_LAST_RETRO_CHANGE';
export const RECEIVE_RETROSPECTIVE_DATA_CHANGE =
  'RECEIVE_RETROSPECTIVE_DATA_CHANGE';
export const UNSUBSCRIBE_TO_RETROSPECTIVE = 'UNSUBSCRIBE_TO_RETROSPECTIVE';

export const DEFAULT_DISPLAY_NAME = 'Anonymous';
