import { getMonthWithDayAndYearInLocalTime } from '@we-agile-you/js-base';
import {
  Button,
  FormGroup,
  FormInput,
  FormSelect,
  InlineAlert,
  SelectValue,
  Span,
} from '@we-agile-you/react-base';
import React, {
  FormEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';

import styles from './CreateRetroForm.module.scss';
import { RetroLayout } from '@we-agile-you/types-planning-poker';
import { useUserTeams } from '../../../spaces/retrospective/hooks/useUserTeams';
import { createTeam } from '../../../spaces/retrospective/data/teams';
import { Loading } from '../../../components/molecules/Loading/Loading';

const NEW_TEAM = 'NEW_TEAM';
const CUSTOM_LAYOUT = 'CUSTOM_LAYOUT';
const WAIT_LOADING_ERROR =
  'Please wait until layout and team selectors are loaded and try again.';

interface SettingsProps {
  onCreated: (retroId: string) => void;
  onSelectNewLayout: () => void;
  onSelectNewTeam: () => void;
  ownerId: string | null;
}

interface LayoutOption {
  label: ReactNode;
  value: string;
}

interface TeamOption {
  label: ReactNode;
  value: string;
}

const DEFAULT_LAYOUTS: RetroLayout[] = [
  {
    id: 'I like, I wish, Actions',
    columns: [
      { id: 'I like', name: 'I like', type: 'simple' },
      { id: 'I wish', name: 'I wish', type: 'simple' },
      { id: 'Actions', name: 'Actions', type: 'actions' },
    ],
    name: 'I like, I wish, Actions',
  },
  {
    id: 'Good, Bad, Actions',
    columns: [
      { id: 'Good', name: 'Good', type: 'simple' },
      { id: 'Bad', name: 'Bad', type: 'simple' },
      { id: 'Actions', name: 'Actions', type: 'actions' },
    ],
    name: 'Good, Bad, Actions',
  },
  {
    id: 'Continue, Stop, Actions',
    columns: [
      { id: 'Continue', name: 'Continue', type: 'simple' },
      { id: 'Stop', name: 'Stop', type: 'simple' },
      { id: 'Actions', name: 'Actions', type: 'actions' },
    ],
    name: 'Continue, Stop, Actions',
  },
];

export const CreateRetroForm = forwardRef(function CreateRetroForm(
  { onCreated, onSelectNewTeam, onSelectNewLayout }: SettingsProps,
  ref,
) {
  const { createRetro } = useRetroActions();
  const [selectedLayout, setSelectedLayout] =
    useState<SelectValue<LayoutOption>>(null);
  const [selectedTeam, setSelectedTeam] =
    useState<SelectValue<TeamOption>>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [teamInputValue, setTeamInputValue] = useState('');
  const [isLoadingFormData, setIsLoadingFormData] = useState(true);

  const teamInputRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const { user } = useCurrentUser();
  const { teams } = useUserTeams();
  const hasTeams = teams?.length;

  const customLayouts = useMemo(
    () =>
      user?.customRetroLayouts
        ? user?.customRetroLayouts.map((layout) => ({
            label: layout.name,
            value: layout.id,
          }))
        : [],
    [user?.customRetroLayouts],
  );

  const layoutOptions: LayoutOption[] = useMemo(
    () => [
      ...DEFAULT_LAYOUTS.map((layout) => ({
        label: layout.name,
        value: layout.id,
      })),
      ...customLayouts,
      {
        label: (
          <Span spanStyle="bold" color="primary">
            Create custom layout...
          </Span>
        ),
        value: CUSTOM_LAYOUT,
      },
    ],
    [customLayouts],
  );

  const teamOptions: TeamOption[] = useMemo(
    () =>
      teams?.length
        ? [
            ...teams.map(({ name, id }) => ({ label: name, value: id })),
            {
              label: (
                <Span spanStyle="bold" color="primary">
                  Create new team
                </Span>
              ),
              value: NEW_TEAM,
            },
          ]
        : [],
    [teams],
  );

  useEffect(() => {
    if (typeof teams !== 'undefined' && user) {
      setIsLoadingFormData(false);
    }
  }, [teams, user]);

  useEffect(() => {
    if (isLoadingFormData) return;

    if (teamInputRef.current) {
      teamInputRef.current.focus();
    } else if (submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [isLoadingFormData]);

  useImperativeHandle(ref, () => ({
    setSelectedTeam: (teamId: string) => {
      const selectedOption = teamOptions.find(
        (option) => teamId === option.value,
      );
      setSelectedTeam(selectedOption);
    },
    setSelectedLayout: (layoutId: string) => {
      const selectedOption = layoutOptions.find(
        (option) => layoutId === option.value,
      );
      setSelectedLayout(selectedOption);
    },
    focusSubmitButton: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.focus();
      }
    },
  }));

  useEffect(() => {
    setSelectedLayout(layoutOptions[0]);
    return;
  }, []);

  useEffect(() => {
    if (!selectedTeam && teamOptions.length) {
      setSelectedTeam(teamOptions[0]);
      return;
    }
  }, [teamOptions, selectedTeam]);

  const handleLayoutChange = (selectedOption: SelectValue<LayoutOption>) => {
    const selectedOptionO =
      Array.isArray(selectedOption) && selectedOption.length > 0
        ? selectedOption[0]
        : selectedOption;

    const value = selectedOptionO && selectedOptionO.value;

    if (value === CUSTOM_LAYOUT) {
      onSelectNewLayout();

      return;
    }

    setSelectedLayout(selectedOption);
  };

  const handleTeamChange = (selectedOption: SelectValue<TeamOption>) => {
    const selectedOptionO =
      Array.isArray(selectedOption) && selectedOption.length > 0
        ? selectedOption[0]
        : selectedOption;

    const value = selectedOptionO && selectedOptionO.value;

    if (value === NEW_TEAM) {
      onSelectNewTeam();

      return;
    }

    setSelectedTeam(selectedOption);
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setError(null);
    setIsLoadingSubmit(true);

    if (!user) {
      setIsLoadingSubmit(false);
      setError(new Error('No valid user is logged in.'));

      return;
    }

    if (!layoutOptions.length) {
      setIsLoadingSubmit(false);
      setError(new Error(WAIT_LOADING_ERROR));

      return;
    }

    let teamName: string, teamId: string;

    if (hasTeams) {
      const selectedTeamOption =
        Array.isArray(selectedTeam) && selectedTeam.length > 0
          ? selectedTeam[0]
          : selectedTeam;

      if (!selectedTeamOption) {
        setIsLoadingSubmit(false);
        setError(new Error('Please select a team'));

        return;
      }

      teamName = selectedTeamOption.label;
      teamId = selectedTeamOption.value;
    } else {
      if (!teamInputValue) {
        setIsLoadingSubmit(false);
        setError(new Error('Please type a team name'));

        return;
      }
      teamName = teamInputValue;
      teamId = await createTeam(teamInputValue);
    }

    const selectedLayoutId = (
      Array.isArray(selectedLayout) && selectedLayout.length > 0
        ? selectedLayout[0]
        : selectedLayout
    ).value;

    const layout =
      DEFAULT_LAYOUTS.find((layout) => layout.id === selectedLayoutId) ||
      user?.customRetroLayouts?.find(
        (layout) => layout.id === selectedLayoutId,
      );

    if (!layout) {
      const error = new Error('No Layout found');
      console.error(error);
      setIsLoadingSubmit(false);
      setError(error);

      return;
    }

    createRetro({
      name: `${teamName} - ${getMonthWithDayAndYearInLocalTime(new Date())}`,
      layout,
      teamId,
      teamName,
      likesLimit: 3,
      canLikeMyNotes: true,
    })
      .then((retroId) => {
        setIsLoadingSubmit(false);
        if (retroId) {
          onCreated(retroId);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingSubmit(false);
        setError(
          error.message
            ? error
            : { message: 'Unexpected error happened when creating game.' },
        );
      });
  };

  if (isLoadingFormData) {
    return <Loading message="Please, wait just 1 second..." />;
  }

  return (
    <div className={styles['settings']}>
      <form onSubmit={handleFormSubmit}>
        <FormSelect
          label="Layout"
          // eslint-disable-next-line
          // @ts-ignore
          options={layoutOptions}
          onChange={handleLayoutChange}
          value={selectedLayout}
          id="layout"
          isSearchable={false}
          menuPortalTarget={document.body}
        />
        {hasTeams ? (
          <FormSelect
            label="Select team"
            options={teamOptions}
            onChange={handleTeamChange}
            value={selectedTeam}
            id="team"
            menuPortalTarget={document.body}
          />
        ) : (
          <FormInput
            label="Team name"
            id="team"
            ref={teamInputRef}
            value={teamInputValue}
            onChange={setTeamInputValue}
          />
        )}
        {error?.message && <InlineAlert title={error.message} content="" />}
        <FormGroup isSubmit>
          <Button
            ref={submitButtonRef}
            buttonType="submit"
            isLoading={isLoadingSubmit}
            isBlock
          >
            Create retrospective
          </Button>
        </FormGroup>
      </form>
    </div>
  );
});
