import { Icon, InlineEdit } from '@we-agile-you/react-base';
import { RetroColumn } from '@we-agile-you/types-planning-poker';
import React, { useContext, useEffect, useRef } from 'react';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';
import { CardsDashboardContext } from '../CardsDashboard/CardsDashboard';

import styles from './AddNoteInline.module.scss';

type AddNoteInlineProps = {
  column: RetroColumn;
  onNoteAdded: () => void;
};

export const AddNoteInline = ({ column, onNoteAdded }: AddNoteInlineProps) => {
  const { addNote } = useRetroActions();
  const addIssueContainerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<any>();
  const [lastOpenedAddNote, setLastOpenedAddNote] = useContext(
    CardsDashboardContext,
  ).lastOpenedAddNote;

  const handleSave = (text: string) => {
    if (!!text) {
      addNote(text, column.id).then(() => {
        onNoteAdded();
      });
    }
  };

  const handleFocus = () => {
    setLastOpenedAddNote(column.id);
    if (addIssueContainerRef.current) {
      addIssueContainerRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (lastOpenedAddNote !== column.id) {
      ref.current?.setIsFocus(false);
    }
  }, [lastOpenedAddNote]);

  const notesLength = column?.cards?.length;

  const label =
    column.columnType === 'actions'
      ? notesLength > 0
        ? 'Add another action'
        : 'Add action'
      : notesLength > 0
      ? 'Add another note'
      : 'Add note';

  return (
    <div className={styles['container']} ref={addIssueContainerRef}>
      <InlineEdit
        buttonLabel={
          <>
            <Icon icon="plus" />
            <span className={styles['button-label']}>{label}</span>
          </>
        }
        submitLabel="Add note"
        ref={ref}
        placeholder="Type your note text"
        value=""
        onConfirmValue={handleSave}
        onFocus={handleFocus}
        keepFocusAfterEnterKeyPress
        confirmValueWithEnterKey
        isRequired
      />
    </div>
  );
};
