import { getMonthWithDayAndYearInLocalTime } from '@we-agile-you/js-base';
import {
  Button,
  ButtonLink,
  FlexBox,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
  HoritzontalSpacing,
  InlineAlert,
  Paragraph,
  SelectValue,
  Span,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import React, {
  FormEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';

import styles from './SettingsForm.module.scss';
import { useUserTeams } from '../../../spaces/retrospective/hooks/useUserTeams';

const NEW_TEAM = 'NEW_TEAM';

interface SettingsProps {
  onSelectNewTeam: () => void;
  onManageTeamsClick: () => void;
  onUpdated: () => void;
  ownerId: string | null;
}

interface TeamOption {
  label: ReactNode;
  value: string;
}

const SettingsForm = forwardRef(function SettingsForm(
  { onSelectNewTeam, onManageTeamsClick, onUpdated }: SettingsProps,
  ref,
) {
  const { updateRetroSettings } = useRetroActions();
  const { retro } = useCurrentRetro();
  const [selectedTeam, setSelectedTeam] =
    useState<SelectValue<TeamOption>>(null);
  const [canLikeMyNotes, setCanLikeMyNotes] = useState<SelectValue<TeamOption>>(
    retro?.canLikeMyNotes,
  );
  const [name, setName] = useState<string>(
    retro?.name ||
      `Retrospective at ${getMonthWithDayAndYearInLocalTime(new Date())}`,
  );
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [likesLimit, setLikesLimit] = useState<number | null>(
    retro?.likesLimit || null,
  );

  const gameNameInput = useRef<HTMLInputElement | null>(null);

  const user = useCurrentUser();

  const { teams } = useUserTeams();

  const teamOptions: TeamOption[] = useMemo(
    () =>
      teams?.length
        ? [
            ...teams.map(({ name, id }) => ({ label: name, value: id })),
            {
              label: (
                <Span spanStyle="bold" color="primary">
                  Create new team
                </Span>
              ),
              value: NEW_TEAM,
            },
          ]
        : [],
    [teams],
  );

  useEffect(() => {
    if (gameNameInput.current) {
      gameNameInput.current.focus();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    setSelectedTeam: (teamId: string) => {
      const selectedOption = teamOptions.find(
        (option) => teamId === option.value,
      );
      setSelectedTeam(selectedOption);
    },
  }));

  const retroName = retro?.name;
  useEffect(() => {
    if (retroName) {
      setName(retroName);
    }
  }, [retroName]);

  const teamId = retro?.team.id;
  useEffect(() => {
    if (selectedTeam) {
      return;
    }

    const selectedOption =
      retro && teamOptions.find((option) => option.value === teamId);

    if (selectedOption) {
      setSelectedTeam(selectedOption);
    }
  }, [teamId, teamOptions, selectedTeam]);

  const handleTeamChange = (selectedOption: SelectValue<TeamOption>) => {
    const selectedOptionO =
      Array.isArray(selectedOption) && selectedOption.length > 0
        ? selectedOption[0]
        : selectedOption;

    const value = selectedOptionO && selectedOptionO.value;

    if (value === NEW_TEAM) {
      onSelectNewTeam();

      return;
    }

    setSelectedTeam(selectedOption);
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleLikesLimitChange = (value: number) => {
    setLikesLimit(value);
  };

  function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    setError(null);
    setIsLoading(true);

    if (!user.user) {
      setIsLoading(false);
      setError(new Error('No valid user is logged in.'));

      return;
    }

    const selectedTeamOption =
      Array.isArray(selectedTeam) && selectedTeam.length > 0
        ? selectedTeam[0]
        : selectedTeam;

    if (!selectedTeamOption) {
      setIsLoading(false);
      setError(new Error('Please select a team'));

      return;
    }

    const teamName = selectedTeamOption?.label;
    const teamId = selectedTeamOption?.value;

    updateRetroSettings({
      name,
      teamId,
      teamName,
      likesLimit: likesLimit || 0,
      canLikeMyNotes,
    })
      .then(() => {
        if (onUpdated) {
          setIsLoading(false);
          onUpdated();
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setError(
          error.message
            ? error
            : { message: 'Unexpected error happened when updating game.' },
        );
      });
  }

  const showManageTeams = !!teamOptions?.length;

  return (
    <div className={styles['settings']}>
      <form onSubmit={handleFormSubmit}>
        <FormInput
          label="Game's name"
          value={name}
          onChange={handleNameChange}
          maxLength={60}
          ref={gameNameInput}
        />
        <FormSelect
          label="Team name"
          options={teamOptions}
          onChange={handleTeamChange}
          value={selectedTeam}
          id="team"
          menuPortalTarget={document.body}
        />
        {showManageTeams && (
          <>
            <Paragraph align="right">
              <ButtonLink onClick={onManageTeamsClick}>Manage teams</ButtonLink>
            </Paragraph>
          </>
        )}
        <div>
          <VerticalSpacing spacing="spacing-l" />
          <FlexBox alignItems="center" className={styles['likes-container']}>
            <div className={styles['likes-limit-input']}>
              <FormInput
                label="Limit of likes per player"
                // eslint-disable-next-line
                // @ts-ignore
                onChange={handleLikesLimitChange}
                value={likesLimit || ''}
                type="number"
                id="likes-limit"
                isClearable
                isNoMargin
              />
            </div>
            <HoritzontalSpacing spacing="spacing-xl" />
            <FormCheckbox
              label="Allow players to like their own notes"
              onChange={setCanLikeMyNotes}
              isChecked={canLikeMyNotes}
              id="likes-limit"
              isNoMargin
            />
          </FlexBox>
        </div>

        {error?.message && <InlineAlert title={error.message} content="" />}

        <FormGroup isSubmit>
          <Button buttonType="submit" isLoading={isLoading} isBlock>
            Update game settings
          </Button>
        </FormGroup>
      </form>
    </div>
  );
});

export default SettingsForm;
