import { updateCurrentUserTeamHistory } from './../../../../../../packages/auth/data/user';
import { useCurrentRetro } from '../../retrospective/hooks/useCurrentRetro';
import { useEffect, useMemo, useState } from 'react';

import useCurrentUser from './useCurrentUser';

type TeamSummary = {
  id: string;
  name: string;
};
export const useUpdateCurrentUserTeamsHistory = () => {
  const { retro } = useCurrentRetro();
  const { user } = useCurrentUser();
  const [previousTeamSummary, setPreviousTeamSummary] =
    useState<TeamSummary | null>(null);

  const teamId = retro?.team.id;
  const teamName = retro?.team.name;

  const teamSummary = useMemo(() => {
    return teamId
      ? {
          id: teamId,
          name: teamName || '',
        }
      : null;
  }, [teamId, teamName]);

  useEffect(() => {
    if (teamSummary === null || user === null) return;

    const hasTeamChanged =
      previousTeamSummary === null ||
      previousTeamSummary.id !== teamSummary.id ||
      previousTeamSummary.name !== teamSummary.name;

    if (hasTeamChanged) {
      updateCurrentUserTeamHistory(user, teamSummary);
      setPreviousTeamSummary(teamSummary);
    }
  }, [teamSummary, user, previousTeamSummary]);
};
