import React from 'react';
import { HeaderRetro } from './HeaderRetro/HeaderRetro';

export const RetroNavigation = () => {
  return (
    <>
      <HeaderRetro />
    </>
  );
};
