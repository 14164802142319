import { RECEIVE_IS_LAST_RETRO_CHANGE } from './../constants';
import { subscribeToRetro } from '../data/retroSubscriptions';
import {
  PlayerRetro,
  RetrospectiveState,
} from '@we-agile-you/types-planning-poker';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  RECEIVE_RETROSPECTIVE_DATA_CHANGE,
  RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE,
  REQUEST_SUBSCRIBE_TO_RETROSPECTIVE,
  UNSUBSCRIBE_TO_RETROSPECTIVE,
} from '../constants';

const useSubscribeToRetro = (
  retroId: string,
): { retroExists: boolean | null } => {
  const dispatch = useDispatch();
  const [retroExists, setRetroExists] = useState<null | boolean>(null);

  const [teamId, setTeamId] = useState<string>();

  useEffect(() => {
    dispatch({
      type: REQUEST_SUBSCRIBE_TO_RETROSPECTIVE,
    });

    const handleRetroChange = (retro: Partial<RetrospectiveState>) => {
      dispatch({
        type: RECEIVE_RETROSPECTIVE_DATA_CHANGE,
        data: retro,
      });
      setTeamId(retro.teamId);
      setRetroExists(true);
    };

    const handleRetroPlayersChange = (players: PlayerRetro[]) => {
      dispatch({
        type: RECEIVE_RETROSPECTIVE_PLAYERS_CHANGE,
        players,
      });
    };
    const handleIsLastRetroChange = (isLastRetro: boolean) => {
      dispatch({
        type: RECEIVE_IS_LAST_RETRO_CHANGE,
        isLastRetro,
      });
    };

    const handleRetroDontExist = () => {
      setRetroExists(false);
    };

    const unsubscribeToRetro = subscribeToRetro(
      retroId,
      teamId || '',
      handleRetroChange,
      handleRetroPlayersChange,
      handleIsLastRetroChange,
      handleRetroDontExist,
    );

    return function cleanup() {
      unsubscribeToRetro();

      dispatch({
        type: UNSUBSCRIBE_TO_RETROSPECTIVE,
      });
    };
  }, [retroId, teamId, dispatch]);

  return { retroExists };
};

export default useSubscribeToRetro;
