import { Modal, ModalTitle } from '@we-agile-you/react-base';
import React, { useRef, useState } from 'react';
import useCurrentUser from '../../../spaces/auth/hooks/useCurrentUser';
import { ManageTeamsForm } from '../../ManageTeamsForm/ManageTeamsForm';
import { CreateTeamForm } from '../../retrospective/CreateTeamForm/CreateTeamForm';

import SettingsForm from '../SettingsForm/SettingsForm';

interface SettingsModalProps {
  onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ onClose }) => {
  // const { retro } = useCurrentRetro();
  const { uid } = useCurrentUser();
  const settingsFormRef = useRef<{
    setSelectedTeam: (teamId: string) => void;
  }>({
    setSelectedTeam: () => ({}),
  });
  const [step, setStep] = useState<'settings' | 'create-team' | 'manage-teams'>(
    'settings',
  );

  const handleTeamCreated = (teamId: string) => {
    setStep('settings');
    settingsFormRef.current.setSelectedTeam(teamId);
  };

  const handleManageTeamsClick = () => {
    setStep('manage-teams');
  };

  if (!uid) return null;

  return (
    <Modal
      width="big"
      onClose={onClose}
      showBackButton={step !== 'settings'}
      onClickBackButton={() => setStep('settings')}
    >
      <>
        <ModalTitle>Game settings</ModalTitle>
        <div style={{ display: step !== 'settings' ? 'none' : 'block' }}>
          <SettingsForm
            ownerId={uid}
            onUpdated={onClose}
            onSelectNewTeam={() => setStep('create-team')}
            ref={settingsFormRef}
            onManageTeamsClick={handleManageTeamsClick}
          />
        </div>
        {step === 'create-team' && (
          <CreateTeamForm
            onCancel={() => setStep('settings')}
            onCreated={handleTeamCreated}
          />
        )}
        {step === 'manage-teams' && <ManageTeamsForm />}
      </>
    </Modal>
  );
};

export default SettingsModal;
