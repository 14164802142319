import React, { useState, useEffect } from "react";

import { ButtonIcon, Icon, usePrevious } from "@we-agile-you/react-base";
import ReactDom from "react-dom";
import { useSubscribeToIceBreaker } from "../../hooks/useSubscribeToIceBreaker";
import { useCurrentIceBreaker } from "../../hooks/useCurrentIceBreaker";
import { StartIceBreakerModal } from "../StartIceBreakerModal/StartIceBreakerModal";
import { startRandomIceBreakerGame } from "../../data";
import { IceBreakerModal } from "../IceBreakerModal/IceBreakerModal";
import { useNotification } from "@we-agile-you/retrospective-app/src/spaces/notifications/useNotification";
import useCurrentUser from "@we-agile-you/retrospective-app/src/spaces/auth/hooks/useCurrentUser";

import {
  hotjarIdentify,
  HOTJAR_IDENTIFY_KEYS,
} from "@we-agile-you/retrospective-app/src/vendors/hotjar/identify";

interface IceBreakerButtonProps {
  retroOrPPId: string;
}

export const IceBreakerButton = ({ retroOrPPId }: IceBreakerButtonProps) => {
  const [isStartIceBreakerModalOpen, setIsStartIceBreakerModalOpen] =
    useState(false);
  const [isIceBreakerModalOpen, setIsIceBreakerModalOpen] = useState(false);

  useSubscribeToIceBreaker(retroOrPPId);
  const { iceBreaker } = useCurrentIceBreaker();
  const { user, uid } = useCurrentUser();
  const { showNotification } = useNotification();

  const isActivePrevious = usePrevious(iceBreaker.isActive);
  const isIceBreakerActive = iceBreaker.isActive;
  const startedByUserName = iceBreaker.isActive && iceBreaker.startedByUserName;

  const openIceBreakerModal = () => {
    setIsIceBreakerModalOpen(true);
    hotjarIdentify(HOTJAR_IDENTIFY_KEYS.OPENED_ICEBREAKER);
  };

  useEffect(() => {
    if (isActivePrevious === false && isIceBreakerActive) {
      openIceBreakerModal();
      showNotification({
        title: `Icebreaker started!`,
        content: `${startedByUserName} has just started an Icebreaker, lets have some fun!`,
      });
    }
  }, [isActivePrevious, isIceBreakerActive]);

  const handleButtonClick = () => {
    if (isIceBreakerActive) {
      openIceBreakerModal();
    } else {
      setIsStartIceBreakerModalOpen(true);
    }
  };

  const handleStartIceBreaker = () => {
    startRandomIceBreakerGame({
      retroOrPPId,
      startedByUserName: user?.displayName || "",
      startedByUserUid: uid || "",
    });
    setIsStartIceBreakerModalOpen(false);
  };

  const label = isIceBreakerActive
    ? "Join ongoing Icebreaker"
    : "Start Icebreaker";

  return (
    <>
      <ButtonIcon
        icon={<Icon icon="dice" />}
        onClick={handleButtonClick}
        tooltip={label}
      />
      {isStartIceBreakerModalOpen &&
        ReactDom.createPortal(
          <StartIceBreakerModal
            onClickStart={handleStartIceBreaker}
            onClose={() => setIsStartIceBreakerModalOpen(false)}
          />,
          document.body
        )}
      {isIceBreakerModalOpen &&
        ReactDom.createPortal(
          <IceBreakerModal
            retroOrPPId={retroOrPPId}
            onClose={() => setIsIceBreakerModalOpen(false)}
          />,
          document.body
        )}
    </>
  );
};
