import {
  REQUEST_SUBSCRIBE_TO_ICE_BREAKER,
  RECEIVE_ICE_BREAKER_DATA_CHANGE,
  UNSUBSCRIBE_TO_ICE_BREAKER,
} from "./constants";
import {
  IceBreakerState,
  IceBreakerActionTypes,
  IceBreakerServer,
} from "./types.d";

export const initialIceBreakerState: IceBreakerState = {
  isActive: null,
};

export const iceBreakerReducer = function iceBreakerReducer(
  state = initialIceBreakerState,
  action: IceBreakerActionTypes
): IceBreakerState {
  switch (action.type) {
    case REQUEST_SUBSCRIBE_TO_ICE_BREAKER:
      return {
        isActive: null,
      };

    case RECEIVE_ICE_BREAKER_DATA_CHANGE:
      return {
        ...(action.data as IceBreakerServer),
      };

    case UNSUBSCRIBE_TO_ICE_BREAKER:
      return {
        ...initialIceBreakerState,
      };

    default:
      return state;
  }
};
