import React from 'react';

interface LogoWayProps {
  isLight?: boolean;
}
export const LogoWay = ({ isLight }: LogoWayProps) => {
  const color1 = isLight ? '#3993FF' : '#3993FF';
  const color2 = isLight ? 'white' : '#48545D';

  return (
    <svg
      width="187"
      height="40"
      viewBox="0 0 187 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.0707 35.6034L48.9016 31.9246H47.972V35.6034H46V26.1631H49.6904C50.451 26.1631 51.0989 26.2939 51.6342 26.5553C52.1694 26.8078 52.5685 27.1549 52.8314 27.5967C53.1037 28.0295 53.2399 28.5164 53.2399 29.0574C53.2399 29.6795 53.0521 30.2431 52.6765 30.748C52.3009 31.2439 51.7421 31.5865 51.0003 31.7759L53.3526 35.6034H51.0707ZM47.972 30.5046H49.6199C50.1552 30.5046 50.5543 30.3828 50.8172 30.1394C51.0801 29.8869 51.2116 29.5398 51.2116 29.098C51.2116 28.6652 51.0801 28.3316 50.8172 28.0972C50.5543 27.8537 50.1552 27.732 49.6199 27.732H47.972V30.5046Z"
        fill={color2}
      />
      <path
        d="M62.0427 31.6947C62.0427 31.9652 62.0239 32.2087 61.9863 32.4251H56.2817C56.3287 32.9661 56.5259 33.3898 56.8733 33.6964C57.2208 34.0029 57.648 34.1562 58.1551 34.1562C58.8875 34.1562 59.4087 33.8542 59.7186 33.2501H61.8455C61.6201 33.9714 61.1882 34.5665 60.5496 35.0353C59.9111 35.4952 59.127 35.7251 58.1974 35.7251C57.4461 35.7251 56.77 35.5673 56.1691 35.2517C55.5775 34.9271 55.1127 34.4718 54.7746 33.8857C54.446 33.2997 54.2816 32.6234 54.2816 31.857C54.2816 31.0816 54.446 30.4009 54.7746 29.8148C55.1033 29.2287 55.5634 28.7779 56.155 28.4623C56.7466 28.1467 57.4274 27.989 58.1974 27.989C58.9392 27.989 59.6012 28.1422 60.1834 28.4488C60.775 28.7554 61.2304 29.1927 61.5497 29.7607C61.8783 30.3197 62.0427 30.9644 62.0427 31.6947ZM60.0003 31.1537C59.9909 30.6669 59.8078 30.2791 59.451 29.9906C59.0941 29.6931 58.6575 29.5443 58.141 29.5443C57.6527 29.5443 57.2396 29.6886 56.9015 29.9771C56.5728 30.2566 56.371 30.6488 56.2958 31.1537H60.0003Z"
        fill={color2}
      />
      <path
        d="M65.5432 29.666V33.2906C65.5432 33.5431 65.6042 33.7279 65.7263 33.8452C65.8578 33.9534 66.0737 34.0075 66.3742 34.0075H67.2898V35.6034H66.0503C64.3882 35.6034 63.5571 34.828 63.5571 33.2771V29.666H62.6275V28.1107H63.5571V26.2578H65.5432V28.1107H67.2898V29.666H65.5432Z"
        fill={color2}
      />
      <path
        d="M70.4453 29.2738C70.6988 28.8771 71.0275 28.566 71.4313 28.3406C71.8444 28.1152 72.314 28.0025 72.8398 28.0025V29.9906H72.3187C71.6989 29.9906 71.2294 30.1304 70.9101 30.4099C70.6002 30.6894 70.4453 31.1763 70.4453 31.8705V35.6034H68.4734V28.1107H70.4453V29.2738Z"
        fill={color2}
      />
      <path
        d="M77.3983 35.7251C76.6471 35.7251 75.971 35.5673 75.37 35.2517C74.769 34.9271 74.2948 34.4718 73.9474 33.8857C73.6093 33.2997 73.4403 32.6234 73.4403 31.857C73.4403 31.0906 73.614 30.4144 73.9614 29.8283C74.3183 29.2422 74.8019 28.7914 75.4122 28.4758C76.0226 28.1513 76.7034 27.989 77.4546 27.989C78.2058 27.989 78.8866 28.1513 79.497 28.4758C80.1074 28.7914 80.5863 29.2422 80.9337 29.8283C81.2905 30.4144 81.469 31.0906 81.469 31.857C81.469 32.6234 81.2858 33.2997 80.9196 33.8857C80.5628 34.4718 80.0745 34.9271 79.4547 35.2517C78.8444 35.5673 78.1589 35.7251 77.3983 35.7251ZM77.3983 34.0751C77.7551 34.0751 78.0885 33.9939 78.3983 33.8316C78.7176 33.6603 78.9711 33.4079 79.159 33.0742C79.3468 32.7406 79.4407 32.3349 79.4407 31.857C79.4407 31.1447 79.2435 30.5992 78.8491 30.2205C78.4641 29.8328 77.9899 29.639 77.4264 29.639C76.863 29.639 76.3888 29.8328 76.0038 30.2205C75.6282 30.5992 75.4404 31.1447 75.4404 31.857C75.4404 32.5693 75.6235 33.1193 75.9897 33.507C76.3653 33.8857 76.8349 34.0751 77.3983 34.0751Z"
        fill={color2}
      />
      <path
        d="M85.6668 35.7251C85.0283 35.7251 84.4555 35.6169 83.9484 35.4005C83.4413 35.1751 83.0375 34.873 82.737 34.4943C82.4459 34.1157 82.2863 33.6964 82.2581 33.2365H84.2442C84.2817 33.5251 84.4273 33.764 84.6808 33.9534C84.9438 34.1427 85.2677 34.2374 85.6527 34.2374C86.0283 34.2374 86.3194 34.1652 86.526 34.021C86.742 33.8767 86.85 33.6919 86.85 33.4665C86.85 33.223 86.7185 33.0427 86.4556 32.9255C86.202 32.7992 85.7936 32.664 85.2302 32.5197C84.648 32.3845 84.1691 32.2447 83.7934 32.1005C83.4272 31.9562 83.108 31.7353 82.8356 31.4378C82.5727 31.1402 82.4412 30.739 82.4412 30.2341C82.4412 29.8193 82.5633 29.4406 82.8075 29.098C83.061 28.7554 83.4178 28.4849 83.878 28.2865C84.3475 28.0881 84.8968 27.989 85.5259 27.989C86.4556 27.989 87.1974 28.2144 87.7514 28.6652C88.3055 29.107 88.6107 29.7066 88.667 30.464H86.7795C86.7514 30.1664 86.6199 29.932 86.3852 29.7607C86.1598 29.5804 85.8546 29.4902 85.4696 29.4902C85.1128 29.4902 84.8358 29.5533 84.6386 29.6795C84.4508 29.8058 84.3569 29.9816 84.3569 30.207C84.3569 30.4595 84.4883 30.6533 84.7513 30.7886C85.0142 30.9148 85.4227 31.0455 85.9767 31.1808C86.5401 31.316 87.0049 31.4558 87.3711 31.6001C87.7374 31.7443 88.0519 31.9697 88.3149 32.2763C88.5872 32.5738 88.728 32.9706 88.7374 33.4665C88.7374 33.8993 88.6106 34.287 88.3571 34.6296C88.113 34.9722 87.7561 35.2427 87.2866 35.4411C86.8265 35.6304 86.2866 35.7251 85.6668 35.7251Z"
        fill={color2}
      />
      <path
        d="M92.0933 29.1927C92.3469 28.85 92.6943 28.566 93.1357 28.3406C93.5864 28.1062 94.0982 27.989 94.671 27.989C95.3377 27.989 95.9386 28.1467 96.4739 28.4623C97.0185 28.7779 97.4458 29.2287 97.7557 29.8148C98.0749 30.3918 98.2346 31.0636 98.2346 31.83C98.2346 32.5964 98.0749 33.2771 97.7557 33.8722C97.4458 34.4583 97.0185 34.9136 96.4739 35.2382C95.9386 35.5628 95.3377 35.7251 94.671 35.7251C94.0982 35.7251 93.5911 35.6124 93.1497 35.387C92.7178 35.1616 92.3657 34.8775 92.0933 34.5349V39.1739H90.1214V28.1107H92.0933V29.1927ZM96.2204 31.83C96.2204 31.3792 96.1218 30.9914 95.9246 30.6669C95.7368 30.3332 95.4832 30.0808 95.1639 29.9095C94.8541 29.7382 94.516 29.6525 94.1498 29.6525C93.793 29.6525 93.4549 29.7427 93.1357 29.923C92.8258 30.0943 92.5722 30.3468 92.375 30.6804C92.1872 31.014 92.0933 31.4062 92.0933 31.857C92.0933 32.3078 92.1872 32.7001 92.375 33.0337C92.5722 33.3673 92.8258 33.6243 93.1357 33.8046C93.4549 33.9759 93.793 34.0616 94.1498 34.0616C94.516 34.0616 94.8541 33.9714 95.1639 33.7911C95.4832 33.6107 95.7368 33.3538 95.9246 33.0202C96.1218 32.6865 96.2204 32.2898 96.2204 31.83Z"
        fill={color2}
      />
      <path
        d="M106.687 31.6947C106.687 31.9652 106.668 32.2087 106.63 32.4251H100.926C100.973 32.9661 101.17 33.3898 101.517 33.6964C101.865 34.0029 102.292 34.1562 102.799 34.1562C103.532 34.1562 104.053 33.8542 104.363 33.2501H106.49C106.264 33.9714 105.832 34.5665 105.194 35.0353C104.555 35.4952 103.771 35.7251 102.841 35.7251C102.09 35.7251 101.414 35.5673 100.813 35.2517C100.222 34.9271 99.7567 34.4718 99.4187 33.8857C99.09 33.2997 98.9257 32.6234 98.9257 31.857C98.9257 31.0816 99.09 30.4009 99.4187 29.8148C99.7474 29.2287 100.207 28.7779 100.799 28.4623C101.391 28.1467 102.071 27.989 102.841 27.989C103.583 27.989 104.245 28.1422 104.827 28.4488C105.419 28.7554 105.874 29.1927 106.194 29.7607C106.522 30.3197 106.687 30.9644 106.687 31.6947ZM104.644 31.1537C104.635 30.6669 104.452 30.2791 104.095 29.9906C103.738 29.6931 103.302 29.5443 102.785 29.5443C102.297 29.5443 101.884 29.6886 101.546 29.9771C101.217 30.2566 101.015 30.6488 100.94 31.1537H104.644Z"
        fill={color2}
      />
      <path
        d="M107.384 31.857C107.384 31.0816 107.549 30.4054 107.877 29.8283C108.206 29.2422 108.661 28.7914 109.244 28.4758C109.826 28.1513 110.492 27.989 111.244 27.989C112.211 27.989 113.009 28.2234 113.638 28.6922C114.277 29.1521 114.704 29.8013 114.92 30.6398H112.793C112.68 30.3152 112.488 30.0627 112.216 29.8824C111.953 29.6931 111.624 29.5984 111.23 29.5984C110.666 29.5984 110.22 29.7968 109.891 30.1935C109.563 30.5812 109.398 31.1357 109.398 31.857C109.398 32.5693 109.563 33.1238 109.891 33.5206C110.22 33.9083 110.666 34.1021 111.23 34.1021C112.028 34.1021 112.549 33.7595 112.793 33.0742H114.92C114.704 33.8857 114.277 34.5304 113.638 35.0083C113 35.4862 112.201 35.7251 111.244 35.7251C110.492 35.7251 109.826 35.5673 109.244 35.2517C108.661 34.9271 108.206 34.4763 107.877 33.8993C107.549 33.3132 107.384 32.6324 107.384 31.857Z"
        fill={color2}
      />
      <path
        d="M118.426 29.666V33.2906C118.426 33.5431 118.487 33.7279 118.609 33.8452C118.74 33.9534 118.956 34.0075 119.257 34.0075H120.172V35.6034H118.933C117.271 35.6034 116.44 34.828 116.44 33.2771V29.666H115.51V28.1107H116.44V26.2578H118.426V28.1107H120.172V29.666H118.426Z"
        fill={color2}
      />
      <path
        d="M122.356 27.2181C122.009 27.2181 121.717 27.1144 121.483 26.907C121.257 26.6906 121.145 26.4246 121.145 26.109C121.145 25.7934 121.257 25.532 121.483 25.3246C121.717 25.1082 122.009 25 122.356 25C122.703 25 122.99 25.1082 123.215 25.3246C123.45 25.532 123.567 25.7934 123.567 26.109C123.567 26.4246 123.45 26.6906 123.215 26.907C122.99 27.1144 122.703 27.2181 122.356 27.2181ZM123.328 28.1107V35.6034H121.356V28.1107H123.328Z"
        fill={color2}
      />
      <path
        d="M128.295 33.8587L130.267 28.1107H132.366L129.478 35.6034H127.084L124.21 28.1107H126.323L128.295 33.8587Z"
        fill={color2}
      />
      <path
        d="M140.493 31.6947C140.493 31.9652 140.474 32.2087 140.436 32.4251H134.732C134.779 32.9661 134.976 33.3898 135.323 33.6964C135.671 34.0029 136.098 34.1562 136.605 34.1562C137.337 34.1562 137.859 33.8542 138.169 33.2501H140.295C140.07 33.9714 139.638 34.5665 139 35.0353C138.361 35.4952 137.577 35.7251 136.647 35.7251C135.896 35.7251 135.22 35.5673 134.619 35.2517C134.027 34.9271 133.563 34.4718 133.225 33.8857C132.896 33.2997 132.732 32.6234 132.732 31.857C132.732 31.0816 132.896 30.4009 133.225 29.8148C133.553 29.2287 134.013 28.7779 134.605 28.4623C135.196 28.1467 135.877 27.989 136.647 27.989C137.389 27.989 138.051 28.1422 138.633 28.4488C139.225 28.7554 139.68 29.1927 140 29.7607C140.328 30.3197 140.493 30.9644 140.493 31.6947ZM138.45 31.1537C138.441 30.6669 138.258 30.2791 137.901 29.9906C137.544 29.6931 137.107 29.5443 136.591 29.5443C136.103 29.5443 135.689 29.6886 135.351 29.9771C135.023 30.2566 134.821 30.6488 134.746 31.1537H138.45Z"
        fill={color2}
      />
      <path
        d="M148.98 35.7877C148.071 35.7877 147.236 35.5786 146.476 35.1604C145.715 34.7422 145.113 34.1648 144.668 33.4283C144.223 32.6828 144 31.8418 144 30.9053C144 29.9779 144.223 29.146 144.668 28.4095C145.113 27.664 145.715 27.0821 146.476 26.6638C147.236 26.2456 148.071 26.0365 148.98 26.0365C149.898 26.0365 150.732 26.2456 151.483 26.6638C152.244 27.0821 152.842 27.664 153.277 28.4095C153.723 29.146 153.945 29.9779 153.945 30.9053C153.945 31.8418 153.723 32.6828 153.277 33.4283C152.842 34.1648 152.244 34.7422 151.483 35.1604C150.723 35.5786 149.888 35.7877 148.98 35.7877ZM148.98 34.083C149.564 34.083 150.078 33.9557 150.523 33.7011C150.969 33.4374 151.316 33.0647 151.567 32.5828C151.817 32.1009 151.942 31.5417 151.942 30.9053C151.942 30.2689 151.817 29.7142 151.567 29.2415C151.316 28.7596 150.969 28.3913 150.523 28.1368C150.078 27.8822 149.564 27.7549 148.98 27.7549C148.395 27.7549 147.876 27.8822 147.422 28.1368C146.977 28.3913 146.629 28.7596 146.378 29.2415C146.128 29.7142 146.003 30.2689 146.003 30.9053C146.003 31.5417 146.128 32.1009 146.378 32.5828C146.629 33.0647 146.977 33.4374 147.422 33.7011C147.876 33.9557 148.395 34.083 148.98 34.083Z"
        fill={color2}
      />
      <path
        d="M159.2 28.0277C160.118 28.0277 160.86 28.3141 161.426 28.8869C161.991 29.4506 162.274 30.2416 162.274 31.2599V35.6923H160.327V31.519C160.327 30.9189 160.174 30.4598 159.868 30.1416C159.562 29.8143 159.145 29.6506 158.616 29.6506C158.078 29.6506 157.652 29.8143 157.337 30.1416C157.031 30.4598 156.878 30.9189 156.878 31.519V35.6923H154.93V28.1368H156.878V29.0778C157.137 28.7505 157.466 28.4959 157.865 28.3141C158.273 28.1231 158.718 28.0277 159.2 28.0277Z"
        fill={color2}
      />
      <path d="M165.61 25.6001V35.6923H163.662V25.6001H165.61Z" fill={color2} />
      <path
        d="M168.058 27.2366C167.715 27.2366 167.427 27.1321 167.195 26.923C166.973 26.7048 166.861 26.4365 166.861 26.1183C166.861 25.8001 166.973 25.5364 167.195 25.3273C167.427 25.1091 167.715 25 168.058 25C168.401 25 168.684 25.1091 168.906 25.3273C169.138 25.5364 169.254 25.8001 169.254 26.1183C169.254 26.4365 169.138 26.7048 168.906 26.923C168.684 27.1321 168.401 27.2366 168.058 27.2366ZM169.017 28.1368V35.6923H167.07V28.1368H169.017Z"
        fill={color2}
      />
      <path
        d="M174.748 28.0277C175.666 28.0277 176.408 28.3141 176.973 28.8869C177.539 29.4506 177.822 30.2416 177.822 31.2599V35.6923H175.874V31.519C175.874 30.9189 175.721 30.4598 175.415 30.1416C175.109 29.8143 174.692 29.6506 174.164 29.6506C173.626 29.6506 173.199 29.8143 172.884 30.1416C172.578 30.4598 172.425 30.9189 172.425 31.519V35.6923H170.478V28.1368H172.425V29.0778C172.685 28.7505 173.014 28.4959 173.412 28.3141C173.82 28.1231 174.266 28.0277 174.748 28.0277Z"
        fill={color2}
      />
      <path
        d="M186.373 31.7509C186.373 32.0236 186.355 32.2691 186.318 32.4873H180.684C180.731 33.0328 180.925 33.4602 181.268 33.7693C181.612 34.0784 182.033 34.233 182.534 34.233C183.257 34.233 183.772 33.9284 184.078 33.3192H186.178C185.956 34.0466 185.529 34.6467 184.899 35.1195C184.268 35.5832 183.494 35.815 182.576 35.815C181.834 35.815 181.166 35.6559 180.573 35.3377C179.989 35.0104 179.53 34.5512 179.196 33.9602C178.871 33.3693 178.709 32.6873 178.709 31.9145C178.709 31.1326 178.871 30.4461 179.196 29.8552C179.521 29.2642 179.975 28.8096 180.559 28.4914C181.143 28.1731 181.816 28.014 182.576 28.014C183.308 28.014 183.962 28.1686 184.537 28.4777C185.121 28.7868 185.571 29.2278 185.886 29.8006C186.211 30.3643 186.373 31.0144 186.373 31.7509ZM184.356 31.2053C184.347 30.7144 184.166 30.3234 183.814 30.0325C183.461 29.7324 183.03 29.5824 182.52 29.5824C182.038 29.5824 181.63 29.7279 181.296 30.0188C180.972 30.3007 180.772 30.6962 180.698 31.2053H184.356Z"
        fill={color2}
      />
      <path
        d="M77.6602 11.3309C77.6602 10.1853 77.8897 9.16922 78.3487 8.28275C78.8216 7.39627 79.4545 6.71437 80.2473 6.23703C81.054 5.7597 81.9512 5.52103 82.9387 5.52103C83.8011 5.52103 84.5522 5.69151 85.192 6.03246C85.8458 6.37341 86.3674 6.80301 86.7568 7.32126V5.70515H89.6986V17.0384H86.7568V15.3814C86.3813 15.9133 85.8597 16.3565 85.192 16.7111C84.5383 17.052 83.7802 17.2225 82.9179 17.2225C81.9442 17.2225 81.054 16.977 80.2473 16.4861C79.4545 15.9951 78.8216 15.3064 78.3487 14.4199C77.8897 13.5198 77.6602 12.4901 77.6602 11.3309ZM86.7568 11.3718C86.7568 10.6762 86.6177 10.083 86.3395 9.59201C86.0614 9.0874 85.6858 8.70553 85.2129 8.4464C84.74 8.17364 84.2323 8.03726 83.6898 8.03726C83.1474 8.03726 82.6466 8.16682 82.1876 8.42595C81.7286 8.68507 81.3531 9.06694 81.061 9.57155C80.7828 10.0625 80.6437 10.649 80.6437 11.3309C80.6437 12.0128 80.7828 12.6128 81.061 13.1311C81.3531 13.6357 81.7286 14.0244 82.1876 14.2972C82.6605 14.5699 83.1613 14.7063 83.6898 14.7063C84.2323 14.7063 84.74 14.5767 85.2129 14.3176C85.6858 14.0448 86.0614 13.663 86.3395 13.172C86.6177 12.6674 86.7568 12.0673 86.7568 11.3718Z"
        fill={color1}
      />
      <path
        d="M96.1516 5.52103C97.014 5.52103 97.7721 5.69151 98.4258 6.03246C99.0795 6.35978 99.5942 6.78938 99.9697 7.32126V5.70515H102.912V17.1202C102.912 18.1704 102.696 19.1046 102.265 19.9229C101.834 20.7548 101.187 21.4094 100.324 21.8868C99.462 22.3777 98.4188 22.6232 97.1948 22.6232C95.5535 22.6232 94.2043 22.2482 93.1472 21.4981C92.104 20.748 91.5129 19.7251 91.3738 18.4295H94.2739C94.4269 18.9477 94.7537 19.3569 95.2545 19.6569C95.7691 19.9706 96.3881 20.1274 97.1114 20.1274C97.9598 20.1274 98.6483 19.8751 99.1769 19.3705C99.7054 18.8796 99.9697 18.1295 99.9697 17.1202V15.3609C99.5942 15.8928 99.0726 16.336 98.4049 16.6906C97.7512 17.0452 97.0001 17.2225 96.1516 17.2225C95.178 17.2225 94.2878 16.977 93.481 16.4861C92.6743 15.9951 92.0345 15.3064 91.5616 14.4199C91.1026 13.5198 90.8731 12.4901 90.8731 11.3309C90.8731 10.1853 91.1026 9.16922 91.5616 8.28275C92.0345 7.39627 92.6674 6.71437 93.4602 6.23703C94.2669 5.7597 95.1641 5.52103 96.1516 5.52103ZM99.9697 11.3718C99.9697 10.6762 99.8306 10.083 99.5524 9.59201C99.2743 9.0874 98.8987 8.70553 98.4258 8.4464C97.9529 8.17364 97.4452 8.03726 96.9027 8.03726C96.3603 8.03726 95.8595 8.16682 95.4005 8.42595C94.9415 8.68507 94.566 9.06694 94.2739 9.57155C93.9957 10.0625 93.8566 10.649 93.8566 11.3309C93.8566 12.0128 93.9957 12.6128 94.2739 13.1311C94.566 13.6357 94.9415 14.0244 95.4005 14.2972C95.8734 14.5699 96.3742 14.7063 96.9027 14.7063C97.4452 14.7063 97.9529 14.5767 98.4258 14.3176C98.8987 14.0448 99.2743 13.663 99.5524 13.172C99.8306 12.6674 99.9697 12.0673 99.9697 11.3718Z"
        fill={color1}
      />
      <path
        d="M106.318 4.35497C105.804 4.35497 105.373 4.19814 105.025 3.88446C104.691 3.55714 104.524 3.15482 104.524 2.67749C104.524 2.20015 104.691 1.80465 105.025 1.49097C105.373 1.16366 105.804 1 106.318 1C106.833 1 107.257 1.16366 107.591 1.49097C107.939 1.80465 108.113 2.20015 108.113 2.67749C108.113 3.15482 107.939 3.55714 107.591 3.88446C107.257 4.19814 106.833 4.35497 106.318 4.35497ZM107.758 5.70515V17.0384H104.837V5.70515H107.758Z"
        fill={color1}
      />
      <path
        d="M112.638 1.90012V17.0384H109.717V1.90012H112.638Z"
        fill={color1}
      />
      <path
        d="M125.341 11.1263C125.341 11.5354 125.313 11.9037 125.258 12.231H116.808C116.877 13.0493 117.169 13.6903 117.684 14.154C118.199 14.6176 118.832 14.8495 119.583 14.8495C120.668 14.8495 121.44 14.3926 121.899 13.4789H125.049C124.715 14.5699 124.075 15.47 123.13 16.1792C122.184 16.8748 121.022 17.2225 119.645 17.2225C118.533 17.2225 117.531 16.9839 116.641 16.5065C115.765 16.0156 115.076 15.3268 114.575 14.4404C114.088 13.5539 113.845 12.531 113.845 11.3718C113.845 10.1989 114.088 9.16922 114.575 8.28275C115.062 7.39627 115.744 6.71437 116.62 6.23703C117.496 5.7597 118.505 5.52103 119.645 5.52103C120.744 5.52103 121.725 5.75288 122.587 6.21657C123.463 6.68027 124.138 7.34172 124.611 8.20092C125.098 9.04648 125.341 10.0216 125.341 11.1263ZM122.316 10.308C122.302 9.57155 122.031 8.98511 121.502 8.54869C120.974 8.09863 120.327 7.87361 119.562 7.87361C118.839 7.87361 118.227 8.09181 117.726 8.52823C117.239 8.95102 116.94 9.54427 116.829 10.308H122.316Z"
        fill={color1}
      />
      <path
        d="M138.734 5.70426L131.578 22.3973H128.469L130.973 16.7511L126.341 5.70426H129.616L132.6 13.6212L135.625 5.70426H138.734Z"
        fill={color1}
      />
      <path
        d="M144.514 17.2216C143.401 17.2216 142.4 16.983 141.509 16.5056C140.619 16.0147 139.917 15.3259 139.402 14.4395C138.901 13.553 138.651 12.5301 138.651 11.3709C138.651 10.2116 138.908 9.18879 139.423 8.30232C139.952 7.41584 140.668 6.73393 141.572 6.2566C142.476 5.76563 143.485 5.52014 144.597 5.52014C145.71 5.52014 146.718 5.76563 147.623 6.2566C148.527 6.73393 149.236 7.41584 149.751 8.30232C150.279 9.18879 150.543 10.2116 150.543 11.3709C150.543 12.5301 150.272 13.553 149.73 14.4395C149.201 15.3259 148.478 16.0147 147.56 16.5056C146.656 16.983 145.64 17.2216 144.514 17.2216ZM144.514 14.7259C145.042 14.7259 145.536 14.6031 145.995 14.3576C146.468 14.0985 146.844 13.7166 147.122 13.212C147.4 12.7074 147.539 12.0937 147.539 11.3709C147.539 10.2935 147.247 9.46837 146.663 8.89557C146.092 8.30913 145.39 8.01591 144.556 8.01591C143.721 8.01591 143.019 8.30913 142.448 8.89557C141.892 9.46837 141.614 10.2935 141.614 11.3709C141.614 12.4483 141.885 13.2802 142.427 13.8667C142.984 14.4395 143.679 14.7259 144.514 14.7259Z"
        fill={color1}
      />
      <path
        d="M162.671 5.70426V17.0375H159.729V15.6055C159.353 16.0965 158.859 16.4852 158.247 16.7716C157.649 17.0443 156.996 17.1807 156.286 17.1807C155.382 17.1807 154.582 16.9966 153.887 16.6284C153.191 16.2465 152.642 15.6942 152.239 14.9713C151.849 14.2349 151.654 13.3621 151.654 12.3528V5.70426H154.575V11.9437C154.575 12.8438 154.805 13.5393 155.264 14.0303C155.723 14.5077 156.349 14.7463 157.142 14.7463C157.948 14.7463 158.581 14.5077 159.04 14.0303C159.499 13.5393 159.729 12.8438 159.729 11.9437V5.70426H162.671Z"
        fill={color1}
      />
      <path
        d="M63.6813 5.70475L60.3013 17.038H57.1509L55.0436 9.12109L52.9364 17.038H49.7651L46.3643 5.70475H49.3269L51.3716 14.3377L53.5832 5.70475H56.671L58.8409 14.3172L60.8855 5.70475H63.6813Z"
        fill={color1}
      />
      <path
        d="M75.3112 11.1259C75.3112 11.535 75.2834 11.9033 75.2277 12.2306H66.7778C66.8474 13.0489 67.1395 13.6899 67.6541 14.1536C68.1688 14.6172 68.8016 14.8491 69.5527 14.8491C70.6377 14.8491 71.4096 14.3922 71.8686 13.4785H75.0191C74.6853 14.5695 74.0454 15.4696 73.0996 16.1788C72.1538 16.8744 70.9924 17.2221 69.6153 17.2221C68.5026 17.2221 67.5011 16.9835 66.6109 16.5061C65.7347 16.0152 65.0461 15.3264 64.5454 14.44C64.0586 13.5535 63.8152 12.5306 63.8152 11.3714C63.8152 10.1985 64.0586 9.16882 64.5454 8.28235C65.0322 7.39587 65.7138 6.71396 66.5901 6.23663C67.4664 5.7593 68.4748 5.52063 69.6153 5.52063C70.7142 5.52063 71.6948 5.75248 72.5571 6.21617C73.4334 6.67987 74.108 7.34132 74.5809 8.20052C75.0678 9.04608 75.3112 10.0212 75.3112 11.1259ZM72.2859 10.3076C72.272 9.57115 72.0008 8.98471 71.4722 8.54829C70.9437 8.09823 70.2969 7.8732 69.5319 7.8732C68.8086 7.8732 68.1966 8.09141 67.6959 8.52783C67.209 8.95061 66.91 9.54387 66.7987 10.3076H72.2859Z"
        fill={color1}
      />
      <g clipPath="url(#clip0_4416_57329)">
        <path
          d="M19.0248 11.5293L20.1242 18.5482C20.1626 18.7846 20.2775 19.0015 20.4507 19.165C20.6239 19.3285 20.8459 19.4293 21.0818 19.4519C21.3177 19.4744 21.5543 19.4173 21.7548 19.2895C21.9552 19.1617 22.1082 18.9703 22.1897 18.7454L23.4841 15.2135C24.3593 15.7931 25.4132 16.0308 26.4491 15.8824C27.485 15.7339 28.432 15.2094 29.1135 14.4068C29.7907 13.6149 30.1484 12.5944 30.1155 11.548C30.0827 10.5017 29.6617 9.50619 28.9362 8.75936L32.2163 6.88589C32.4245 6.76939 32.5877 6.58515 32.6793 6.36306C32.7708 6.14097 32.7855 5.89408 32.7208 5.66252C32.6561 5.43097 32.5159 5.2283 32.3229 5.08749C32.1299 4.94669 31.8955 4.87603 31.6578 4.88692L24.5036 5.03929L22.0479 2.98654C21.1766 2.26643 20.0617 1.91868 18.9407 2.01747C17.8196 2.11626 16.781 2.65374 16.0461 3.51539C15.6883 3.93888 15.4175 4.43017 15.2494 4.96062C15.0814 5.49106 15.0195 6.05005 15.0673 6.60494C15.1152 7.15984 15.2719 7.69951 15.5282 8.19253C15.7846 8.68556 16.1354 9.12207 16.5603 9.47653L19.0248 11.5293ZM18.5018 5.55922C18.8678 5.12734 19.387 4.85807 19.9474 4.80945C20.5079 4.76082 21.0648 4.9367 21.4983 5.29923C21.712 5.47586 21.8887 5.69388 22.0181 5.94046C22.1475 6.18704 22.2269 6.45726 22.2516 6.73527C22.2764 7.01327 22.246 7.29347 22.1623 7.55946C22.0786 7.82545 21.9433 8.07187 21.7642 8.28429C21.3966 8.71676 20.8759 8.98623 20.314 9.03483C19.7521 9.08344 19.1938 8.90729 18.7589 8.54423C18.5447 8.36768 18.3678 8.14949 18.2386 7.90256C18.1093 7.65563 18.0303 7.38499 18.0063 7.10666C17.9823 6.82832 18.0139 6.54795 18.099 6.28214C18.1841 6.01634 18.3211 5.77055 18.5018 5.55922Z"
          fill={color1}
        />
        <path
          d="M33.9804 17.4543V17.3558C33.9804 17.284 33.9183 17.2034 33.8829 17.1317C33.5678 16.4909 33.0139 16.003 32.3431 15.7752C31.6722 15.5474 30.9394 15.5985 30.3058 15.917C29.6721 16.2356 29.1896 16.7957 28.9643 17.474C28.739 18.1523 28.7895 18.8933 29.1045 19.534C30.0019 21.4594 30.3448 23.6015 30.094 25.7148C29.8431 27.8281 29.0086 29.8272 27.6861 31.4832C26.3065 33.2277 24.4602 34.5345 22.3669 35.2481C20.2274 36.0002 17.9188 36.1122 15.718 35.5708C13.4546 35.0318 11.3907 33.85 9.76943 32.1644C8.93331 31.3201 8.22793 30.3531 7.67719 29.2959C7.39324 28.7664 7.14449 28.2184 6.93254 27.6555C6.73092 27.0847 6.56802 26.5007 6.44496 25.9075C5.92226 23.4828 6.09448 20.9578 6.94141 18.6286C7.80993 16.2431 9.32443 14.1525 11.312 12.5958C9.11932 14.0273 7.37266 16.0577 6.27435 18.4517C5.17605 20.8457 4.77107 23.5054 5.1063 26.1227C5.25524 27.469 5.60806 28.7841 6.15239 30.022C6.6739 31.2826 7.38239 32.4554 8.25345 33.5001C10.0034 35.667 12.3284 37.2841 14.9556 38.1614C16.2839 38.6 17.6644 38.8562 19.0602 38.9233C20.4738 38.9607 21.8862 38.8131 23.2623 38.4841C26.0683 37.7779 28.6193 36.283 30.6205 34.1724C32.6749 31.9849 34.0794 29.2573 34.6735 26.301C35.2676 23.3448 35.0275 20.2791 33.9804 17.4543Z"
          fill={color1}
        />
      </g>
      <defs>
        <clipPath id="clip0_4416_57329">
          <rect
            width="30"
            height="36.9231"
            fill="white"
            transform="translate(5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
