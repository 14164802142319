export const HOTJAR_IDENTIFY_KEYS = {
  VIEWED_TIMER: 'Has viewed a timer',
  OPENED_PROFILE_IMAGE_PICKER_CROPPER:
    'Has opened profile image picker cropper',
  OPENED_ICEBREAKER: 'Has opened Icebreaker',
};

export const hotjarIdentify = (identifyKey: string, uid?: string | null) => {
  if (window.hj) {
    window.hj('identify', uid || null, {
      [identifyKey]: true,
    });
  } else {
    console.log('## Hotjar tracking: ', identifyKey, ' @ uid: ', uid);
  }
};
