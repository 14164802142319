import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CardsContainer } from '../CardsContainer/CardsContainer';
import { useCurrentRetro } from '../../../spaces/retrospective/hooks/useCurrentRetro';
import { useRetroActions } from '../../../spaces/retrospective/hooks/useRetroActions';

import styles from './CardsDashboard.module.scss';
import { RetroCardModal } from '../../../components/molecules/RetroCardModal/RetroCardModal';
import { RetroCardAction, RetroCard } from '@we-agile-you/types-planning-poker';
import { useAppContext } from '../../../spaces/app/hooks/useAppContext';

type CardDomElements = {
  [key: string]: HTMLDivElement;
};

type CardsDashboardContextType = {
  lastOpenedAddNote: [
    string | null,
    React.Dispatch<React.SetStateAction<string | null>>,
  ];
};

export const CardsDashboardContext =
  React.createContext<CardsDashboardContextType>({
    lastOpenedAddNote: [null, (_columnId) => {}],
  });

export const CardsDashboard = () => {
  const { retro } = useCurrentRetro();
  const { moveCard, startDraggingCard, stopDraggingCard, stackCard } =
    useRetroActions();
  const [lastOpenedAddNote, setLastOpenedAddNote] = useState<string | null>(
    null,
  );
  const cardDomElements = useRef<CardDomElements>({});
  const [modalOpenedCardId, setModalOpenedCardId] = useState<string | null>(
    null,
  );
  const [modalCurrentCardId, setModalCurrentCardId] = useState<string | null>(
    null,
  );

  const { isUsingMobileLayout } = useAppContext();

  const handleCardMove = useCallback(
    async (
      fromIndex: number,
      toIndex: number,
      fromGroupName: string,
      toGroupName: string,
    ) => {
      await moveCard(fromIndex, toIndex, fromGroupName, toGroupName);
    },
    [moveCard],
  );

  const handleStackCard = useCallback(
    async (fromId: string, fromGroupName: string, toId: string) => {
      await stackCard(fromId, fromGroupName, toId);
    },
    [stackCard],
  );

  const handleCardStartDrag = useCallback(
    async (cardId: string) => {
      startDraggingCard(cardId);
    },
    [startDraggingCard],
  );

  const handleCardEndDrag = useCallback(async () => {
    stopDraggingCard();
  }, [startDraggingCard]);

  const openCard = useCallback(
    async (card: RetroCard | RetroCardAction, isOpenAction = true) => {
      const cardElement = cardDomElements.current[card.id];

      if (cardElement) {
        cardElement.scrollIntoView({ block: 'nearest' });
      }

      if (isOpenAction) {
        setModalOpenedCardId(card.id);
      }

      setModalCurrentCardId(card.id);
    },
    [],
  );

  const handleModalNext = useCallback(() => {
    if (!modalCurrentCardId) return;

    retro?.columns.forEach((column) => {
      const index = column.cards.findIndex(
        (card) => card.id === modalCurrentCardId,
      );

      if (index >= 0 && index + 1 < column.cards.length) {
        openCard(column.cards[index + 1], false);
      }
    });
  }, [retro, modalCurrentCardId]);

  const handleModalPrevious = useCallback(() => {
    if (!modalCurrentCardId) return;

    retro?.columns.forEach((column) => {
      const index = column.cards.findIndex(
        (card) => card.id === modalCurrentCardId,
      );

      if (index >= 1) {
        openCard(column.cards[index - 1], false);
      }
    });
  }, [retro, modalCurrentCardId]);

  const handleRenderCard = useCallback(
    async (element: HTMLDivElement, cardId: string) => {
      cardDomElements.current = {
        ...cardDomElements.current,
        [cardId]: element,
      };
    },
    [],
  );

  const handleModalClose = () => {
    setModalCurrentCardId(null);
    setModalOpenedCardId(null);
  };

  return (
    <CardsDashboardContext.Provider
      value={{
        lastOpenedAddNote: [lastOpenedAddNote, setLastOpenedAddNote],
      }}
    >
      <DndProvider backend={HTML5Backend}>
        {isUsingMobileLayout ? (
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            className={styles['slider']}
            spaceBetween={8}
          >
            {retro?.layout.columns.map((column) => (
              <SwiperSlide className={styles['slide']}>
                <div className={styles['slide-content']}>
                  <CardsContainer
                    columnId={column.id}
                    onCardMove={handleCardMove}
                    onStartDrag={handleCardStartDrag}
                    onEndDrag={handleCardEndDrag}
                    onStackCard={handleStackCard}
                    key={column.id}
                    onCardOpen={openCard}
                    currentOpenedCardId={modalCurrentCardId}
                    onRenderCard={handleRenderCard}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={styles['cards-dashboard']}>
            {retro?.layout.columns.map((column) => (
              <CardsContainer
                columnId={column.id}
                onCardMove={handleCardMove}
                onStartDrag={handleCardStartDrag}
                onEndDrag={handleCardEndDrag}
                onStackCard={handleStackCard}
                key={column.id}
                onCardOpen={openCard}
                currentOpenedCardId={modalCurrentCardId}
                onRenderCard={handleRenderCard}
              />
            ))}
          </div>
        )}
      </DndProvider>
      {modalOpenedCardId &&
        ReactDOM.createPortal(
          <RetroCardModal
            onClose={handleModalClose}
            currentCardId={modalCurrentCardId}
            onNext={handleModalNext}
            onPrevious={handleModalPrevious}
          />,
          document.body,
        )}
    </CardsDashboardContext.Provider>
  );
};
