import { AppState } from './../../../../../../sites/retrospective-site/src/state/createStore';
import { Auth } from '@we-agile-you/auth';
import { useSelector } from 'react-redux';

const useCurrentUser = (): Auth => {
  const user = useSelector((state: AppState) => state.auth);

  return user;
};

export default useCurrentUser;
