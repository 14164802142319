import { NotificationType } from '@we-agile-you/types-planning-poker';
import { uuidv4 } from '@we-agile-you/js-base';
import React, { ReactNode, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { CookiesBanner } from '../../containers/CookiesBanner/CookiesBanner';
import { ContactModal } from '@we-agile-you/contact';
import { Notifications, useLocation } from '@we-agile-you/react-base';
import {
  AuthModal,
  AuthModalIsOpen,
} from '../../containers/auth-modals/AuthModal';
import { MyAccountModal } from '../../containers/my-account/MyAccountModal/MyAccountModal';
import {
  EditDisplayNameModal,
  EditDisplayNameModalIsOpen,
} from '../../containers/EditDisplayNameModal/EditDisplayNameModal';
import InvitePlayersModal from '../../containers/poker/InvitePlayersModal/InvitePlayersModal';
import SettingsModal from '../../containers/poker/SettingsModal/SettingsModal';
import { useHandleCampaignUrl } from '../tracking/useHandleCampaignUrl';
import useCurrentUser from '@we-agile-you/planning-poker-app/src/spaces/auth/hooks/useCurrentUser';
import EditLayoutModal from '../../containers/retrospective/EditLayoutModal/EditLayoutModal';
import { MyRetrosModal } from '../../containers/MyRetrosModal/MyRetrosModal';

interface AppWrapperProps {
  children: ReactNode;
}

type AppContextType = {
  notifications: {
    showNotification: (notification: Partial<NotificationType>) => void;
  };
  contactModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  authModal: [
    AuthModalIsOpen,
    React.Dispatch<React.SetStateAction<AuthModalIsOpen>>,
  ];
  myAccountModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  myRetrosModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  editDisplayNameModal: [
    EditDisplayNameModalIsOpen,
    React.Dispatch<React.SetStateAction<EditDisplayNameModalIsOpen>>,
  ];
  invitePlayersModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  settingsModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  editLayoutModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  isUsingMobileLayout: boolean | null;
};

export const AppContext = React.createContext<AppContextType>({
  notifications: { showNotification: (_notification) => {} },
  contactModal: [null, (_isOpen) => {}],
  authModal: [null, (_isOpen) => {}],
  myAccountModal: [null, (_isOpen) => {}],
  myRetrosModal: [null, (_isOpen) => {}],
  editDisplayNameModal: [null, (_isOpen) => {}],
  invitePlayersModal: [null, (_isOpen) => {}],
  settingsModal: [null, (_isOpen) => {}],
  editLayoutModal: [null, (_isOpen) => {}],
  isUsingMobileLayout: null,
});

export const AppWrapper = ({ children }: AppWrapperProps) => {
  const [openNotifications, setOpenNotifiactions] = useState<
    NotificationType[]
  >([]);
  const [isContactOpen, setIsContactOpen] = useState<boolean | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<AuthModalIsOpen>(null);
  const [isMyRetrosModalOpen, setIsMyRetrosModalOpen] = useState<
    boolean | null
  >(null);
  const [isUsingMobileLayout, setIsUsingMobileLayout] = useState<
    boolean | null
  >(null);
  const [isMyAccountModalOpen, setIsMyAccountModalOpen] = useState<
    boolean | null
  >(null);
  const [isEditDisplayNameModalOpen, setIsEditDisplayNameModalOpen] =
    useState<EditDisplayNameModalIsOpen>(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean | null>(
    null,
  );
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<
    boolean | null
  >(null);
  const [isEditLayoutModalOpen, setIsEditLayoutModalOpen] = useState<
    boolean | null
  >(null);
  const auth = useCurrentUser();

  const pathname = useLocation().pathname;

  useEffect(() => {
    setIsUsingMobileLayout(window.innerWidth < 920);

    const handleWindowResize = throttle(() => {
      setIsUsingMobileLayout(window.innerWidth < 920);
    }, 1000);

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (pathname === '/manage-account/') {
      setIsMyAccountModalOpen(true);
    }
  }, [pathname]);

  useHandleCampaignUrl();

  const showNotification = (notification: Partial<NotificationType>) => {
    setOpenNotifiactions([
      ...openNotifications,
      {
        style: notification.style || 'success',
        title: notification.title || '',
        content: notification.content || '',
        uuid: uuidv4(),
      },
    ]);
  };

  return (
    <>
      <AppContext.Provider
        value={{
          contactModal: [isContactOpen, setIsContactOpen],
          notifications: { showNotification },
          authModal: [isAuthModalOpen, setIsAuthModalOpen],
          myRetrosModal: [isMyRetrosModalOpen, setIsMyRetrosModalOpen],
          myAccountModal: [isMyAccountModalOpen, setIsMyAccountModalOpen],
          editDisplayNameModal: [
            isEditDisplayNameModalOpen,
            (is) => {
              setIsEditDisplayNameModalOpen(is);
            },
          ],
          invitePlayersModal: [isInviteModalOpen, setIsInviteModalOpen],
          settingsModal: [isSettingsModalOpen, setIsSettingsModalOpen],
          editLayoutModal: [isEditLayoutModalOpen, setIsEditLayoutModalOpen],
          isUsingMobileLayout,
        }}
      >
        {children}

        <CookiesBanner />

        {isContactOpen && (
          <ContactModal
            onClose={() => setIsContactOpen(false)}
            currentUserEmail={auth.email}
          />
        )}

        <Notifications
          openNotifications={openNotifications}
          setOpenNotifiactions={setOpenNotifiactions}
        />

        {isMyAccountModalOpen && (
          <MyAccountModal onClose={() => setIsMyAccountModalOpen(false)} />
        )}

        {isEditDisplayNameModalOpen && (
          <EditDisplayNameModal
            onClose={() => setIsEditDisplayNameModalOpen(false)}
            isPromptedModal={isEditDisplayNameModalOpen === 'prompted'}
          />
        )}

        {isAuthModalOpen && (
          <AuthModal
            authType={isAuthModalOpen || 'sign-up'}
            onChangeAuthType={setIsAuthModalOpen}
            onClose={() => setIsAuthModalOpen(false)}
          />
        )}

        {isInviteModalOpen && (
          <InvitePlayersModal onClose={() => setIsInviteModalOpen(false)} />
        )}

        {isSettingsModalOpen && (
          <SettingsModal onClose={() => setIsSettingsModalOpen(false)} />
        )}
        {isEditLayoutModalOpen && (
          <EditLayoutModal onClose={() => setIsEditLayoutModalOpen(false)} />
        )}
        {isMyRetrosModalOpen && (
          <MyRetrosModal onClose={() => setIsMyRetrosModalOpen(false)} />
        )}
      </AppContext.Provider>
    </>
  );
};
